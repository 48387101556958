// @import "bourbon"; //Uncomment if you are not using Prepross
// Global requirements
@import 'partials/variables';
@import 'partials/mixins';
// Basecoat
// @import "partials/normalize";
@import 'partials/base';
@import 'partials/type';
// @import "partials/forms";
// @import "partials/tables";
body {
    background: #f2faff;

    /* scrollbar  */
    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
        // box-shadow: inset 0 0 2px #666;
        border-radius: 0px;
        background: #dee5ee;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #b5d1f8;
        border-radius: 0px;
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #c4ddff;
    }
}

.main {
    margin-left: 343px;
    -moz-transition: margin-left 0.2s ease-out;
    -o-transition: margin-left 0.2s ease-out;
    -webkit-transition: margin-left 0.2s ease-out;
    transition: margin-left 0.2s ease-out;
    padding: 0px;
    flex-grow: 1;
    max-width: 100%;
}
.wrapper-main {
    display: flex;
    flex-wrap: wrap;
    min-height: calc(100vh - 50px);
}
.input-holder {
    position: relative;
}

.form_control {
    background: #f5f5f5;
    height: 48px;
    border: 0;
    width: 100%;
    padding: 15px;
    font: 500 13px/20px $poppins;
    color: #333;
    border-radius: 2px;
    @include transition(0.2s ease-out all);
    &:focus {
        outline: 0;
        box-shadow: 0 0 0 0.25rem #f0f1f3;
    }
}

.list-style {
    ul {
        li {
            list-style: none;
            position: relative;
            color: #525252;
            font: 12px/17px $poppins;
            padding-left: 18px;
            margin-bottom: 15px;
            &:before {
                content: '';
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: #01a3ff;
                position: absolute;
                left: 0;
                top: 5px;
            }
        }
    }
}

.content-area {
    padding-left: 30px;
    padding-right: 30px;
}

.content-area-main {
    padding-left: 30px;
    padding-right: 30px;
}

.heading-cvr {
    padding-top: 30px;
    padding-bottom: 15px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .heading {
        color: $primary-head-color;
        font: 500 20px/23px $poppins;

        @media screen and (max-width: 767px) {
            font: 500 18px/20px $poppins;
        }
        @media screen and (max-width: 480px) {
            font: 500 16px/18px $poppins;
        }

        .breadcrumb-item {
            display: inline-block;
            a {
                color: #0075e9;
            }
        }
        .breadcrumb-item + .breadcrumb-item::before {
            content: '>';
            color: #0075e9;
        }
    }
    .rgt-scnt {
        margin-left: auto;
    }

    .filter-cover {
        width: auto;
        margin-bottom: 0;
        .sort-select {
            border: 0 !important;

            .dropdown-toggle {
                border: 1px solid #d3e3f8 !important;
                width: 100%;
                background-color: #fff;
                color: #0075e9;
            }
            .dropdown-menu {
                left: 0 !important;
            }
            .sort {
                color: #35475e !important;
                left: 0px !important;
            }
        }
    }
}

.select__cstm {
    position: relative;
    z-index: 1 !important;
    .form-floating {
        width: 100%;
        background: #ffffff;
        border: 1px solid #d3e3f8;
        border-radius: 12px;
        font-size: 12px !important;
        font-family: 'Poppins', sans-serif !important;
        line-height: 17px;
        font-weight: 300;
        padding: 15px 20px;
        height: 51px;
    }
    label {
        color: #35475e !important;
        background-color: #ffffff !important;
        top: -10px !important;
        font-size: 13px !important;
        font-family: 'Poppins', sans-serif !important;
        padding: 0px 10px !important;
        font-weight: normal !important;
    }
}
.drag-drop {
    margin-bottom: 35px;
}

.add-role-btn-block {
    .add-role-btn {
        display: inline-flex;
        align-items: center;
        padding: 12px 15px;
        color: #0075e9;
        font-family: $poppins;
        font-size: 13px;
        border-radius: 8px;
        background-color: #f3f8ff;
        border: dashed 1px #c4ddff;
        img {
            margin-left: 20px;
        }
    }
}

.heading-cvr.sm-head {
    padding-top: 23px;
    padding-bottom: 23px;
    .heading {
    }
}

/* scrollbar  */

// ::-webkit-scrollbar {
//     width: 8px;
//     height: 5px;
// }

/* Track */

// ::-webkit-scrollbar-track {
//     box-shadow: inset 0 0 5px #666;
//     border-radius: 10px;
// }

/* Handle */

// ::-webkit-scrollbar-thumb {
//     background: #697a99;
//     border-radius: 10px;
// }

/* Handle on hover */

// ::-webkit-scrollbar-thumb:hover {
//     background: #A1AFC7;
// }

.custom-drop {
    display: flex;
    align-items: center;
    @media screen and (min-width: 992px) {
        margin-left: 48px;
    }
    .btn {
        border: 0;
        box-shadow: none;
        background-color: #fff;
        color: #617392;
        font: 500 14px/17px $roboto-font;
        &:focus {
            box-shadow: none;
        }
    }
    .dropdown-item {
        padding: 0.25rem 12px;
        color: #617392;
        font: 400 13px/17px $roboto-font;
        i {
            margin-right: 8px;
        }
        &:active {
            background-color: #e9ecef;
        }
    }
    .user-profile {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        flex-shrink: 0;
        img {
            // border: 1px solid #617392;
            background: #e8f0ff;
            object-fit: cover;
            width: 32px;
            height: 32px;
            border-radius: 50%;
        }
    }
    .dropdown-menu {
        margin-top: 10px !important;
        min-width: 125px !important;
        border: 1px solid #00000012;
        -webkit-box-shadow: 0px 3px 16px -6px rgba(0, 0, 0, 0.18);
        -moz-box-shadow: 0px 3px 16px -6px rgba(0, 0, 0, 0.18);
        box-shadow: 0px 3px 16px -6px rgba(0, 0, 0, 0.18);
        inset: -18px auto auto 0px !important;
    }
}

// header
.header {
    position: relative;
    min-height: 82px;
    position: sticky;
    top: 0;
    overflow-x: clip;
    width: 100%;
    border-bottom: 2px solid #e8f0ff;
    display: flex;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
    background-color: #fff;
    z-index: 99;
    .hdg {
        font: 300 24px/27px $roboto-font;
        color: #617392;
    }
    .right-sctn {
        margin-left: auto;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    .lft-sctn {
        display: flex;
        align-items: center;
        @media screen and (max-width: 768px) {
            flex: 0 0 calc(100% - 150px);
        }
        @media screen and (max-width: 576px) {
            flex: 0 0 calc(100% - 144px);
        }
        .search-bar {
            margin-left: 30px;
            @media screen and (max-width: 768px) {
                margin-left: 0;
                padding-left: 10px;
                position: relative;
            }
            @include placeholder {
                opacity: 1;
                color: #7c7f81;
            }
            .search {
                width: 400px;
                background: #f6fbff;
                border: 1px solid #f3f8ff;
                border-radius: 20px;
                padding-left: 25px;
                height: 40px;
                background-image: url(../img/search.svg);
                background-repeat: no-repeat;
                font: 14px/17px $poppins;
                background-position: 20px 12px;
                padding-left: 50px;
                @media screen and (max-width: 991px) {
                    width: 100%;
                }
                @media screen and (max-width: 768px) {
                    background-image: none !important;
                    max-width: 0 !important;
                    padding-right: 6px !important;
                    height: 32px;
                }
            }

            .searchINWrap {
                transition: all ease-in-out 0.3s;
                @media screen and (max-width: 480px) {
                    position: fixed;
                    left: 0;
                    right: 0;
                    top: 58px;
                    padding: 10px 15px;
                    background-color: #ffffff;
                    transform: translateX(-100%);
                }
                &.opened {
                    @media screen and (max-width: 480px) {
                        transform: translateX(0);
                    }
                    .search {
                        @media screen and (max-width: 768px) {
                            max-width: 100% !important;
                            padding-right: 10px !important;
                        }
                    }
                }
            }
        }
    }
}

.input-shadow {
    @include transition(0.2s ease-out all);
    &:focus {
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgb(243, 247, 255);
    }
}

.custom-container {
    margin-right: auto;
    margin-left: auto;
    max-width: 1190px;
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 1679px) {
        max-width: 1000px;
    }
    @media (max-width: 1199px) {
        max-width: 850px;
    }
    @media (max-width: 1023px) {
        max-width: 95%;
    }
    @media (max-width: 767px) {
        max-width: 90%;
    }
}

.custom-container-sm {
    max-width: 1000px;
}

.mobHamb {
    @media screen and (min-width: 992px) {
        display: none;
    }
    padding-left: 5px;
    padding-right: 5px;
    flex: 0 0 42px;
    .collapsetoggle {
        width: 32px;
        height: 32px;
        background: transparent;
        .toggle-btn {
            img {
                filter: invert(26%) sepia(99%) saturate(5739%) hue-rotate(200deg) brightness(109%) contrast(101%);
            }
        }
    }
}

.mobLogo {
    flex: 0 0 28px;
    @media screen and (min-width: 992px) {
        display: none;
    }
}

ul.list-item {
    list-style: none;
    position: relative;
    margin: 25px 0;
    padding-left: 15px;
    li {
        font: 14px/30px $manrope-font;
        color: #4e5a6f;
        padding-left: 35px;
        position: relative;
        &::before {
            content: '';
            width: 8px;
            height: 8px;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 10px;
            background: #ccedff;
        }
        &:nth-child(even) {
            &::before {
                background: #01a3ff;
            }
        }
    }
}

.mobSearchToggle {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 54px;
    left: 10px;
    background: url(../img/search.svg) no-repeat center;
    @media screen and (min-width: 769px) {
        display: none;
    }
    @media (max-width: 579px) {
        width: 36px;
    }
    @media (max-width: 480px) {
        border: 1px solid #f3f8ff;
        border-radius: 32px;
    }
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    @media screen and (max-width: 991px) {
        // order: 2;
    }
    cursor: pointer;
    &:before {
        content: '';
        background: url(../img/sun.svg) no-repeat 8px center;
        width: 25px;
        height: 25px;
        position: absolute;
        top: 4px;
        z-index: 2;
        left: 3px;
    }

    &:after {
        content: '';
        background: url(../img/moons.svg) no-repeat 8px center;
        width: 25px;
        height: 25px;
        position: absolute;
        top: 5px;
        right: 6px;
        z-index: 2;
    }
    input {
        opacity: 0;
        width: 0;
        height: 0;
    }
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #cce6ff;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    .slider:before {
        position: absolute;
        content: '';
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: #0075e9;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    input:checked + .slider {
        background-color: #1e1e2c;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px transparent;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
}

.select-custom {
    margin-left: auto;
    position: relative;
    &:after {
        content: '';
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 9px solid #35475e;
        position: absolute;
        right: 0;
        top: 50%;
        right: 8px;
        transform: translate(-50%, -50%);
        border-radius: 5px;
    }
    select {
        appearance: none;
        background: #ffffff;
        border: 1px solid #ecf7ff;
        border-radius: 6px;
        height: 40px;
        min-width: 125px;
        padding: 8px 10px;
        color: #393939;
        font: 500 15px/23px Poppins;
    }
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
.ui-button,
html .ui-button.ui-state-disabled:hover,
html .ui-button.ui-state-disabled:active {
    border: 0 !important;
    background: #fff !important;
    font-weight: normal;
    color: #454545;
    text-align: center;
}
a.ui-state-default:hover {
    background: #eae1e1 !important;
    border: 1px solid #dbcfcf !important;
}
a.ui-state-default {
    width: 30px !important;
    height: 30px !important;
    line-height: 23px !important;
    border-radius: 100px;
}
.ui-widget-header {
    background-color: #fff !important;
    border: 0 !important;
}
.ui-datepicker select.ui-datepicker-month,
.ui-datepicker select.ui-datepicker-year {
    background: #f2f7ff;
    border: none;
    margin-right: 7px !important;
    padding: 6px 7px;
    border-radius: 5px !important;
    font: 500 13px/18px 'Roboto', sans-serif !important;
}
.ui-datepicker select.ui-datepicker-month {
    margin-right: 5px !important;
}

.profile-drop {
    @media screen and (max-width: 768px) {
        margin-left: 0 !important;
    }

    .btn {
        text-align: left;
        &:after {
            content: '';
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 7px solid #35475e;
            position: absolute;
            right: 0;
            top: 40%;
            right: -10px;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            border-radius: 5px;
        }

        @media screen and (max-width: 768px) {
            height: 32px;
            width: 32px;
            flex: 0 0 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 10px;

            &:after {
                transform: none;
                position: static;
            }
        }
    }

    .name {
        display: inline-flex;
        flex-direction: column;
        color: #35475e;
        font: 600 13px/20px Poppins;
        margin-right: 15px;
        @media screen and (max-width: 768px) {
            display: none;
        }
        span {
            font-weight: normal;
        }
    }
    .dropdown-menu {
        min-width: 227px !important;
        background: #ffffff;
        box-shadow: 0px 3px 6px #0075e929;
        border: 1px solid #d3e3f8;
        border-radius: 8px;
        border: 0;
        padding: 0;
        overflow: hidden;
        inset: 12px auto auto 0px !important;
        li {
            border-bottom: 1px solid #f3f8ff !important;
            &:last-child {
                border: 0 !important;
            }
        }
        .dropdown-item {
            padding: 18px 25px;
            color: #35475e;
            font: 500 13px/20px Poppins;
            &:hover {
                background-color: #eef3f8 !important;
            }
            .drop-icon {
                margin-right: 20px;
            }
        }
    }
}

.custom-drop-down {
    .drop-down-btn {
        width: 100%;
        text-align: left;
        border: 1px solid #d3e3f8;
        border-radius: 12px;
        background-color: #fff;
        box-shadow: none !important;
        height: 51px;
        font: 500 13px/20px Poppins;
        color: #6c83a2;
        padding: 10px 20px;
    }
    .dropdown-toggle::after {
        position: absolute;
        right: 18px;
        top: 50%;
        transform: translate(-50%, -50%);
        display: inline-block;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: '';
        border-top: 0.4em solid;
        border-right: 0.4em solid transparent;
        border-bottom: 0;
        border-left: 0.4em solid transparent;
        border-radius: 1px;
    }
    .dropdown-menu {
        width: 100%;
        border: 1px solid #d3e3f8;
        border-radius: 12px;
        margin-top: 8px;

        .dropdown-item {
            padding: 10px 20px;
            color: #6c83a2;
            &:hover {
                background: #f2f8ff;
            }
        }
    }
}

.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
    opacity: 1;
    transform: scale(0.85) translateY(-10px) translateX(10px);
    padding: 0;
    background: #fff;
    padding: 0 15px;
    max-height: 20px;
}
.floating-form-block {
    margin-bottom: 25px;
    padding-top: 0px;

    .form-floating {
        label {
            font: 13px/20px $poppins;
            text-align: left;
            color: #6c83a2;
            font-weight: 200;
            span {
                color: #f85050;
            }
        }

        .form-control {
            height: 51px;
            background: #ffffff;
            border: 1px solid #d3e3f8;
            border-radius: 12px;
            font-size: 13px;
            text-align: left;
            font-family: $poppins;
            color: #35475e;
            padding-left: 15px;

            &:focus {
                box-shadow: none;
                padding-top: 10px;
            }
        }
        .form-control:disabled {
            background: #f2f8ff;
            label {
            }
        }
    }
}

.form-control[type='file'] {
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background: transparent;
    border: 0;
}
// drag and drop
.drag-drop-wrap {
    margin-top: 32px;
    background: #f3f8ff 0% 0% no-repeat padding-box;
    border: 1px dashed #d3e3f8;
    border-radius: 12px;
    height: 231px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    position: relative;
    .drag-drop {
        width: 100%;
        position: relative;
        .upload-icon {
            text-align: center;
            margin-bottom: 20px;
        }
    }
    .upload-content {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        small {
            font: normal normal normal 14px/21px Poppins;
            letter-spacing: 0px;
            color: #35475e;
            margin-bottom: 10px;
        }
        .or {
            color: #35475e;
        }
        .upload-btn {
            font: 14px/21px Poppins;
            color: #0075e9;
            width: 165px;
            height: 40px;
            border: 1px solid #0075e9;
            border-radius: 11px;
            background: #cfe4ff;
        }
    }
}

.toast {
    position: fixed;
    top: 40px;
    right: 30px;
    opacity: 1 !important;
    color: #fff;
    z-index: 99999999;
    border: 0;

    strong {
        font: 500 16px/23px Poppins;
    }
    p {
        font: normal 13px/21px Poppins;
        margin-bottom: 0;
    }
    &.success_msg {
        background: #44b85e !important;
    }
    &.error_msg {
        background-color: #f44336 !important;
    }
    .btn-close {
        border: 0;
        box-shadow: none;
        filter: brightness(0) invert(1);
        opacity: 1;
    }
}

.select-otr .css-13cymwt- {
    .css-13cymwt- {
    }
}

// text editor
.ql-toolbar.ql-snow {
    border-color: #b9d0ee !important;
    padding-top: 32px !important;
    border-bottom: 0 !important;
}
.ql-container.ql-snow {
    border-color: #b9d0ee !important;
}
.ql-editor blockquote,
.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6,
.ql-editor ol,
.ql-editor p,
.ql-editor pre,
.ql-editor ul {
    // border-top: 1px solid #b9d0ee;
    // padding-top: 30px;
}
div.ql-editor {
    padding: 12px 25px !important;
    padding-top: 0px !important ;
    position: relative;
    padding-top: 30px !important;
    &:after {
        content: '';

        height: 1px;
        position: absolute;
        left: 25px;
        right: 25px;
        background-color: #b9d0ee;
        top: 0;
    }
}
.ql-container.ql-snow {
    padding-top: 15px !important;
}
.ql-editor.ql-blank:before {
    top: 30px !important;
    left: 25px !important;
}
.ql-toolbar.ql-snow {
    padding: 8px 15px !important;
}

.copy-right {
    display: grid;
    text-align: center;
    align-items: center;
    height: 45px;
    background-color: #fff;

    p {
        background-color: #fff;
        font: 500 13px/17px Poppins;
        color: #6a6a6a;
        margin-bottom: 0;
        margin-left: calc(25% - 100px);
        span {
            color: #058afc;
        }
    }
}

.warning_msg {
    display: block;
    border-radius: 22px;
    font-size: 13px;
    font-family: 'Poppins', sans-serif;
    margin-top: 8px;
    position: absolute;
    width: 100%;
    color: #ff3926;
    background-color: #ff392633;
    z-index: 9;
    @media (max-width: 1679px) {
        font-size: 13px;
    }
    @media (max-width: 579px) {
        // font-size: 12px;
    }
    .msg-wrp {
        display: inline-flex;
        align-items: center;
        padding: 5px;
        img {
            margin-right: 5px;
            // margin-top: 1px;
        }
        .msg-text {
        }
    }
}

.eye-cross {
    background: url(../img/eye-icon.svg) no-repeat center center;
    width: 40px;
}
.eye-open {
    background: url(../img/eye.svg) no-repeat center center;
    width: 40px;
}

.password-control {
    position: relative;
    .floating-input {
        padding-right: 40px !important;
    }
    .toggle-password {
        position: absolute;
        height: 40px;
        right: 5px;
        top: 5px;
    }
}

//  no data

.no-data {
    height: 57vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .no-data-content {
        text-align: center;
        .no-data-img {
            margin-bottom: 20px;
            img {
                width: 125px;
            }
        }
        .no-data-txt {
            color: #35475e;
            font: 500 16px/25px Poppins;
        }
    }
}

// 404

.cover-404 {
    min-height: calc(100vh - 180px);
    // background: #F2FAFF;
    display: flex;
    align-items: center;
    justify-content: center;
    .content-404 {
        max-width: 525px;
        min-height: 450px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .img-404 {
            margin-bottom: 35px;
            img {
                max-width: 285px;
                width: 100%;
            }
        }
    }
    .content-otr {
        text-align: center;
        .title-404 {
            color: #35475e;
            font: 600 48px/57px Poppins;
            margin-bottom: 24px;
        }

        p {
            color: #6c83a2;
            font: normal 19px/32px Poppins;
        }

        .go-home {
            background-color: #0075e9;
            color: #fff;
            border-radius: 28px;
            min-width: 185px;
            text-align: center;
            height: 48px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font: normal 16px/25px Poppins;
            margin-top: 25px;
        }
    }
}

// date picker

.custom-cl-feild {
    background: #eef8ff;
    width: 100%;
    display: flex;
    align-items: center;
    border: 1px solid #c4ddff;
    border-radius: 8px;
    height: 43px;
    padding: 5px 10px;
    cursor: pointer;

    .fa-calendar {
        color: #0075e9;
    }
    .input-fld {
        color: #27282d;
        font: normal normal normal 13px/20px Poppins;
        margin: 0 10px;
        width: 100%;
    }
}

.chart-box {
    .custom-cl-feild {
        background-color: #fff;
    }
}

.daterangepicker .ranges li.active {
    background-color: #eef5ff !important;
    color: #0075e9 !important;
}
.daterangepicker .ranges li:hover {
    background-color: #eef5ff !important;
}
.daterangepicker .ranges li {
    font: normal 13px/18px Poppins !important;
    color: #6c83a2 !important;
    border-radius: 8px;
    height: 35px;
    display: flex;
    align-items: center;
}
.daterangepicker {
    box-shadow: 0px 3px 16px #8b9db533;
    border: 0 !important;
}
.daterangepicker:before {
    border-bottom-color: #fff !important;
}
.daterangepicker .ranges {
    padding: 15px 20px;
}

.daterangepicker .calendar-table .next span,
.daterangepicker .calendar-table .prev span {
    border-color: #6c83a2 !important;
}
.daterangepicker .calendar-table th {
    color: #6c83a2 !important;
    font: normal 12px/24px Poppins !important;
}
.daterangepicker .calendar-table td {
    font: normal 12px/24px Poppins !important;
}
.daterangepicker th.month {
    color: #35475e !important;
    font: 13px/18px Poppins !important;
}
// .daterangepicker td.end-date.available,
// .daterangepicker td.start-date.available {
//     background-color: #0075e9 !important;
//     color: #fff !important;
// }

.daterangepicker td.active {
}
.daterangepicker td.in-range {
    // background-color: #eef5ff !important;
}
.drp-buttons {
    .btn {
        min-width: 129px;
        height: 40px;
        /* UI Properties */
        background: #0075e9 0% 0% no-repeat padding-box;
        border-radius: 11px;
        font: normal 13px/18px Poppins !important;
    }
    .cancelBtn {
        background-color: #fff !important;
        border: 1px solid #0075e9 !important;
        color: #0075e9 !important;
    }
}

// .applyBtn {
//     min-width: 129px;
//     height: 40px;
//     /* UI Properties */
//     background: #0075e9 0% 0% no-repeat padding-box;
//     border-radius: 11px;
//     font: normal 13px/18px Poppins !important;
// }

.daterangepicker .ranges li {
    margin-bottom: 2px;
}

body {
    .daterangepicker:first-child {
    }
}
.modal-add-proposal .employee-add-sec .select-custom {
    .css-b62m3t-container {
        padding: 0 !important;
    }
}

.css-1p3m7a8-multiValue {
    background-color: rgb(220 235 255) !important;
    border-radius: 5px !important;
    .css-9jq23d {
        font-family: Poppins, sans-serif;
        font-size: 13px !important;
    }
}

.cstm-drop-border {
}

.copy-input {
    display: flex;
    position: relative;
    .copy_btn {
        background: #e5f2ff;
        border: 1px solid #bbdcfd;
        border-radius: 16px;
        min-width: 80px;
        height: 31px;
        color: #0075e9;
        padding: 5px 10px;
        font: 13px/17px Poppins;
        position: absolute;
        right: 25px;
        top: 50%;
        transform: translate(0%, -50%);
        &:hover {
            background-color: rgb(202 230 255);
            border: 1px solid rgb(189 207 225);
        }
        span {
            margin-left: 5px;
        }
    }
}

// file choose

.file-input > [type='file'] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 10;
    cursor: pointer;
}

.file-input > .button {
    display: inline-block;
    cursor: pointer;
    background: #ebf3ff;
    padding: 5px;
    border-radius: 8px;
    color: #6c83a2;
    width: 100px;
    margin-right: 10px;
    text-align: center;
    font: normal normal normal 13px/20px Poppins;
}

.file-input:hover > .button {
    background: #eaeef3;
    color: #6c83a2;
}

.file-input > .label {
    color: #35475e;
    white-space: nowrap;
    font: normal normal normal 13px/20px Poppins;
}
// file choose end

@media (max-width: 1679px) {
    .file-input > .button {
        padding: 3px;
    }
    .file-input > .button,
    .file-input > .label {
        font-size: 12px;
    }
    .wrapper-main {
        min-height: calc(100vh - 40px);
    }
    .copy-right {
        height: 35px;
    }
    .cover-404 {
        margin-top: 25px;
    }
    .cover-404 .content-404 .img-404 img {
        max-width: 225px;
    }
    .cover-404 .content-otr .title-404 {
        font: 600 35px/43px Poppins;
        margin-bottom: 15px;
    }
    .cover-404 .content-otr p {
        font: normal 17px/27px Poppins;
    }
    .no-data .no-data-content .no-data-img img {
        width: 95px;
    }
    .no-data {
        // height: 300px;
    }
    .cover-404 .content-otr .go-home {
        margin-top: 15px;
        min-width: 165px;
        height: 45px;
    }

    .profile-drop .dropdown-menu {
        inset: -2px auto auto 0 !important;
    }
    .heading-cvr .rgt-scnt .floating-label label {
        top: 10px;
    }
    .heading-cvr .rgt-scnt {
        .floating-input:focus ~ label,
        .floating-input:not(:placeholder-shown) ~ label {
            top: -7px;
        }
    }

    .main {
        margin-left: 240px;
    }
    .header {
        min-height: 60px;
    }
    .heading-cvr {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .select-custom select {
        font: 500 14px/23px Poppins;
        min-width: 105px;
    }
    .select-custom:after {
        content: '';
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 7px solid #35475e;
    }
    .profile-drop .btn:after {
        content: '';
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 6px solid #35475e;
    }
}

@media (max-width: 1200px) {
    .heading-cvr.sm-head {
        flex-wrap: nowrap;
        align-items: center;
        .heading {
            margin-bottom: 0;
        }
        .rgt-scnt {
            display: flex;
            padding-top: 0;
            flex: auto;
            .headingForm {
                margin-left: auto;
            }
        }
    }
}
@media (max-width: 991px) {
    .cover-404 .content-404 {
        padding-left: 20px;
        padding-right: 20px;
    }
    .main {
        margin-left: 0px;
        margin-top: 0px;
    }
    .copy-right p {
        margin-left: 0;
    }
}

@media (max-width: 767px) {
    .cover-404 .content-404 .img-404 img {
        max-width: 200px;
    }
    .cover-404 .content-404 .img-404 {
        margin-bottom: 20px;
    }
    .no-data {
        height: 280px;
    }
    .cover-404 .content-otr .title-404 {
        font: 600 33px/40px Poppins;
    }
    .cover-404 .content-otr p {
        font: normal 15px/24px Poppins;
    }
    .cover-404 .content-otr .go-home {
        min-width: 165px;
    }
    .profile-drop .dropdown-menu {
        min-width: 195px !important;
    }
    .profile-drop .dropdown-menu .dropdown-item {
        padding: 10px 20px;
    }
    .profile-drop .dropdown-menu {
        inset: -5px auto auto 0px !important;
    }
    .profile-drop .dropdown-menu .dropdown-item .drop-icon {
        margin-right: 12px;
    }
    .header .lft-sctn .search-bar .search {
        max-width: 400px;
    }

    .heading-cvr .heading {
        margin-bottom: 20px;
    }
}

@media (max-width: 579px) {
    .daterangepicker.show-calendar .drp-buttons {
        display: flex !important;
    }
    .drp-buttons .btn {
        min-width: 70px;
        height: 33px;
    }
    .daterangepicker .drp-selected {
        text-align: left;
    }
    .cover-404 .content-404 .img-404 img {
        max-width: 180px;
    }
    .cover-404 {
        margin-top: 0;
    }
    .cover-404 .content-otr .title-404 {
        font: 600 28px/40px Poppins;
    }
    .header .lft-sctn .search-bar {
        margin-left: 0;
        height: 32px;
        min-width: 60px;
    }
    .switch {
        // display: none;
        width: 50px;
        height: 28px;
        flex: 0 0 50px;
        &:before {
            width: 20px;
            height: 20px;
            background: url(../img/sun.svg) no-repeat 5px center/10px;
        }
        &:after {
            top: 4px;
            background: url(../img/moons.svg) no-repeat center/10px;
            width: 20px;
            height: 20px;
        }
        .slider:before {
            height: 20px;
            width: 20px;
        }
        input:checked + .slider:before {
            transform: translateX(20px);
        }
    }
    .date-picker {
        flex-direction: column;
        width: 100%;
    }
    .date-picker .date-input-cvr .date-input {
        width: 100%;
    }
    .date-input-cvr {
        margin-bottom: 10px;
    }
    .heading-cvr .rgt-scnt {
        margin-left: 0;
        width: 100%;
    }
    .profile-drop {
        margin-left: 15px;
    }
    .header,
    .content-area-main {
        padding-left: 15px;
        padding-right: 15px;
    }
    .header .lft-sctn .search-bar .search {
        font: 300 13px/17px 'Poppins', sans-serif;
        padding-left: 30px;
        padding-right: 15px;
        background-position: 10px 13px;
        background-size: 13px;
    }
}

// tooltip custom
.tooltip-custom {
    * {
        font-family: 'Poppins', sans-serif;
    }
    .tooltip-arrow {
        z-index: 2;
        &:before {
            z-index: 2;
        }
        &:after {
            position: absolute;
            content: '';
            border-color: transparent;
            border-style: solid;
            z-index: 0;
        }
    }
    .tooltip-inner {
        z-index: 1;
        position: relative;
        background-color: #ffffff;
        color: #6c83a2;
        border: 1px solid #d3e3f8;
        box-shadow: 0px 3px 6px #a2bcd633;
        font-size: 12px;
        padding: 4px 10px;
        font-weight: 400;
    }
    // tooltip bottom
    &.bs-tooltip-bottom,
    &.bs-tooltip-auto[data-popper-placement^='bottom'] {
        .tooltip-arrow {
            &:before {
                border-bottom-color: #ffffff;
            }
            &:after {
                bottom: 0px;
                border-width: 0 0.4rem calc(0.4rem + 2px);
                border-bottom-color: #d3e3f8;
            }
        }
    }

    // tooltip top
    &.bs-tooltip-top,
    &.bs-tooltip-auto[data-popper-placement^='top'] {
        .tooltip-arrow {
            &:before {
                border-top-color: #ffffff;
            }
            &:after {
                top: 0px;
                border-width: calc(0.4rem + 2px) 0.4rem 0;
                border-top-color: #d3e3f8;
            }
        }
    }

    // tooltip right
    &.bs-tooltip-end,
    &.bs-tooltip-auto[data-popper-placement^='right'] {
        .tooltip-arrow {
            &:before {
                border-right-color: #ffffff;
            }
            &:after {
                right: 0px;
                border-width: 0.4rem calc(0.4rem + 2px) 0.4rem 0;
                border-right-color: #d3e3f8;
            }
        }
    }

    // tooltip left
    &.bs-tooltip-start,
    &.bs-tooltip-auto[data-popper-placement^='left'] {
        .tooltip-arrow {
            &:before {
                border-left-color: #ffffff;
            }
            &:after {
                left: 0px;
                border-width: 0.4rem 0 0.4rem calc(0.4rem + 2px);
                border-left-color: #d3e3f8;
            }
        }
    }
}

.input-holder {
    position: relative;
}
.select-custom {
    position: relative;
    // width: 100%;
}
.filter-cover {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 8px;
    .react-select__control.react-select__control {
        min-height: 45px !important;
        @media (max-width: 1679px) {
            min-height: 40px !important;
        }
    }
    .react-select__menu .react-select__option {
        color: #6c83a2;
        &--is-selected {
            color: #ffffff;
        }
    }

    .filter-btns {
        padding-left: 8px;
        padding-right: 8px;
    }
    .custom-cl-feild {
        height: 45px !important;
        @media (max-width: 1679px) {
            height: 40px !important;
        }
    }
    .select-otr {
        display: flex;
        background-color: #fff;
        padding: 20px 18px;
        border: 1px solid #dfedff;
        border-radius: 12px;
        // margin: 0 -8px;
        .sm-form-col {
            padding: 0 8px;
            // width: 100%;
            display: inline-flex;
            align-items: center;
        }
        .form_controler {
            width: 100%;
        }
    }
    .form_controler {
        border: 1px solid #d3e3f8;
        border-radius: 8px;
        color: #6c83a2;
        font: 13px/23px Poppins;
    }

    .select-custom {
        width: 100%;
    }
    .lft-filter-frm {
        display: flex;
        margin: 0 -8px;
        // flex-grow: 1;
        width: 45%;
    }
    .filter-btns {
        display: flex;
        column-gap: 8px;
        margin-right: auto;
        margin-left: 8px;
        .btn-fltr {
            border: 1px solid #d3e3f8;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            height: 45px !important;
            width: 45px !important;
            @media (max-width: 1679px) {
                height: 40px !important;
                width: 40px !important;
            }
            &.btn-search {
                background: #e5f0ff;
            }
        }
    }
    .fltr-right {
        width: 100%;
        display: flex;
        margin-right: -8px;
        .sort-select {
            display: flex;
            align-items: center;
            border: 1px solid #d3e3f8;
            border-radius: 8px;
            padding-left: 60px;
            min-width: 300px;
            &.select-custom:after {
                display: none;
            }
            .sort.sort {
                position: absolute;
                left: 10px;
                top: 0;
                bottom: 0;
                font: 500 13px/18px Poppins;
                color: $primary-color;
                display: flex;
                align-items: center;
            }
            .form_controler {
                border: 0;
            }
            .btn {
                border: 0;
                box-shadow: none;
                text-align: left;
                &:after {
                    display: none;
                }
            }
            .dropdown-menu {
                width: 100%;
            }
            .dropdown-item {
                font: 13px/18px Poppins;
                color: #6c83a2;
                &:hover,
                &:active {
                    background-color: #e5f0ff;
                }
            }
        }
    }
}

.error-msg {
    position: absolute;
    color: #f85050;
    font-size: 14px;
    bottom: -24px;
    left: 0;
    width: 100%;
    font: 13px/21px Poppins;
    display: none;
}

.loader-img {
    display: none;
    position: absolute;
    right: 15px;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 28px;
}
.btn {
    position: relative;
}

@media (max-width: 1300px) {
    .filter-cover .select-otr {
        flex-wrap: wrap;
    }
}
@media (max-width: 1281px) {
    .filter-cover .fltr-right {
        width: auto;
    }
}

@media (max-width: 1199px) {
    .filter-cover .filter-btns {
        margin-right: 0;
    }
    .filter-cover .fltr-right {
        // margin-left: -8px;
    }
    .filter-cover .lft-filter-frm {
        // margin-bottom: 15px;
        flex-wrap: wrap;
    }
    .filter-cover .lft-filter-frm {
        // width: 100%;
    }
    .filter-cover .select-otr {
        flex-wrap: nowrap;
    }
}
@media (max-width: 1023px) {
    .filter-cover .fltr-right {
        margin-right: 0;
    }
    .filter-cover .filter-btns {
        order: 2;
        margin-left: 0;
    }
    .filter-cover .lft-filter-frm {
        margin-bottom: 0;
    }
}
@media (max-width: 767px) {
    .filter-cover .select-otr {
        flex-wrap: wrap;
    }
    .filter-cover .lft-filter-frm {
        width: 100%;
        flex-grow: 1;
    }

    .filter-cover .fltr-right .sort-select {
        min-width: 100%;
    }
    .filter-cover .fltr-right {
        display: flex;
        flex-direction: column;
    }
    .filter-cover .fltr-right .sort-select {
        // min-width: 100%;
        margin-bottom: 10px;
    }
    .mb-p {
        padding-right: 0 !important;
    }
    .filter-cover .select-otr .sm-form-col.col {
        flex: 1 0 100%;
        margin-bottom: 10px;
    }
    .filter-cover .lft-filter-frm {
        margin-bottom: 0;
    }
    .filter-cover .fltr-right {
        margin-left: 0;
        width: 100%;
        .sm-form-col {
            padding-left: 0;
            flex-grow: 1;
        }
    }
}

.css-19bb58m {
    outline: 0;
}
.ql-editor blockquote,
.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6,
.ql-editor ol,
.ql-editor p,
.ql-editor pre,
.ql-editor ul {
    padding-top: 25px;
}
.ql-editor p,
.ql-editor ol,
.ql-editor ul,
.ql-editor pre,
.ql-editor blockquote,
.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6 {
    padding-top: 25px;
}

.ql-editor p,
.ql-editor ol,
.ql-editor ul,
.ql-editor pre,
.ql-editor blockquote,
.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6 {
    // padding-top: 35px !important;
}
.ql-toolbar.ql-snow {
    padding-top: 25px !important;
}
.ql-editor {
    // padding-top: 35px !important;
}
.css-1cfo1cf {
    font: 13px/21px Poppins !important;
}

.border_radius_sm {
    border-radius: 8px !important;
}

.round-checkbox {
    margin-bottom: 30px;
    display: flex;
    column-gap: 12px;
    li {
        list-style: none;
        display: inline;

        .chbx {
            border: 1px solid #c4ddff;
            border-radius: 26px;
            background: #f3f8ff;
            min-width: 147px;
            height: 44px;
            display: inline-flex;
            align-items: center;
            padding: 0 20px;
            position: relative;

            .checkmark {
                background: #ffffff;
                border: 1px solid #c4ddff;
                border-radius: 5px;
                width: 16px;
                height: 16px;
                margin-right: 10px;
                position: relative;
                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 3px;
                    left: 3px;
                    height: 4px;
                    width: 8px;
                    border: solid #fff;
                    border-width: 0 0 2px 2px;
                    -webkit-transform: rotate(-45deg);
                    -moz-transform: rotate(-45deg);
                    -ms-transform: rotate(-45deg);
                    -o-transform: rotate(-45deg);
                    transform: rotate(-45deg);
                }
            }
            input {
                cursor: pointer;
                position: absolute;
                inset: 0;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                transition: 0.2s;
                border-radius: 26px;

                &:hover {
                    box-shadow: 0 0 0 0.15rem rgb(0 117 233 / 24%);
                }
            }
            input:checked {
                box-shadow: 0 0 0 0.15rem rgb(0 117 233 / 24%);
            }
            input:checked ~ .checkmark {
                background-color: #0075e9;
                display: block !important;
                &:before {
                    content: '' !important;
                    border-color: #fff;
                }
            }
            label {
                color: #0075e9;
                font: 500 13px/20px Poppins;
            }
        }
    }
}

.search-input-otr {
    position: relative;
}
.search-list {
    background-color: #fff;
    border: 1px solid #d3e3f8;
    border-radius: 12px;
    box-shadow: 0px 3px 16px #0000001a;
    width: 100%;
    position: absolute;
    bottom: -5px;
    top: auto;
    transform: translate(0px, 100%);
    overflow: auto;
    z-index: 9;
    max-height: 200px;

    li {
        display: flex;
        align-items: center;
        padding: 10px 20px;
        .selecte,
        &:hover {
            background: #f9fbff;
        }
    }
    .small {
        color: #35475e;
        font: normal 13px/20px Poppins;
    }
    .hours {
        margin-left: auto;
        display: flex;
        align-items: center;
        padding-left: 25px;
        color: #0075e9;
        font: 500 13px/14px Poppins;
        column-gap: 5px;
        span {
            justify-content: center;
            display: inline-flex;
            align-items: center;
            column-gap: 3px;
            background: #eef6ff;
            border-radius: 14px;
            min-width: 40px;
            padding: 5px 10px;
            .icon {
                flex-shrink: 0;
            }
            img {
                flex-shrink: 0;
            }
        }
    }
}
.search-list .hours span .icon {
    margin-right: 5px;
}

.select___custom {
    &.select-custom-react:after {
        position: absolute;
        top: 30px;
    }
    .css-13cymwt-control {
        min-height: auto !important;
    }
    .css-13cymwt-control,
    .css-t3ipsp-control {
        border: 0 !important;
        width: 100%;
        outline: none !important;
        background: transparent !important;
    }
    .css-1fdsijx-ValueContainer {
        outline: none !important;
        width: 100%;
        background-color: transparent !important;
    }

    .form-control {
        height: auto !important;
        min-height: 50px !important;
        align-items: center;
        display: flex;
    }
}

.select__custom {
    .select {
        padding-left: 0px !important;
    }
    .css-13cymwt-control,
    .css-t3ipsp-control {
        height: 100%;
        border-radius: 12px !important;
    }
    .css-b62m3t-container {
        height: 51px;
        background: #ffffff;
        border: 1px solid #d3e3f8;
        border-radius: 12px;
        font-size: 13px;
        text-align: left;
        font-family: 'Poppins', sans-serif;
        color: #35475e;
        padding-left: 0px;
    }
}
.dark-mode {
    .select__custom .css-b62m3t-container {
        border-color: #494b5b;
    }
}
@media (max-width: 1679px) {
    .select___custom .form-control {
        min-height: 46px !important;
    }
}
.css-1jqq78o-placeholder {
    color: #6c83a2 !important;
}
body.dark-mode {
    .select-z-menu__placeholder {
        color: #fff !important;
    }
    .hed-elements .btsgrp .round-btn {
        background-color: #11121d;
        img {
            -webkit-filter: brightness(0) invert(1);
            filter: brightness(0) invert(1);
        }
    }
    .round-checkbox li .chbx {
        background-color: #242636;
        border-color: #333546;
    }
    .round-checkbox li .chbx .checkmark {
        background: #242636;
        border: 1px solid #333546;
    }
    .round-checkbox li .chbx .checkmark:before {
        border: solid #242636;
        border-width: 0 0 2px 2px;
    }
    .round-checkbox li .chbx input:checked ~ .checkmark:before {
        border: solid #0075e9;
        border-width: 0 0 2px 2px;
    }
    .round-checkbox li .chbx input:checked ~ .checkmark {
        background-color: #242636;
        border: 1px solid #0075e9;
    }
    .round-checkbox li .chbx label,
    .modal-full-width .modal-dialog .modal-content .title-italic {
        color: #a2aab2;
    }
    .search-list {
        background: #303348;
        border-color: #494b5b;
    }
    .search-list .small {
        color: #ffffff;
    }
    .search-list li .selecte,
    .search-list li:hover {
        background: #242636;
    }
    .search-list .hours {
        color: #a2aab2;
    }
    .search-list .hours span {
        background: #364658fa;
    }
    .search-list .hours span img {
        filter: brightness(0) invert(1);
    }
    .file-input > .label {
        color: #a2aab2;
    }
    .file-input > .button {
        background-color: #494b5b;
        border-color: #494b5b;
        color: #a2aab2;
    }
    .heading-cvr .filter-cover .sort-select .sort {
        color: #b4b4b4 !important;
    }
    .heading-cvr .filter-cover .sort-select .dropdown-toggle {
        border: 1px solid #333546 !important;
    }
    .heading-cvr .heading .breadcrumb-item {
        color: #fff;
    }
    .heading-cvr .heading .breadcrumb-item a,
    .heading-cvr .heading .breadcrumb-item + .breadcrumb-item:before {
        color: #a2aab2 !important;
    }

    .css-1cfo1cf {
        color: #fff !important;
    }
    .css-1p3m7a8-multiValue {
        background-color: #494b5b !important;
        .css-9jq23d {
            color: #fff !important;
        }
    }
    .custom-cl-feild {
        border: 1px solid #35475e;
        background-color: #242636;
    }
    .custom-cl-feild .input-fld,
    .custom-cl-feild .fa-calendar,
    .custom-cl-feild .fa {
        color: #fff;
    }
    .chart-box .custom-cl-feild {
        background: #2b2d3e;
    }
    .ql-snow .ql-picker-options {
        background-color: #11121d;
        color: #9eaec4;
    }
    .daterangepicker {
        background-color: #2b2d3e;
        box-shadow: 0px 3px 16px #0000005c;
    }
    .daterangepicker:before {
        border-bottom-color: #2b2d3e !important;
        top: -5px;
    }
    .daterangepicker:after {
        border-bottom: 6px solid #2b2d3e;
    }
    .daterangepicker .ranges li {
        color: #a2aab2 !important;
    }
    .daterangepicker .ranges li.active {
        background-color: #000 !important;
        color: #fff !important;
    }
    .daterangepicker.show-ranges.ltr .drp-calendar.left {
        border-left: 1px solid #35475e;
    }
    .daterangepicker .calendar-table {
        background-color: transparent;
        border: 1px solid transparent;
    }
    .daterangepicker td.off {
        background: transparent;
        color: #a2aab242 !important;
    }

    .daterangepicker td.in-range.ends {
        background: #2b2d3e !important;
    }

    .daterangepicker .drp-buttons {
        border-color: #35475e !important;
    }
    .daterangepicker th.month {
        color: #e5e5e5 !important;
    }
    .daterangepicker .calendar-table .prev span,
    .daterangepicker .calendar-table .next span {
        border-color: #fff !important;
    }
    .daterangepicker .drp-selected {
        color: #e5e5e5;
    }
    .drp-buttons .cancelBtn {
        background-color: transparent !important;
    }

    div.ql-editor:after {
        background-color: #494b5b !important;
    }
    /* scrollbar  */
    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
        // box-shadow: inset 0 0 2px #666;
        border-radius: 0px;
        background: #242636;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #37394a;
        border-radius: 0px;
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #c4ddff;
    }

    .ql-toolbar.ql-snow {
        border-color: #494b5b !important;
    }
    .ql-toolbar.ql-snow {
        border-color: #494b5b !important;
        color: #fff !important;
    }

    .ql-container.ql-snow {
        border-color: #494b5b !important;
    }
    .ql-editor blockquote,
    .ql-editor h1,
    .ql-editor h2,
    .ql-editor h3,
    .ql-editor h4,
    .ql-editor h5,
    .ql-editor h6,
    .ql-editor ol,
    .ql-editor p,
    .ql-editor pre,
    .ql-editor ul {
        border-color: #494b5b !important;
        color: #fff !important;
    }
    .ql-editor.ql-blank:before {
        color: #6f7780 !important;
    }
    .ql-snow .ql-picker {
        color: #c9cfd4 !important;
    }
    .ql-snow .ql-stroke {
        color: #c9cfd4 !important;
        stroke: #c9cfd4;
    }
    .css-1nmdiq5-menu {
        background: #242636 !important;
        .css-d7l1ni-option {
            background: #494b5b !important;
        }
        .css-qr46ko {
            color: #fff;
        }
        .css-qr46ko > div {
            &:hover {
                background: #494b5b !important;
            }
        }
    }
    .css-tr4s17-option {
        background: #494b5b !important;
    }

    .css-d7l1ni-option {
        background: #494b5b !important;
    }
    .css-hlgwow {
        // background: #2b2d3e;
        // border: 1px solid #35475e;
        color: #fff;
        outline: none;
    }
    .css-tr4s17-option:active {
        background: #494b5b !important;
    }
    .css-t3ipsp-control {
        background-color: #242636 !important;
        // border: 1px solid #35475e;
        color: #fff;
    }

    .date-picker.dashboard-date .ant-picker {
        border-color: #35475e !important;
        box-shadow: none;
    }

    .ant-picker-dropdown .ant-picker-panel-container {
        background: #242636;
    }
    .ant-picker-dropdown .ant-picker-header,
    .ant-picker-dropdown .ant-picker-content th {
        color: #fff !important;
    }

    .ant-picker-dropdown .ant-picker-cell-in-view {
        color: #fff !important;
    }
    .ant-picker-dropdown .ant-picker-cell {
        color: rgba(221, 221, 221, 0.25);
    }
    .ant-picker-dropdown .ant-picker-header {
        color: #fff;
    }
    .ant-picker-dropdown .ant-picker-header > button {
        color: #fff !important;
    }
    .ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
        background: #12131b;
    }
    .ant-picker-dropdown
        .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
    .ant-picker-dropdown
        .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
        background: #12131b;
    }

    .ant-picker-dropdown
        .ant-picker-date-panel
        .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
        .ant-picker-cell-inner::after,
    .ant-picker-dropdown
        .ant-picker-date-panel
        .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
        .ant-picker-cell-inner::after {
        background: #002962;
    }

    .select-custom-react {
        .css-13cymwt-control {
            background: #242636 !important;
        }
        .css-19bb58m,
        .css-1dimb5e-singleValue {
            color: #fff;
        }
    }
    .mobSearchToggle {
        @media screen and (max-width: 480px) {
            border: 1px solid #35475e;
        }
    }
    .header .lft-sctn .search-bar .searchINWrap {
        @media screen and (max-width: 480px) {
            background-color: #242636;
        }
    }
    .header .lft-sctn .search-bar {
        @include placeholder {
            opacity: 0.49;
            color: #a2bcd6;
        }
    }
    .custom-drop-down .drop-down-btn {
        background-color: transparent;
        border-color: $dark-border;
        color: #828b94;
    }
    .custom-drop-down .dropdown-menu {
        background-color: $dark-bg;
        border-color: $dark-border;
    }
    .custom-drop-down .dropdown-menu .dropdown-item {
        color: #fff;
        &:hover {
            background: #333546;
        }
    }
    .drag-drop-wrap {
        background: #333546;
        border-color: $dark-border;
    }
    .drag-drop-wrap .drag-drop .upload-icon {
        filter: grayscale(100%);
        opacity: 0.5;
    }
    .drag-drop-wrap .upload-content small {
        color: #e5e5e5;
    }
    .drag-drop-wrap .upload-content .or {
        color: #828b94;
    }
    .drag-drop-wrap .upload-content .upload-btn {
        background-color: $dark-bg-2;
        border-color: $dark-border;
        color: #e5e5e5;
    }
    .drag-drop-wrap label {
        color: #fff;
    }
    .profile-drop .dropdown-menu {
        background-color: #11121d;
        box-shadow: 0px 3px 16px #00000047;
        li {
            border-bottom: 1px solid #2b2b2b !important;
            &:last-child .dropdown-item {
                border-bottom: 0 !important;
            }
        }
    }
    .profile-drop .dropdown-menu .dropdown-item {
        color: #fff;
        &:hover,
        &:focus {
            background-color: #1c1c22 !important;
        }
    }
    .profile-pic .icon-camera img {
        filter: grayscale(100%);
    }
    .profile-drop .dropdown-menu .dropdown-item .drop-icon img {
        filter: brightness(0) invert(1);
    }
    .floating-form-block .form-floating .form-control {
        background-color: $dark-bg;
        border-color: $dark-border;
        color: #fff;
    }
    .floating-form-block .form-floating label {
        color: #ffffff;
    }
    .form-inner-cover {
        .form-floating > .form-control:focus ~ label,
        .form-floating > .form-control:not(:placeholder-shown) ~ label,
        .form-floating > .form-select ~ label {
            background-color: $dark-bg;
            color: #a2aab2;
        }
    }

    .filter-cover .select-otr {
        background-color: $dark-bg;
        border-color: $dark-bg;
    }
    .filter-cover .select-custom {
        .form_controler {
            background-color: $dark-bg;
            border-color: $dark-border;
            color: #e5e5e5;
        }
    }
    .filter-cover .fltr-right .sort-select .sort {
        color: $light-clr;
    }
    .filter-cover .filter-btns .btn-fltr.btn-search {
        background: #333546;
        border-color: $dark-border;
        img {
            filter: brightness(0) invert(1);
        }
    }
    .filter-cover .filter-btns .btn-fltr {
        background-color: $dark-bg;
        border-color: $dark-border;
        img {
            filter: brightness(0) invert(1);
            opacity: 0.6;
        }
    }
    .filter-cover .fltr-right .sort-select {
        border-color: $dark-border;
    }
    .filter-cover .fltr-right .sort-select .dropdown-menu {
        background-color: #2b2d3e;
    }
    .filter-cover .fltr-right .sort-select .dropdown-item:hover,
    .filter-cover .fltr-right .sort-select .dropdown-item:active {
        background-color: #1e1e2c;
    }
    .filter-cover .fltr-right .sort-select .dropdown-item {
        color: #e5e5e5;
    }

    .mobHamb {
        .collapsetoggle {
            background: transparent !important;
            .toggle-btn img {
                filter: brightness(0) invert(1);
            }
        }
    }

    .copy-right {
        background-color: #1d1d27;
        p,
        span {
            color: #e5e5e5;
            background-color: #1d1d27;
        }
    }

    //  no data and 404
    .no-data .no-data-content .no-data-txt {
        color: #fff;
    }
    .cover-404 .content-otr .title-404 {
        color: #fff;
    }
    .cover-404 .content-otr p {
        color: #9da1a7;
    }
}

.date-picker.dashboard-date {
    .ant-picker {
        width: 100%;
    }
}

// second-opiton mlty height issue
.select__custom_01.select__custom {
    .css-b62m3t-container {
        height: auto !important;
    }
    .css-13cymwt-control {
        border: 0 !important;
        min-height: 51px !important;
        height: auto !important;
        @media (max-width: 1679px) {
            min-height: 46px !important;
        }
    }
    &:after {
        top: 25px;
    }
}

.controls {
    .select-custom {
        width: auto !important;
        &:after {
            content: '';
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 7px solid #35475e;
        }
        .form-control,
        .css-13cymwt-control,
        .css-t3ipsp-control {
            height: 40px;
            background: #ffffff;
            border: 1px solid #d3e3f8 !important;
            border-radius: 12px;
            font-size: 13px;
            text-align: left;
            font-family: 'Poppins', sans-serif;
            color: #35475e;
            font-weight: normal;
            padding-right: 30px;
            padding-left: 0px;
            box-shadow: none;
            min-width: 65px;
            margin-right: 0;
            margin-left: auto;
        }
    }
    .sect-algn {
        display: flex;
        align-items: center;
        p {
            margin-bottom: 0;
            font-size: 12px;
            text-align: left;
            font-family: 'Poppins', sans-serif;
            color: #35475e;
            margin-right: 15px;
        }
    }
    .btn__group {
        display: flex;
        column-gap: 15px;
        .btn_sqr {
            background-color: #deeeff;
            width: 39px;
            border: 0;
            border-radius: 8px;
            height: 39px;
            & > img {
                width: 16px;
            }
        }
    }
}

/* Your existing CSS styles can remain the same */

.progress-container {
    position: relative;
    zoom: 0.2;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 3px;
    .progress {
        transform: rotate(-90deg);
        height: auto;
    }
}

.progress-circle {
    fill: transparent;
    stroke: #e0e0e0;
    stroke-width: 10;
}

.progress-bar {
    fill: transparent;
    stroke: #00bfff;
    stroke-width: 10;
    stroke-dasharray: 339;
    stroke-dashoffset: 0;
    transition: stroke-dashoffset 0.5s;
}

.progress-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    img {
        width: 50px !important;
    }
}

.custom-select-with-tick.select-custom {
    .form-control {
        padding-top: 8px !important;
    }
    label {
        top: 0 !important;
    }
    .css-13cymwt-control,
    .css-t3ipsp-control {
        border: 0 !important;
        outline: 0 !important;
        box-shadow: none !important;
    }
    .css-tr4s17-option,
    .css-10wo9uf-option {
        font-family: 'Poppins', sans-serif !important;
        font-size: 13px !important;
        padding: 8px 12px !important;
    }
}

.dark-mode {
    .custom-select-with-tick.select-custom {
        .css-qbdosj-Input,
        .css-1dimb5e-singleValue {
            color: #fff !important;
        }
        .css-13cymwt-control,
        .css-t3ipsp-control {
            background-color: transparent !important;
        }
    }
}

.floating-form-block .form-floating label {
    font-weight: normal !important;
}

.sect-algn .controls .select-custom .css-13cymwt-control,
.sect-algn .controls .select-custom .css-t3ipsp-control {
    min-height: 38px !important;
}
.dark-mode {
    .sect-algn .controls .select-custom .css-13cymwt-control,
    .sect-algn .controls .select-custom .css-t3ipsp-control {
        border-color: #35475e !important;
    }
    .controls .btn__group .btn_sqr {
        background-color: #494b5b;
        img {
            -webkit-filter: brightness(0) invert(1);
            filter: brightness(0) invert(1);
        }
    }
    .controls .sect-algn p {
        color: #a2aab2;
    }
}
.addProjectModal {
    .drag-drop-wrap {
        z-index: 1;
    }
}

.common-col {
    width: 155px;
}

.job-table.content-tables {
    .table {
        @media (max-width: 1200px) {
            width: 1200px;
        }
    }
    tbody {
        .tbl-cont {
            max-width: 340px;
            &.rating {
                display: flex;
                align-items: center;
                span {
                    img {
                        margin-right: 6px;
                    }
                }
            }
        }
    }
}
.selectimg {
    // width: 10px !important;
    height: 7px !important;
}
.intvw-tab-cover .intvw-tab-btn-wrapper {
    display: flex;
    border-bottom: solid 1px #e8e6f2;
    justify-content: space-between;

    .lt-navlist {
        @media (max-width: 579px) {
            display: flex;
            overflow-x: auto;
        }
    }
    @media (max-width: 579px) {
        flex-wrap: wrap;
    }
}

.intvw-tab-cover .intvw-tab-btn-wrapper .intvw-tab-btn {
    color: #6c83a2;
    font-size: 15px;
    position: relative;
    margin-right: 36px;
    transition: 0.3s;
    border: none;
    padding: 12px 0;
    background-color: rgba(0, 0, 0, 0);
    @media (max-width: 579px) {
        margin-right: 10px;
        font-size: 13px;
        margin-bottom: 20px;
    }
}
.intvw-tab-cover .intvw-tab-btn-wrapper .intvw-tab-btn::before {
    position: absolute;
    content: '';
    left: 0;
    bottom: 0;
    width: 0;
    height: 3px;
    border-radius: 2px;
    display: block;
    background-color: #0075e9;
    transition: 0.3s;
}
.intvw-tab-cover .intvw-tab-btn-wrapper .intvw-tab-btn.active {
    color: #0075e9;
    font-weight: 500;
}
.intvw-tab-cover .intvw-tab-btn-wrapper .intvw-tab-btn:hover::before,
.intvw-tab-cover .intvw-tab-btn-wrapper .intvw-tab-btn.active::before {
    width: 100%;
}

.intvw-tab-body-cover {
    .primary-title {
        font-size: 18px;
        font-weight: 500;
        line-height: 27px;
        color: #35475e;
        font-family: $poppins;
    }
    .job-post-info {
        display: flex;
        .time {
            text-align: left;
            font-size: 12px;
            line-height: 18px;
            font-family: $poppins;
            font-weight: 400;
            letter-spacing: 0px;
            color: #768598;
            opacity: 1;
            padding-right: 30px;
            .time-img {
                padding-right: 13px;
                img {
                    height: 11px;
                }
                @media (max-width: 579px) {
                    padding-right: 4px;
                }
            }
        }
    }

    .upwork-primary-col {
        margin-top: 15px;

        background: #fafcfead 0% 0% no-repeat padding-box;
        border: 1px solid #e8f0fb;
        border-radius: 18px;
        padding: 30px 40px;

        .primary-info {
            padding-top: 18px;
            .content-txt {
                letter-spacing: 0px;
                color: #35475e;
                line-height: 20px;
                font-size: 13px;
                font-family: $poppins;
                font-weight: 400;
            }
        }
    }
}
.upwork-primary-col.scnd-col {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 800px) {
        flex-wrap: wrap;
    }

    .bold-txt {
        text-align: left;

        line-height: 21px;
        font-size: 14px;
        font-family: $poppins;
        font-weight: 500;

        letter-spacing: 0px;
        color: #35475e;
    }
    .normal-txt {
        letter-spacing: 0px;
        color: #768598;

        line-height: 18px;
        font-size: 12px;
        font-family: $poppins;
        font-weight: 400;
    }
    .sm-box {
        display: flex;
        img {
            height: 13px;
        }
        @media (max-width: 800px) {
            width: 26%;
            padding-bottom: 20px;
        }
        @media (max-width: 579px) {
            width: 100%;
            padding-bottom: 20px;
        }
    }
}
.upwork-primary-col {
    .title {
        font-family: $poppins;
        font-size: 15px;
        line-height: 23px;
        font-weight: 500;
        letter-spacing: 0px;
        color: #35475e;
    }

    .skills-wrap {
        display: flex;
        justify-content: space-between;
        @media ($sm-767) {
            flex-wrap: wrap;
        }
        .skills-right-left {
            @media ($sm-767) {
                padding-bottom: 20px;
            }
        }
        .skill-btn {
            background: rgb(118 132 152 / 7%) 0% 0% no-repeat padding-box;
            border-radius: 19px;
            padding: 9px 18px;
            font-size: 13px;
            font-weight: 400;
            font-family: $poppins;
            letter-spacing: 0px;
            color: #35475e;
            @media ($sm-767) {
                margin-bottom: 10px;
            }
            &.more {
                border: 1px solid #0075e9;
                color: #0075e9;
            }
        }
        .top-tile {
            font-family: $poppins;
            font-size: 13px;
            line-height: 20px;
            letter-spacing: 0px;
            color: #35475e;
            padding-bottom: 13px;
        }
        .list-items {
            ul {
                display: flex;
                padding-inline-start: 0px;
                padding-top: 12px;
                @media ($sm-767) {
                    flex-direction: column;
                }
                li {
                    list-style-type: none;
                    padding-right: 41px;

                    letter-spacing: 0px;
                    color: #35475e;
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 20px;
                    font-family: $poppins;
                }
            }
        }
    }
    .ri-content {
        margin-left: 12px;
    }
}
.next-col {
    .title {
        font-family: $poppins;
        letter-spacing: 0px;
        color: #35475e;
        font-weight: 500;
        font-size: 18px;

        line-height: 27px;
        padding-bottom: 21px;
    }
    .subtitle {
        letter-spacing: 0px;
        color: #768598;
        font-size: 12px;
        font-family: $poppins;
        font-weight: 400;
        padding-bottom: 21px;
    }
    .skills-wrap1 {
        .more-details {
            display: flex;
            justify-content: space-between;
            @media ($sm-767) {
                flex-direction: column;
            }
            .detail-sec {
                max-width: 33%;
                .sec-title {
                    letter-spacing: 0px;
                    color: #35475e;
                    font-size: 14px;
                    line-height: 21px;
                    font-family: $poppins;
                }
                .sec-content {
                    color: #768598;
                    font-size: 12px;
                    font-family: $poppins;
                    font-weight: 400;
                }
                @media ($sm-767) {
                    padding-bottom: 10px;
                }
            }
        }
    }
    .links {
        .link {
            a {
                color: #418eef;
                text-decoration: underline;

                font-size: 13px;
                line-height: 20px;
                font-family: $poppins;
            }
        }
        .txt {
            font-size: 13px;
            line-height: 20px;
            font-family: $poppins;
            color: #35475e;
        }
    }
}
.modal-upwork {
    .modal-dialog .modal-content .modal-body {
        min-height: 890px;
        max-height: 1200px;
    }
}

.next-col {
    .primary-info {
        padding-top: 0px !important;
        .content-txt {
            line-height: 23px;
            padding-bottom: 16px;
        }
    }
}
.open-job {
    color: #0075e9;
    font-size: 14px;
    line-height: 21px;
    font-family: $poppins;
    background: #ebf3ff 0% 0% no-repeat padding-box;
    padding: 9px 18px;
    border-radius: 19px;
    @media ($sm-767) {
        font-size: 12px;
    }
}
.next-col {
    .question-wrp {
        display: flex;
        justify-content: space-between;
        @media ($sm-767) {
            flex-wrap: wrap;
        }
        .question {
            color: #35475e;
            font-size: 14px;
            line-height: 21px;
            font-family: $poppins;
            font-weight: 500;
        }
        .pencil {
            .texts {
                letter-spacing: 0px;
                color: #0075e9;
                font-size: 14px;
                line-height: 21px;
                font-weight: 400;
                font-family: $poppins;
            }
        }
    }

    .messgae-wrp {
        margin-top: 10px;
        margin-bottom: 20px;
        position: relative;
        display: inline-block;
        width: 100%;
        textarea {
            width: 100%;
            border: 1px solid #d7e7fc;
            border-radius: 18px;
            height: 111px;
            padding: 20px;
            &::placeholder {
                color: #bcc5cf;

                font-size: 12px;
                line-height: 18px;
                font-family: $poppins;
            }
        }
        .msg-icon {
            position: absolute;
            right: 10px; /* Adjust the position to your preference */
            top: 50%;
            transform: translateY(-50%);
            // width: 24px; /* Adjust the size of the icon */
            height: 12px; /* Adjust the size of the icon */
            cursor: pointer;
        }
    }
    .pencil {
        display: flex;
        align-items: center;
        span {
            img {
                padding-right: 10px;
            }
        }
    }
}

.add-job-modal {
    font-family: $poppins;
    .subheading {
        font-weight: 500;
        font-size: 16px;
        line-height: 25px;
        color: #35475e;
    }
    .radio-label {
        color: #6c83a2;
        font-size: 11px;
        font-weight: 300;
    }
    .add-job-btn {
        display: flex;
        padding-top: 12px;
        label {
            display: flex;
            align-items: center;
            margin-right: 10px;
            // font: normal normal normal 13px/20px Poppins;
            letter-spacing: 0px;
            color: #35475e;
            font-size: 13px;
            input {
                margin-right: 10px;
                border: 1px solid #b9d0ee;
                border-radius: 11px;
                width: 18px;
                height: 18px;
            }
        }
    }
    .two-inputs {
        display: flex;
        .smallinput {
            width: 100px !important;
            margin-right: 40px;
        }
    }
    span.no-floating {
        position: absolute;
        font-size: 11px;
        top: 15px;
        right: -26px;
        font: normal normal normal 13px / 20px Poppins;
        letter-spacing: 0px;
        color: #35475e;
    }
    .modal-dialog .modal-content .modal-body .modal-data-content .modal-form-area {
        padding: 0px 30px;
    }
    .btn {
        &.btn-add {
            padding: 0px;
        }
    }
    .checkbox {
        label {
            letter-spacing: 0px;
            color: #35475e;
            font-size: 13px;

            font-weight: 300;
        }
    }
}

.multiple-select.multiple-select {
    .select__control {
        // height: unset !important;
    }
    .select__multi-value {
        &:hover {
            .select__multi-value__remove {
                opacity: 1;
            }
        }
        .select__multi-value__remove {
            transition: all ease-in-out 0.15s;
            // opacity: 0;
            &:hover {
                background-color: transparent !important;
            }
            svg {
                fill: #0075e9;
            }
        }
    }
}

//dark -mode

.dark-mode {
    .add-job-modal {
        .subheading {
            h4 {
                color: #fff;
            }
        }
        .radio-label {
            color: #fff;
        }
        .add-job-btn {
            label {
                color: #fff;
            }
        }
    }

    .open-job {
        background-color: #11121d;
        color: #fff;
        img {
            filter: brightness(0) invert(1);
        }
    }

    .intvw-tab-body-cover .upwork-primary-col {
        background-color: #11121d;
        color: #fff;
    }
    .intvw-tab-body-cover .primary-title,
    .intvw-tab-body-cover .job-post-info .time,
    .intvw-tab-body-cover .upwork-primary-col .primary-info .content-txt,
    .upwork-primary-col.scnd-col .bold-txt,
    .upwork-primary-col .title,
    .upwork-primary-col .skills-wrap .top-tile,
    .upwork-primary-col .skills-wrap .skill-btn,
    .upwork-primary-col .skills-wrap .list-items ul li,
    .next-col .subtitle,
    .next-col .skills-wrap1 .more-details .detail-sec .sec-title,
    .next-col .skills-wrap1 .more-details .detail-sec .sec-content,
    .next-col .question-wrp .question,
    .intvw-tab-cover .intvw-tab-btn-wrapper .intvw-tab-btn {
        color: #fff;
    }
    .intvw-tab-cover .intvw-tab-btn-wrapper .intvw-tab-btn.active {
        color: #0075e9 !important;
    }
    .intvw-tab-body-cover .job-post-info .time img,
    .upwork-primary-col.scnd-col .sm-box img {
        filter: brightness(0) invert(1);
    }

    .next-col .messgae-wrp textarea {
        background: #242636;
        border: none;
    }
    .intvw-tab-body-cover .upwork-primary-col {
        border: none;
    }
}

.form-floating {
    .custom-textarea {
        &.form-control {
            height: 120px;
        }
    }
}

.select-label {
    top: -7px !important;
    background: #f2faff;
    font: 500 12px / 20px 'Roboto', sans-serif;
    line-height: 120%;
    color: #7c7f80;
    left: 13px !important;
    padding: 0 7px;
    z-index: 3;
}

.react-select__value-container {
    padding-left: 15px !important;
}
.react-select__menu {
    .react-select__option {
        color: white;
    }
}

// .react-select__control {
//     background: url(../images/selct-traingle-arrow.svg) no-repeat right 18px center !important;
// }

.select-z-menu {
    &__menu-portal {
        z-index: 9999 !important;
    }
}
.select-z-menu__value-container {
    position: relative;
    &:after {
        content: '';
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 7px solid #35475e;
        position: absolute;
        right: 10px;
        .dark-mode & {
            border-top: 7px solid #ffffff;
        }
    }
}
.select-z-menu__option {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
    font-size: 12px !important;
    &.select-z-menu__option--is-selected {
        color: #ffffff !important;
    }
}

body.dark-mode .multiple-select.multiple-select .select__multi-value .select__multi-value__remove svg {
    fill: #fff;
}

.domain-tooltip.domain-tooltip {
    background-color: #ffffff !important;
    border: 1px solid #dfedff;
    color: #6c83a2;
    border-radius: 8px;
    .react-tooltip-arrow {
        border-bottom: 1px solid #dfedff;
        border-left: 1px solid #dfedff;
    }

    .proj-list {
        list-style: none;
        li {
            display: flex;
            align-items: center;
            line-height: 1;
            padding-top: 10px;
            padding-bottom: 10px;
            font-size: 12px;
            .ico {
                width: 12px;
                height: 12px;
                flex: 0 0 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 10px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
    }
}

body.dark-mode {
    .domain-tooltip.domain-tooltip {
        background-color: #11121d !important;
        border: 1px solid #333546;
        .react-tooltip-arrow {
            border-bottom: 1px solid #333546;
            border-left: 1px solid #333546;
        }

        .proj-list {
            li {
                color: #ffffff;
            }
        }
    }
}

.form_controler.form-filter {
    min-width: 300px;
    @media ($sm-1679) {
        min-width: 260px;
    }
    @media (max-width: 1480px) {
        min-width: 210px;
    }
    @media ($sm-1199) {
        min-width: 180px;
    }
    .css-13cymwt-control {
        border: 1px solid #d3e3f8;
        border-radius: 8px;
        display: flex;
        min-width: 300px;
        @media ($sm-1679) {
            height: 40px !important;
            min-width: 260px;
        }
        @media (max-width: 1480px) {
            min-width: 210px;
        }
        @media ($sm-1199) {
            min-width: 180px;
        }
        &::hover {
            border: 1px solid #d3e3f8;
        }
    }
    .select-z-menu__control {
        height: 51px !important;
        @media ($sm-1679) {
            height: 40px !important;
        }
    }
}

.heading-cvr .rgt-scnt .headingForm .searchInput.search-new {
    min-height: 53px;
    min-width: 273px;
    @media ($sm-1679) {
        min-height: 40px;
        min-width: 260px;
    }
    @media (max-width: 1480px) {
        min-width: 210px;
    }
    @media ($sm-1199) {
        min-width: 180px;
    }
}

.reset-filter {
    border: none;
    border-radius: 50px;
    height: 40px;
    width: 40px;
    background-color: #fff;
    img {
        width: 20px;
    }
}

.input-filter {
    @media ($sm-767) {
        flex: 1 1;
    }
}

.dark-mode {
    .form_controler.form-filter {
        .css-13cymwt-control {
            color: #fff;
            background-color: #2b2d3e;
            border-color: #2b2d3e !important;
        }
    }
    .reset-filter {
        background-color: #2b2d3e;
        border-color: #2b2d3e !important;
    }
}

.form_controler.form-filter.multiple-select {
    width: 300px;
    @media ($sm-1679) {
        width: 260px;
    }
    @media (max-width: 1480px) {
        width: 100%;
    }
    .css-13cymwt-control {
        border: 1px solid #d3e3f8;
        border-radius: 8px;
        display: flex;
        width: 300px;
        height: auto !important;
        min-height: 51px !important;
        @media ($sm-1679) {
            min-height: 40px !important;
            width: 260px;
        }
        @media (max-width: 1480px) {
            width: 100%;
        }
        &::hover {
            border: 1px solid #d3e3f8;
        }
    }
    .select-z-menu__control {
        height: auto !important;
        min-height: 51px !important;
        @media ($sm-1679) {
            min-height: 40px !important;
        }
    }
}
.dark-mode {
    .select-z-menu__single-value.css-1dimb5e-singleValue{
    color:#fff;
    }}