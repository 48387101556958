/* Custom margin */
.mr-cust-2 {
    margin-right: 5px !important;
}
.mr-cust-4 {
    margin-right: 10px !important;
}
.mb-cust-4 {
    margin-bottom: 15px !important;
}
/* Cursor */
.cursor-pointer {
    cursor: pointer !important;
}

/* React Quill */
.quil-container-style {
    /* height: 200px !important; */
    /* margin-bottom: 110px; */
}
.quil-container-style .ql-toolbar.ql-snow + .ql-container.ql-snow {
    height: 200px;
}
.quil-container-style .ql-toolbar.ql-snow {
    border-radius: 12px 12px 0px 0px;
}

.error.quil-container-style .ql-toolbar.ql-snow {
    border-color: #e80909 !important;
}
.error.quil-container-style .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-color: #e80909 !important;
}

.quil-container-style .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-radius: 0px 0px 12px 12px;
}

/* Date Picker Custom  */

.custom-datepicker .ant-picker-input input {
    border-radius: 8px;
    padding: 6px 22px;
    background-repeat: no-repeat;
    background-position: 95% 50%;
    font: 13px/20px Poppins;
    position: relative;
}

.ant-picker {
    background-color: transparent;
    border: 1px solid #c4ddff;
}

.ant-picker-dropdown {
    z-index: 10000;
}
.select-custom-react .css-13cymwt-control {
    border: none;
    border-radius: 8px;
}
.select-custom-react .css-1u9des2-indicatorSeparator {
    background: none;
}
.select-custom-react .css-1wy0on6 {
    display: none;
}

.select-custom.select-custom-react:after {
    pointer-events: none;
}
.css-13cymwt-control:hover {
    border: none !important;
}
.custom-datepicker {
    background-color: transparent;
    border: 1px solid #c4ddff !important;
}

.custom-datepicker .ant-picker-input input {
    padding: 6px 22px 6px 0;
    min-width: 150px;
}

.custom-datepicker .ant-picker-input ::-webkit-input-placeholder {
    color: #7c7f80;
}
.custom-datepicker .ant-picker-input :-moz-placeholder {
    color: #7c7f80;
}
.custom-datepicker .ant-picker-input ::-moz-placeholder {
    color: #7c7f80;
}
.custom-datepicker .ant-picker-input :-ms-input-placeholder {
    color: #7c7f80;
}
.floating-label.select-box-label-react.select-custom-react .floating-input.css-b62m3t-container {
    padding: 5px !important;
}
.select-custom-react .css-t3ipsp-control {
    box-shadow: none !important;
    border-color: transparent !important;
    border-radius: 8px;
}
.user-name {
    white-space: normal;
}

.dark-mode .custom-datepicker .ant-picker-input ::-webkit-input-placeholder {
    color: #e5e5e5;
}
.dark-mode .custom-datepicker .ant-picker-input :-moz-placeholder {
    color: #e5e5e5;
}
.dark-mode .custom-datepicker .ant-picker-input ::-moz-placeholder {
    color: #e5e5e5;
}
.dark-mode .custom-datepicker .ant-picker-input :-ms-input-placeholder {
    color: #e5e5e5;
}

/* Toastify style */
.Toastify__toast {
    border-radius: 10px;
}
.Toastify__toast-icon {
    width: 35px;
}
.Toastify__toast--success {
    border: 1px solid #3ebe63;
    background-color: #eaf7ee !important;
}
.Toastify__toast--error {
    border: 1px solid #f1766a;
    background-color: #ffe5e2 !important;
}
.Toastify__toast-body {
    color: #393939;
    font: 500 13px/17px Poppins;
    padding-right: 25px;
}
.Toastify__close-button {
    color: #333;
}
/* .Toastify__toast-theme--dark {
} */
.Toastify__close-button {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    right: 10px;
}

/* Error */
.error-txt {
    font-size: 13px;
    font-family: 'Poppins', sans-serif;
    color: #ff9aa4;
    position: absolute;
    left: 12px;
    bottom: -20px;
}

.modal_backdrop {
    background-color: rgba(26, 44, 67, 0.2);
}

.feature-actions {
    background-color: rgba(26, 44, 67, 0.2);
}

.feature-actions.modal-full-width .modal-dialog {
    max-width: 900px;
}
.feature-actions.modal-full-width .modal-dialog .modal-content {
    height: auto;
}
@media (max-width: 991px) {
    .error-txt {
        font-size: 12px;
    }
}

/* Spinner */
.custom-spinner {
    margin-right: 10px;
}

@media (max-width: 767px) {
    div.ql-editor:after {
        left: 17px;
        right: 17px;
    }
    .quil-container-style .ql-toolbar.ql-snow + .ql-container.ql-snow {
        height: 150px;
    }
    div.ql-editor {
        padding: 12px 17px !important;
    }
    .ql-toolbar.ql-snow {
        padding-top: 17px !important;
    }
    .custom-mobile-width-120 {
        min-width: 120px;
    }
    .custom-mobile-width-200 {
        min-width: 200px;
    }
}
.custom-width-120 {
    min-width: 120px;
}

.custom-capitalize {
    text-transform: capitalize;
}

/* Info circle */
.custom-info-circle {
    position: absolute;
    top: 20px;
    right: -20px;
}

@media (max-width: 575px) {
    .custom-info-circle {
        font-size: 12px !important;
        margin-right: 5px !important;
    }
}

/* 404 height */
.custom404-height {
    height: 80vh !important;
}

/* form input */
.custom-form-err {
    border: 1px solid #e00b0b !important;
    border-radius: 12px;
}

/* Error proposal */
.err-proposal {
    border-color: red !important;
}

/* Dark mode text editor  */
body.dark-mode .css-14h4o58-menu {
    background: #242636;
}

/* Removing Drop symbol */

.remove-drop.select-custom:after {
    display: none;
}

/* React Select Error */
.modal-add-proposal .select-custom-react.err-select .css-hlgwow {
    border-color: #e11534 !important;
}

/* High Chart remove watermark */
.highcharts-credits {
    display: none !important;
}

.bg-texteditor {
    background-color: white;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}

.dark-mode .bg-texteditor {
    background-color: #242636;
}

/* Quill */
.ql-editor ol li:before {
    content: '\27A2' !important;
}

/* .ql-editor ol, */
.ql-editor ul {
    padding-left: 12.5px !important;
}
.ql-editor ol {
    padding-left: 35.5px !important;
    margin-top: 5px !important;
}
.dark-mode .tooltip-action {
    background-color: #fff !important;
    color: #333;
}
.tooltip-action {
    background-color: #555 !important;
    color: #fff;
    font-size: 13px !important;
    padding: 10px;
    font-family: 'Poppins', sans-serif;
}

.info-icon {
    color: black;
}
.dark-mode .info-icon {
    color: white;
}

@media print {
    /* table {
        border-collapse: collapse !important;
    }

    td,
    th {
        border: 1px solid black !important;
        padding: 8px;
    } */

    .imp-display-table {
        display: table-row !important;
    }
}

/* Background style Add Project */
.test-mod-bg {
    background: #f0f1f2d8 0% 0% no-repeat padding-box;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 10px;
}

.test-mod-bg .test-mod-btncont {
    width: 100%;
    display: flex;
    justify-content: end;
    margin-bottom: 10px;
}

/* slider */
.thumb-slide {
    height: 110px;
}

.file-input > .label {
    width: 120px;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    display: inline-block;
    top: 50%;
    left: 70%;
    transform: translate(-50%, -50%);
}

/* Message Modal */
.modal.pd-low .modal-dialog .modal-content .modal-header {
    padding-top: 30px !important;
}

/* custom Dropdown */
.dropdown-menu.custom-drop.show {
    position: fixed !important;
    inset: inherit !important;
    transform: none !important;
    display: block !important;
}
.dropdown-menu.custom-drop {
    display: none !important;
}

.onepage-cover {
    max-width: 1000px;
    margin: 0 auto;

    /* padding: 25px; */
    /* background: #fff; */
}

.onepage-cover .container-view {
    max-width: 100%;
}
/* .custom-spacing {
    height: 80px;
    width: 100%;
} */

/* React quil scroll */
div.ql-editor {
    padding: 12px 25px !important;
    padding-top: 0px !important;
    position: relative;
    padding-top: 30px !important;
    cursor: pointer !important;
}

.css-1n6sfyn-MenuList {
    max-height: 200px;
}

.dark-mode .css-14h4o58-menu,
.dark-mode .css-1nmdiq5-menu {
    border: 1px solid #33364a;
}
.css-1jqq78o-placeholder {
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 90%;
    overflow: hidden;
    color: #35475e;
    font-size: 13px;
    line-height: 18px;
}

@media (max-width: 1679px) {
    .css-1n6sfyn-MenuList {
        max-height: 145px !important;
    }
}

.slider-width {
    width: 120px;
    height: auto;
}

.thumbnail-height {
    height: 340px;
}

/* image Indicator */
.custom-tag {
    width: 25px !important;
    margin-right: 5px !important;
}
.custom-tags-otr {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 15px;
    row-gap: 15px;
    width: auto !important;
    margin-right: 5px;
}

/* File error */
.error-msg-file {
    color: #f85050;
    font-size: 14px;
    bottom: 0px;
    left: 0;
    width: 100%;
    font: 13px/21px Poppins;
    position: relative !important;
}

.error-msg-custom {
    /* position: relative !important; */
    color: #f85050;
    font-size: 14px;
    bottom: 0px;
    left: 0;
    width: 100%;
    font: 13px/21px Poppins;
}

.file-format.transform-fix {
    transform: translateY(-28px);
}

.col-ftr {
    padding: 0 20px;
}
.width-75 {
    max-width: 75% !important;
}
body.dark-mode.status-02 .status-round.delete.active {
    box-shadow: 0 0 0 0.15rem #9c4747e0;
}
body.dark-mode.status-02 .status-round.active {
    -webkit-box-shadow: 0 0 0 0.15rem #a1e4b6;
    box-shadow: 0 0 0 0.15rem #12983cba;
}
.status-02 .status-round.delete.active {
    box-shadow: 0 0 0 0.15rem #f36a6ae0;
}
.status-02 .status-round.active {
    -webkit-box-shadow: 0 0 0 0.15rem #a1e4b6;
    box-shadow: 0 0 0 0.15rem #6eee96ba;
}

.select-with-image .css-1nmdiq5-menu img {
    border-radius: 50%;
    background-color: #f4f5f7;
    height: 24px;
    overflow: hidden;
}

.select-with-image .css-1n6sfyn-MenuList div > div {
    align-items: center;
    font: 13px/21px Poppins;
    color: #35475e;
}
.error-msg-custom,
.error-msg-file {
    position: absolute !important;
    bottom: -24px;
}

.floating-input:not(:placeholder-shown) ~ label span,
.form-floating > .form-control:not(:placeholder-shown) ~ label span {
    color: #f85050 !important;
}

.floating-input:focus ~ label span,
.form-floating > .form-control:focus ~ label span {
    color: #f85050 !important;
}

@media print {
    .portfolio-cover {
        border-radius: 0 !important;
        box-shadow: none !important;
        margin: 0 auto !important;
        page-break-after: always;
    }

    .details-wrap .inner__content .portfolio-col ul li .tag {
        -webkit-print-color-adjust: exact;
    }

    .portfolio-cover.main-page {
        -webkit-print-color-adjust: exact;
    }
}

.container-view ol {
    counter-reset: item;
}
.nbr-list li {
    display: block;
}
.nbr-list li:before {
    content: counters(item, '.') '. ';
    counter-increment: item;
}

.login-cover .back-btn-block .custom-spinner {
    color: #dcdbf9 !important;
}

.test-no-break {
    white-space: nowrap !important;
}

.uploade-otr .floating-form-block.mb-4 {
    margin-bottom: 35px !important;
}

.modal-full-width .modal-dialog .modal-content .sub-heading {
    position: relative;
}
.modal-full-width .modal-dialog .modal-content .sub-heading .cls_btn {
    width: 20px;
    height: 20px;
    position: absolute;
    right: -12px;
    top: -10px;
    border-radius: 50%;
    background: #fff;
    padding: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid #ddd;
}

/* Custom title */
.custom-cnt-title {
    display: flex;
    justify-content: center;
    margin: 10px;
}

.content-area-view {
    display: flex;
    flex-direction: column;
}

.cursor-area {
}

.cursor-area span {
    cursor: pointer;
    width: 80px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    border-radius: 5px;
    border: 1px solid transparent;
}

.cursor-area span:hover {
    background-color: #e1f0ff;
    border: 1px solid #d3e3f8;
    color: #546377;
}

.dark-mode .cursor-area span:hover {
    background-color: #2b2d3d;
    border: 1px solid #333546;
    color: #e5e5e5;
}

/* .css-1nmdiq5-menu {
    z-index: 99 !important;
} */

.css-1n6sfyn-MenuList {
    max-height: 140px !important;
    font-family: Poppins;
}

.css-n94xjb-MenuPortal {
    z-index: 999 !important;
}
.custom-err-border {
    border-color: #d44856 !important;
}

.form-grp.inputvbtn .css-13cymwt-control,
.form-grp.inputvbtn .css-t3ipsp-control {
    border: 0 !important;
    outline: none;
    border: 0 !important;
    outline: none;
}

/* Resource table switch */
.tab_btns_otr .tb-btn.lst {
    left: -12px;
    margin-left: -12px;
}

.proposal-add-modal .step-tab-panel .select-custom.select-custom-react .css-1n6sfyn-MenuList {
    max-height: 140px !important;
}

.proposal-add-modal .step-tab-panel .css-b62m3t-container {
    min-width: 150px;
}

.one-line span {
    padding-left: 5px;
}
ol.nbr-list li:first-child::after {
    left: 5px !important;
}
ol.nbr-list li:after {
    /* content: '.'; */
    font-size: 13px;
    left: 10px;
    top: 0px;
    position: absolute;
}

ol.sub-list li:after {
    display: none !important;
}

@media (max-width: 1679px) {
    .table-area .value-area .table tr td.img-td {
        width: 95px;
    }
}

.portfolio-preview-sec .portfolio-slider .portfolio-cover.main-page {
    box-shadow: none !important;
}

.sm__table .img-content {
    justify-content: left !important;
}
.modal .modal-dialog .modal-content .nbr-list li:before {
    /* content: "in" */
}

.input-custom-error {
    position: relative;
}

.input-custom-error .error-msg {
    bottom: 34px !important;
}
.file-lister {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}
.file-lister .file-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
    width: 144px;
    width: calc(144px - 15px) !important;
    cursor: pointer;
    margin: 10px 5px;
}
@media (max-width: 1199px) {
    .file-lister .file-details {
        width: 139px;
        width: calc(139px - 15px) !important;
    }
}
@media (max-width: 991px) {
    .file-lister .file-details {
        width: 197px;
        width: calc(197px - 15px) !important;
    }
}
@media (max-width: 767px) {
    .file-lister .file-details {
        width: 50%;
        width: calc(50% - 15px) !important;
    }
}
@media (max-width: 579px) {
    .file-lister .file-details {
        width: 100%;
        width: calc(100% - 24px) !important;
    }
}
.file-lister .file-details .otr {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
}
.file-lister .file-details .otr .file_details {
    display: none;
    padding: 10px;
}
.file-lister .file-details .otr .file_details .file-name {
    max-width: 10ch;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 15px;
    color: #fff !important;
}
.file-lister .file-details .otr .file_details .size-info {
    color: #fff !important;
}
.file-lister .file-details .format-icon {
    flex-shrink: 0;
    margin-right: 0px;
    width: 100% !important;
    height: 75px;
    object-fit: cover;
    cursor: pointer;
}
.file-lister .file-details .format-icon img {
    border-radius: 12px;
}
.file-lister .file-details .otr:hover .close_btn {
    opacity: 1;
}
.file-lister .file-details .close_btn {
    position: absolute;
    top: 10px;
    right: 10px;
    opacity: 0;
    transition: all 0.5s linear;
}
.file-lister .file-details:hover .format-icon img {
    filter: blur(3px);
    -webkit-filter: blur(3px);
}
.file-lister .file-details:hover .otr .file_details {
    display: block;
    position: absolute;
    font-size: 12px;
}

.modal-full-width .modal-dialog .modal-content .table-cover .table-custom p {
    word-break: break-word;
}

.modal-full-width .modal-dialog .modal-content .table-cover .table-custom td strong {
    color: #35475e;
    font: 600 13px/20px Poppins;
    word-break: break-word;
}
.feature-reject {
    background: #bf9e9e;
    border: 1px solid #bdefc3;
    color: #222e23;
}

.feature-approve {
    background: #5bba67;
    border: 1px solid #bdefc3;
    color: #334135;
    background: #c7f3cc;
    border: 1px solid #22ba3463;
    color: #3e6343;
}
.feature-add {
    background: #e09b156b;
    border: 1px solid #ffc1079c;
    color: #9d7706;
}

.form-grp.inputvbtn .css-13cymwt-control,
.form-grp.inputvbtn .css-t3ipsp-control {
    box-shadow: none;
}

.modal-designation .modal-dialog .modal-content .modal-header .btn-close {
    cursor: pointer;
}
.disable-style {
    opacity: 1 !important;
}

.login-cover .login-wrapper .custom-spinner {
    margin-right: 10px;
    color: #d7d7d7 !important;
}
.font-wt-normal {
    font-weight: normal !important;
}
.container-view th,
.container-view td {
    font-weight: normal;
}
.estimation td {
    font-weight: 500 !important;
}
.red-color {
    color: red !important;
}

.text-editor-bx {
    position: relative;
}

.text-editor-bx .floating-txt {
    position: absolute;
    top: -10px;
    left: 15px;
    color: #6c83a2;
    font: 200 13px/17px Poppins;
    background-color: #fff;
    padding: 3px 8px;
    z-index: 0;
    font-size: 12px;
}
.dark-mode .text-editor-bx .floating-txt {
    background-color: #242636 !important;
    color: #a2aab2;
}

.select-custom-react .css-13cymwt-control {
    background-color: transparent;
}
.modal-form-area.add-form .css-13cymwt-control,
.css-t3ipsp-control {
    min-height: 36px !important;
}

.modal-form-area.add-form .floating-input.select {
    background-image: none !important;
}
.custom-info-circle {
    min-height: 15px !important;
    opacity: 1 !important;
    pointer-events: auto !important;
}
@media (max-width: 1679px) {
    .uploade-otr .floating-form-block {
        margin-top: 20px;
    }
}

.content-tables table.table .tbl-cont span p {
    margin-bottom: 0 !important;
}

.details-wrap .inner__content .portfolio-col p .linkicon img {
    margin: 0;
    display: inline-block;
}

.portfolio-preview-sec .portfolio-slider .box__full .large-view .ul-li-table td {
    font: normal 13px/21px Poppins;
}

.modal .modal-dialog .modal-content .modal-body .css-tr4s17-option,
.modal .modal-dialog .modal-content .modal-body .css-d7l1ni-option {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    font-size: 12px !important;
}
.css-1wlit7h-NoOptionsMessage,
.css-1nmdiq5-menu {
    font-size: 15px !important;
}
.cover-elements .tags-otr .tag {
    margin-bottom: 5px;
}

.copy-btn.me-0 {
    width: 200px;
}
.text-editor-bx .floating-txt span {
    color: #f85050 !important;
}
.content-tables table.table .tbl-cont strong {
    word-break: break-word;
}

.download_as .dl-options .input-wrp .error-msg {
    bottom: -18px;
    font-size: 11px;
}

.label-fix .select__cstm label span {
    background-color: #fff;
}
.label-fix .select__cstm label {
    top: -6px !important;
    z-index: 9;
}

.dark-mode .label-fix .select__cstm label span {
    background: #242636;
}

.inline-edit {
    border: 0;
    /* background-color: transparent; */
    color: #35475e;
    font: 13px/20px Poppins;
    width: 40px;
    border-radius: 5px;
    height: 30px;
    text-align: center;
    transition: 0.1s;
    padding: 0;
}

.inline-edit:focus {
    box-shadow: rgb(13 110 253 / 25%) 0px 0px 0px 0.1rem !important;
}

.default-input {
    border: 0;
    background: transparent;
    color: #35475e;
    font: 13px/20px Poppins;
    transition: 0.1s;
    width: 100px;
    text-align: center;
}
.select-custom-react .css-13cymwt-control,
.select-custom-react .css-t3ipsp-control {
    z-index: 0 !important;
}

.modal-designation .modal-dialog .modal-content .modal-body .floating-form-block .form-floating label {
    z-index: 0 !important;
}

.select-height .css-13cymwt-control,
.select-height .css-t3ipsp-control {
    min-height: 38px !important;
}
.disable-input-otr .form-control {
    border: 0 !important;
    background-color: transparent !important;
    padding-left: 9px !important;
    color: #6c83a2 !important;
}

.disable-input-otr .form-floating label {
    background-color: transparent !important;
    padding-left: 0 !important;
    font-weight: 600 !important;
    color: #6c83a2 !important;
}
.cover-elements .chk-bx-cvr .ck-label {
    z-index: 0 !important;
}

.z-index-inherit {
    z-index: inherit !important;
}

.select__custom .css-b62m3t-container {
    z-index: 1 !important;
}

.custom-drop01 .select-custom.select-custom-react:after {
    z-index: 99 !important;
}
.label-custom span {
    color: #f85050 !important;
}
.label-custom {
    z-index: 99 !important;
    display: inline-flex;
    position: absolute;
    height: auto !important;
    top: 0 !important;
    width: auto !important;
}

.label-index {
    z-index: 999 !important;
}

label.asterisks span {
    color: #f85050 !important;
}

label.asterisks {
    top: -6px !important;
}

.select__cstm.select-custom.select-custom-react .css-16xfy0z-control {
    min-height: 48px;
    background-color: rgb(181 181 181 / 18%);
    border-color: rgb(230 230 230 / 17%);
}

.text-editor-bx .floating-txt {
    font-weight: 400;
}

.select-custom-fluid.select___custom .css-13cymwt-control {
    min-height: 51px;
    height: auto !important;
}

body.dark-mode .sub_item_list_otr {
    border: 1px solid #494b5b !important;
}

.custom__col {
    flex: 0 0 auto;
    width: 16.6666%;
}
.custom__col .css-1wy0on6 {
    display: none;
}
@media (max-width: 1441px) {
    .custom__col {
        width: 20%;
    }
}
@media (max-width: 1199px) {
    .custom__col {
        flex: 0 0 auto;
        width: 33.33333333%;
    }
}

@media (max-width: 767px) {
    .custom__col {
        flex: 0 0 auto;
        width: 50%;
    }
}

.modal
    .modal-dialog
    .modal-content
    .modal-body
    .multiple-select
    .select__menu-portal
    .select__menu
    .select__menu-list
    .select__option {
    padding: 8px 12px !important;
}
.modal
    .modal-dialog
    .modal-content
    .modal-body
    .multiple-select
    .select__menu-portal
    .select__menu
    .select__menu-list
    .select__option:hover {
    padding: 8px 12px !important;
}

/* custom-select */

.modal .modal-dialog .modal-content .modal-body .multiple-select .select__control.select__control {
    min-height: 51px !important;
    height: unset !important;
}
@media (max-width: 1679px) {
}
.modal
    .modal-dialog
    .modal-content
    .modal-body
    .select-custom-react
    .select__menu-portal
    .select__menu
    .select__menu-list
    .select__option {
    padding: 8px 12px !important;
}
.modal
    .modal-dialog
    .modal-content
    .modal-body
    .select-custom-react
    .select__menu-portal
    .select__menu
    .select__menu-list
    .select__option:hover {
    padding: 8px 12px !important;
}
.select-z-menu__control {
    height: 51px !important;
}
.select-z-menu__indicator {
    display: block !important;
}
.select-z-menu__menu {
    box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
    border: 1px solid #d3e3f8;
}
.select-z-menu__menu-list {
    max-height: 150px !important;
    font-family: Poppins;
}
.select-z-menu__option {
    padding: 8px 12px !important;
    color: #333333 !important;
    font-size: 12px !important;
    line-height: 18px !important;
    cursor: pointer;
}
body.dark-mode .select-z-menu__menu {
    border: 1px solid #33364a;
    background: #242636 !important;
}
body.dark-mode .select-z-menu__option {
    padding: 8px 12px !important;
    color: #fff !important;
    font-size: 12px !important;
    line-height: 18px !important;
}
