@import 'partials/variables';
@import 'partials/mixins';

.count-section {
    display: flex;
    // column-gap: 17px;
    margin-bottom: 18px;
    margin-left: -8px;
    margin-right: -8px;
    > div {
        padding-left: 8px;
        padding-right: 8px;
    }

    .count-bx {
        background: #ffffff;
        border-radius: 15px;
        min-height: 138px;
        padding: 20px 20px 20px 30px;
        display: flex;
        align-items: center;
        // flex: 1 0 0%;
        .icon-lg {
            margin-right: 15px;
            width: 60px;
            flex-shrink: 0;
        }

        .content {
            color: #393939;
            .strong {
                font: 500 15px/23px Poppins;
            }

            .count {
                font: 600 29px/43px Poppins;
            }
        }
    }
}

.chart-areas {
    // display: flex;
    // column-gap: 17px;
    margin-left: -8px;
    margin-right: -8px;
    > div {
        padding-left: 8px;
        padding-right: 8px;
    }

    .chart-box {
        background: #ffffff;
        border-radius: 15px;
        padding: 35px;
        // flex: 1 0 0%;
        min-height: 400px;
        .chart-head {
            display: flex;
            align-items: center;
            .title-sm {
                font: 500 16px/25px Poppins;
                color: #393939;
                .strong {
                    color: #0075e9;
                    font: 500 24px/35px Poppins;
                }
            }
        }
        .chart {
            margin-top: 25px;
        }
    }
}

.chart-areas .chart-box {
    .css-hlgwow {
        border: 1px solid #ecf7ff;
        border-radius: 6px;
        height: 40px;
        min-width: 125px;
        padding: 3px 10px;
        color: #393939;
        font: 500 15px/23px Poppins;
    }
}

.chart-areas .chart-box{
    margin-bottom: 10px;
}

.profile-drop .dropdown-menu .dropdown-item{
    cursor: pointer;
}

@media (max-width: 1679px) {
    .count-section .count-bx .content .strong {
        font: 500 14px/23px Poppins;
    }

    .count-section .count-bx {
        min-height: 111px;
        padding: 17px 13px 17px 13px;
    }
    .count-section .count-bx .icon-lg {
        margin-right: 10px;
        width: 48px;
    }
    .count-section .count-bx .content .count {
        font: 600 25px/34px Poppins;
    }
    .chart-areas .chart-box {
        padding: 20px;
    }
    .chart-areas .chart-box .chart-head .title-sm .strong {
        font: 500 20px/35px Poppins;
    }
    .chart-areas .chart-box .chart-head .title-sm {
        font: 500 14px/25px Poppins;
    }
    .chart-areas .chart-box {
        min-height: 300px;
    }
    .chart-areas .chart-box .css-hlgwow {
        font: 500 14px/23px Poppins;
        min-width: 105px;
    }
}

@media (max-width: 1281px) {
}

@media (max-width: 1199px) {

.chart-areas .chart-box .chart-head {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
}
.chart-areas .chart-box .select-custom{
    margin-left: 0;
}
}

@media (max-width: 1023px) {
    .count-section {
        flex-wrap: wrap;
    }
    .count-section .count-bx {
        flex: 1 0 40%;
        // flex: 0 0 auto;
        // width: 50%;
        margin-bottom: 15px;
    }
}

@media (max-width: 991px) {
    .count-section .count-bx {
        // flex: 1 0 40%;
    }
    .count-section .count-bx .content .strong {
        font: 500 13px/23px Poppins;
    }
    .count-section .count-bx .content .count {
        font: 600 20px/34px Poppins;
    }
    .count-section .count-bx {
        min-height: 90px;
    }
    .chart-areas .chart-box .css-hlgwow {
        font: 500 13px/17px Poppins;
    }
}

@media (max-width: 767px) {
    .chart-areas {
        flex-wrap: wrap;
    }
    .chart-areas .chart-box {
        margin-bottom: 15px;
    }
}

@media (max-width: 579px) {
   
    .count-section .count-bx {
        // flex: 0 0 auto;
        // width: 100%;
    }
}

// dark theme
.dark-mode {
    .chart-areas .chart-box .css-hlgwow {
        border-color: #35475e;
    }

    .chart-areas .chart-box {
        .css-1dimb5e-singleValue {
            color: #fff !important;
        }
        .css-hlgwow {
            background: #2b2d3e;
            border-color: #35475e !important;
            color: #fff;
            &:focus-visible {
                border: 1px solid #35475e;
            }
        }
    }

    .chart-areas .chart-box {
        .select-custom select {
            background: #2b2d3e;
        }
    }

    .count-section .count-bx {
        background: $dark-bg;
    }
    .chart-areas .chart-box {
        background: $dark-bg;
    }
    .count-section .count-bx .content .strong {
        color: #cac8c8;
    }
    .count-section .count-bx .content .count {
        color: #fff;
    }

    .chart-areas .chart-box .chart-head .title-sm {
        color: #ffffff;
    }
    .chart-areas .chart-box .chart-head .title-sm .strong {
        color: #fff;
    }
}

@media (max-width: 479px) {
    .chart-areas .chart-box .select-custom{
        margin-left: 0;
        width: 100%;
    }
    .chart-areas .chart-box .chart-head {
        flex-wrap: wrap;
        flex-direction: column;
        align-items: flex-start;
    }
    .chart-areas .chart-box .chart-head {
        flex-wrap: wrap;
    }
}
