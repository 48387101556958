
@import "partials/mixins";
.heading-cvr{
    
    flex-wrap: wrap;
    @media screen and (min-width: 1680px){
        padding-top: 40px;
    }
    .rgt-scnt{
        @media screen and (max-width: 1100px){
            flex: 0 0 100%;
            max-width: 100%;
            padding-top: 30px; 
        }
        @media screen and (max-width: 767px){
            padding-top: 0px;
        }
        .headingForm{
            display: flex;
            align-items: center;
            gap: 16px;
            .input-wrp.search{
                flex: 1;
            }
            .searchInput{
                background: url(../img/icon-search.svg) no-repeat right 20px center/20px;
                background-color: #fff;
                border: none;
                border-radius: 40px;
                min-width: 430px;
                padding-left: 32px;
                padding-right: 50px;
                color: #6C83A2;
                font-family: 'Poppins', sans-serif;
                min-height: 40px;
                font-size: 14px;
                @media screen and (max-width: 1200px){
                    max-width: 240px;
                    min-height: 32px;
                    font-size: 12px;
                    padding-left: 10px;
                     
                }
                @media screen and (max-width: 1100px){
                    max-width: 100%;
                }
                &:focus{
                    box-shadow: none;
                }
                &::placeholder{
                    color: #A2BCD6;
                    font-weight: 300;
                }
            }

            .btn{
                border-radius: 40px;
                font-size: 14px;
                padding: 8px 25px;
                display: flex;
                align-items: center;
                min-height: 40px;
                gap: 15px;
                font-family: 'Poppins', sans-serif;
                @media screen and (max-width: 1200px){
                  font-size: 12px;  
                  gap: 8px;
                  min-height: 32px;
                  padding-left: 10px;
                  padding-right: 10px;
                }
                .ico{
                    width: 20px;
                    height: 20px;
                    flex: 0 0 20px;
                    display: flex;
                    align-items: center;
                    @media screen and (max-width: 1200px){
                        width: 14px;
                        height: 14px;
                        flex: 0 0 14px;
                    }
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
                &:focus{
                    box-shadow: none;
                }
                &.btn-filter{
                    background-color: #E1F0FF;
                    border: 1px solid #D3E3F8;
                    color: #546377;
                    &:hover{
                        background: #E1F0FF;
                    }
                }

                &.btn-add{
                    background-color: #0075E9;
                    border: 1px solid #0075E9;
                    color: #ffffff;
                    &:hover{
                        background-color: #1A8DFF;
                        border-color: #1A8DFF;
                    }
                }
            }

            .dropdown-menu{
                padding: 0;
                border: 1px solid #DFEDFF;
                overflow: hidden;
                border-radius: 12px;
                font-family: 'Poppins', sans-serif;
                font-weight: 300;
                font-size: 14px;
                box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
                .dropdown-item{
                    padding: 10px 15px;
                    color: #6C83A2;
                    &:hover{
                       background-color: #E1F0FF; 
                    }
                }
            }
        }
    }
}
.header{
    @media screen and (max-width: 450px){
        // display: none;
    }
}

// dark mode
body.dark-mode.dark-mode{
    .heading-cvr{
        .rgt-scnt{
            .headingForm{
                .searchInput{
                    background-color: #2B2D3E;
                    background-image: url(../img/dark-search-icon-01.svg);
                    color: #FFFFFF;
                    border-color: #2B2D3E !important;
                }
                .btn.btn-filter{
                    background-color: #2B2D3E;
                    border: 1px solid #343647;
                    color: #9EAEC4;
                    &:hover{
                        background-color: #2B2D3E;
                    }
                }

                .dropdown-menu{
                    border: 1px solid #153563;
                    background-color: #05213C;
                    .dropdown-item{
                        &:hover{
                            background-color: #0075E9; 
                            color: #ffffff;
                         }
                    }
                }
              }
         }
    }   
    .heading-cvr .rgt-scnt .headingForm .btn.btn-add{
        background: #494B5B;
       border-color: #494B5B;
    }
    .heading-cvr .rgt-scnt .headingForm .btn .ico img{
        filter: brightness(0) invert(1);
    }

    .heading-cvr .rgt-scnt .headingForm .input-wrp.search{
        @include placeholder{
      opacity: 0.52;
      color: #FFFFFF;
    }
    }
}



.heading-cvr .rgt-scnt .headingForm .btn.btn-filter[aria-expanded="true"]{
    border: 1px solid #0075E9;
    background: #E1F0FF;
}

@media(max-width:1679px){

    .heading-cvr .rgt-scnt .headingForm .searchInput{
        min-width: 340px;
    }

}
@media(max-width:767px){
    .heading-cvr .rgt-scnt .headingForm .input-wrp.search{
        flex: auto;
    }
    .heading-cvr .rgt-scnt .headingForm .searchInput{
        min-width:100%;
    }
.heading-cvr .rgt-scnt .headingForm{
 
    flex-wrap: wrap;

}
}