@import 'partials/variables';
@import 'partials/mixins';

$lable-color: #6c83a2;
$input-lable-color: #35475e;
$back-white: #ffffff;
$btn-colors: #0075e9;

.modal .show {
    padding-left: 0 !important;
}
.modal {
    .modal-dialog {
        .modal-content {
            background: $back-white;
            box-shadow: 0px 3px 16px #00000014;
            border-radius: 24px;
            border: none;
            .modal-header {
                padding: 19px;
                border: none;
                .btn-close {
                    width: 26px;
                    height: 26px;
                    background-color: #e8f2ff !important;
                    padding: 0px;
                    border-radius: 50%;
                    outline: none;
                    box-shadow: none;
                    background: transparent
                        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230075E9'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
                        center/10px auto no-repeat;
                    opacity: 1;
                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
            .modal-header {
                padding: 19px 19px 10px;
                border: none;
            }
            .modal-body {
                padding: 20px 50px 0px;
                .modal-data-content {
                    display: flex;
                    flex-direction: column;
                    .heading {
                        h3 {
                            color: $primary-head-color;
                            margin-bottom: 0px;
                            font-size: 20px;
                            font-family: $poppins;
                            font-weight: 400;
                            line-height: 30px;
                        }
                    }
                    .modal-form-area {
                        margin-top: 25px;
                        .form-input {
                            margin-bottom: 50px;
                            .floating-label {
                                width: 100%;
                                label {
                                    color: $lable-color;
                                    font-size: 11px;
                                    font-family: $poppins;
                                    line-height: 17px;
                                    font-weight: 300;
                                    margin-bottom: 0px;
                                    background-color: $back-white;
                                    left: 20px;
                                    text-transform: capitalize;
                                }
                                .floating-input {
                                    width: 100%;
                                    background: $back-white;
                                    border: 1px solid #d3e3f8;
                                    border-radius: 12px;
                                    color: $input-lable-color;
                                    font-size: 13px;
                                    font-family: $poppins;
                                    line-height: 17px;
                                    font-weight: 300;
                                    padding: 15px 20px;
                                    &.select {
                                        appearance: none !important;
                                        -webkit-appearance: none;
                                        -moz-appearance: none;
                                        background: transparent;
                                        background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
                                        background-repeat: no-repeat;
                                        background-position-x: 98%;
                                        background-position-y: 10px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .modal-footer {
                padding: 0px 50px 50px;
                border: none;
                .btn-area {
                    display: flex;
                    margin: 0px;
                    &.multi-btns {
                        column-gap: 15px;
                        .btn {
                            display: inline-flex;
                            justify-content: center;
                            align-items: center;
                            height: 50px;
                            padding-left: 25px !important;
                            padding-right: 25px !important;
                            min-width: 160px;
                            text-align: center !important;
                            &:hover {
                                box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
                            }
                        }

                        .icon-sm {
                            margin-left: auto;
                            img {
                                width: 16px;
                            }
                        }
                    }
                    .btn {
                        font-size: 13px;
                        font-family: $poppins;
                        line-height: 20px;
                        padding: 15px 60px;
                        border-radius: 11px;
                        outline: none;
                        box-shadow: none;

                        &.btn-cancel {
                            background-color: #ffffff;
                            border: 1px solid $btn-colors;
                            color: $btn-colors;
                            // margin-right: 15px;
                        }
                        &.btn-save {
                            background-color: $btn-colors;
                            border: 1px solid $btn-colors;
                            color: #ffffff;

                            min-width: 150px;
                        }
                        &.share-btn {
                            background: #0299e9;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
    &.add-emp-modal {
        .modal-dialog {
            .modal-content {
            }
        }
        .modal-form-area {
            // margin-top: 15px !important;
        }
        .image-uploader {
            margin-bottom: 30px;
        }
    }

    &.view-emp-modal {
        .modal-dialog {
            .modal-content {
                .modal-header {
                    padding: 10px 19px;
                    border: none;
                }
                .modal-body {
                    padding: 0px 50px 0px;
                    .modal-data-content {
                        display: flex;
                        flex-direction: column;
                        .heading {
                            h3 {
                                color: $primary-head-color;
                                margin-bottom: 0px;
                                font-size: 20px;
                                font-family: $poppins;
                                font-weight: 400;
                                line-height: 30px;
                            }
                        }
                        .modal-form-view {
                            margin-top: 25px;
                            .form-value {
                                margin-bottom: 50px;
                                label {
                                    font-family: $poppins;
                                    font-size: 14px;
                                    line-height: 21px;
                                    font-weight: 300;
                                    margin-bottom: 8px;
                                    color: #0075e9;
                                }
                                h3 {
                                    font-family: $poppins;
                                    font-size: 14px;
                                    line-height: 21px;
                                    font-weight: 300;
                                    margin-bottom: 0px;
                                    color: #35475e;
                                }
                            }
                        }
                    }
                }
                .modal-footer {
                    padding: 0px 50px 50px;
                    .btn-area {
                        display: flex;
                        margin: 0px;
                        .btn {
                            background-color: #ebf3ff;
                            color: #0075e9;
                            font-size: 12px;
                            line-height: 18px;
                            font-family: $poppins;
                            margin-right: 15px;
                            font-weight: 500;
                            padding: 13px 20px;
                            border-radius: 9px;
                            display: flex;
                            align-items: center;
                            border: none;
                            outline: none;
                            box-shadow: none;
                            &:last-child {
                                margin-right: 0px;
                            }
                            img {
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
    &.success-modal {
        .modal-dialog {
            .modal-content {
                .modal-body {
                    padding: 0px;
                    .modal-data-content {
                        .success-modal-area {
                            padding: 15px 30px 65px;
                            display: flex;
                            justify-content: center;
                            width: 100%;
                            flex-wrap: wrap;
                            img {
                                max-width: 120px;
                                margin-bottom: 15px;
                            }
                            h3 {
                                font-family: $poppins;
                                font-size: 22px;
                                line-height: 33px;
                                font-weight: 400;
                                margin-bottom: 10px;
                                color: #35475e;
                                text-align: center;
                                width: 100%;
                            }
                            p {
                                font-family: $poppins;
                                font-size: 14px;
                                line-height: 21px;
                                font-weight: 400;
                                margin-bottom: 30px;
                                color: #6c83a2;
                                text-align: center;
                                width: 100%;
                            }
                            .btn-suces {
                                background: #0075e9;
                                padding: 15px 65px;
                                font-family: $poppins;
                                font-size: 16px;
                                line-height: 25px;
                                font-weight: 400;
                                text-transform: uppercase;
                                color: #ffffff;
                                outline: none;
                                box-shadow: none;
                                border: none;
                                border-radius: 11px;
                            }
                        }
                    }
                }
            }
        }
    }
    &.delete-modal {
        .modal-dialog {
            .modal-content {
                .modal-body {
                    padding: 0px;
                    .modal-data-content {
                        .delete-modal-area {
                            padding: 15px 30px 65px;
                            display: flex;
                            justify-content: center;
                            flex-wrap: wrap;
                            img {
                                max-width: 120px;
                                margin-bottom: 15px;
                            }
                            h3 {
                                font-family: $poppins;
                                font-size: 22px;
                                line-height: 33px;
                                font-weight: 400;
                                margin-bottom: 10px;
                                color: #35475e;
                                text-align: center;
                                width: 100%;
                            }
                            p {
                                font-family: $poppins;
                                font-size: 14px;
                                line-height: 21px;
                                font-weight: 400;
                                margin-bottom: 30px;
                                color: #6c83a2;
                                text-align: center;
                                width: 100%;
                            }
                            .btn-area {
                                display: flex;
                                margin: 0px;
                                justify-content: center;
                                .btn {
                                    font-size: 13px;
                                    font-family: $poppins;
                                    line-height: 20px;
                                    padding: 15px 60px;
                                    border-radius: 11px;
                                    outline: none;
                                    box-shadow: none;
                                    &.btn-cancel {
                                        background-color: #ffffff;
                                        border: 1px solid $btn-colors;
                                        color: $btn-colors;
                                        margin-right: 15px;
                                    }
                                    &.btn-save {
                                        background-color: $btn-colors;
                                        border: 1px solid $btn-colors;
                                        color: #ffffff;
                                        &:hover {
                                            opacity: 0.9;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.modal {
    // font-family: 'Poppins', sans-serif;

    &.proposal-download {
        .modal-dialog {
            .modal-content {
                .modal-header,
                .modal-body,
                .modal-footer {
                    padding-left: 38px;
                    padding-right: 38px;
                }
                .modal-header {
                    .modal-title {
                        color: #35475e;
                        font-size: 20px;
                        font-weight: 500;
                    }
                }
                .modal-body-inner {
                    padding: 12px;
                    border-radius: 12px;
                    border: 1px solid #d3e3f8;
                    background-color: #f8fbff;
                    max-height: calc(100vh - 228px);
                    overflow: hidden;
                    display: flex;
                    justify-content: center;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
                .modal-footer {
                    padding-top: 24px;
                    justify-content: center;
                }
            }
        }
    }

    &.proposal-dwd-conf {
        background-color: #1a2c4333;
        .modal-dialog {
            max-width: 587px;
            .modal-content {
                .modal-header,
                .modal-body,
                .modal-footer {
                    padding-left: 80px;
                    padding-right: 80px;
                }

                .modal-header {
                    padding-top: 64px;
                    position: relative;
                    .btn-close {
                        position: absolute;
                        z-index: 2;
                        top: 20px;
                        right: 20px;
                    }
                }

                .modal-body {
                    .download-as {
                        .dl-title {
                            .title {
                                color: #35475e;
                                font-size: 14px;
                                font-weight: 400;
                            }
                        }
                        .input-wrp {
                            position: relative;
                            .customInput {
                                position: absolute;
                                inset: 0;
                                opacity: 0;
                                cursor: pointer;
                            }

                            .label {
                                width: 100%;
                                transition: all ease-in-out 0.2s;
                                color: #35475e;
                                font-size: 13px;
                                border: 1px solid transparent;
                            }
                        }
                        .dl-format {
                            padding: 6px;
                            border: 1px solid #d3e3f8;
                            border-radius: 12px;
                            display: flex;
                            align-items: center;
                            gap: 5px;
                            margin-bottom: 20px;
                            .input-wrp {
                                flex: 1;
                                .label {
                                    text-align: center;
                                    border-radius: 12px;
                                    padding: 12px;
                                }
                                .customInput {
                                    &:checked {
                                        & ~ .label {
                                            background-color: #e8f2ff;
                                            border-color: #d3e3f8;
                                            color: #0075e9;
                                        }
                                    }
                                }
                            }
                        }

                        .dl-options {
                            display: flex;
                            margin-bottom: 20px;
                            gap: 24px;
                            transition: all ease-in-out 0.3s;
                            max-height: 60px;
                            .input-wrp {
                                flex: 1;
                                .label {
                                    border: 1px solid #d3e3f8;
                                    padding: 16px 24px;
                                    border-radius: 12px;
                                    display: flex;
                                    gap: 10px;
                                    .checks {
                                        flex: 0 0 16px;
                                        max-width: 16px;
                                        height: 16px;
                                        border-radius: 16px;
                                        border: 1px solid #0075e9;
                                        position: relative;
                                        &:before {
                                            position: absolute;
                                            content: '';
                                            inset: 1px;
                                            border-radius: 100%;
                                        }
                                    }
                                }

                                .customInput {
                                    &:checked {
                                        & ~ .label {
                                            background-color: #f2f6fc;
                                            .checks {
                                                &:before {
                                                    background-color: #0075e9;
                                                }
                                            }
                                        }
                                    }
                                } // custom input
                            } // input wrps
                        }
                    }
                }
                .modal-footer {
                    padding-top: 30px;
                }
            }
        }
    }
}

.d-noneX {
    max-height: 0 !important;
    overflow: hidden;
}

.addLink {
    font-family: 'Poppins', sans-serif;
    .btn-link {
        font-size: 12px;
        margin-bottom: -30px;
    }
}
.addPortfolioCheck {
    font-family: 'Poppins', sans-serif;
    color: #6c83a2;
    font-size: 13px;
    .custom-label {
        font-size: 11px;
        padding-bottom: 10px;
    }
    .form-check-input {
        width: 1.3em;
        height: 1.3em;
        margin-top: auto;
        margin-bottom: auto;
    }
    .form-check {
        min-height: 2rem;
        align-items: center;
        line-height: 1;
        display: flex;
    }
    label {
        padding-left: 5px;
    }
}
.modal-backdrop {
    & + .modal-backdrop {
        &.show {
            opacity: 0.01;
        }
    }
}

.modal.proposal-dwd-conf .modal-dialog .modal-content .modal-header .modal-title {
    font: 500 20px/30px Poppins;
    color: #35475e;
}
.modal .modal-dialog .modal-content .modal-body .modal-data-content .heading h3 {
    color: #35475e !important;
}
.modal.add-emp-modal .image-uploader {
    margin-top: -20px;
}

.uploaded-image-otr {
    display: flex;
    justify-content: center;
    .img-file,
    img {
        width: 120px;
        height: 120px;
        border-radius: 100%;
        border: 8px solid #cce6ff;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        position: relative;
        overflow: hidden;
    }
    img {
        object-fit: cover;
        overflow: auto;
        //  object-fit: contain;
        border: 0;
    }
}
.employee-add-sec .row {
    position: relative;
}
.dlt-otr {
    display: flex;
    justify-content: flex-end;
    width: 35px;
    margin-top: 10px;
    padding: 0;
    margin-left: 15px;
    flex-shrink: 0;

    @media (max-width: 767px) {
        // position: static;
        // width: 100%;
        // padding-right: calc(var(--bs-gutter-x) / 2) !important;
    }
    .btn-remove {
        width: 33px;
        height: 33px;
        background: #dcebff;
        padding: 0px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        border-radius: 50%;
    }
}
.custom-col-lg {
    // max-width: 46%;
    display: flex;
    &.col-md-12 {
        // padding: 0 !important;
    }
    .select-custom {
        width: 100%;
    }
    @media (max-width: 767px) {
        max-width: 100%;
        margin-bottom: 0px;
        // flex-wrap: wrap;
        .dlt-otr {
            margin-left: 5px;
            margin-top: 14px;
        }
    }
}

.custom-drop-down {
    .css-b62m3t-container {
        border: 1px solid #d3e3f8;
        height: 52px;
    }
    .css-13cymwt-control,
    .css-t3ipsp-corntol {
        height: 100%;
        outline: none !important;
    }
}

.portfolio-from-modal {
    .select-custom:after {
        display: none;
    }

    .floating-input.select.css-3iigni-container {
        .css-16xfy0z-control {
            &:after {
                content: '';
                width: 0;
                height: 0;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-top: 7px solid #35475e;
                position: absolute;
                right: 10px;
                .dark-mode & {
                    border-top: 7px solid #ffffff;
                }
            }
        }
    }
    .form-grp {
        margin-bottom: 40px;
        position: relative;
    }
    .modal-footer {
        padding-top: 0px !important;
        padding-bottom: 35px !important;
    }
    .textarea-col {
        height: 200px !important;
    }
}

.sm-add-btn-otr {
    .add-role-btn {
        height: 51px;
        width: 100%;
        justify-content: center;
    }
}

.portfolio-from-modal .modal-dialog {
    max-width: 1000px !important;
}

.modal-article {
    .title_lg {
        font: 600 20px/30px Poppins;
        color: #35475e;
        margin-bottom: 35px;
    }
    .para {
        p {
            color: #35475e;
            font: normal 14px/31px Poppins;
            white-space: normal;
            word-break: break-word;
        }
    }
    .inner__content {
        padding: 0 !important;
        margin-top: 20px;
        margin-bottom: 50px;
        display: flex;
        flex-wrap: wrap;
    }
}

.cstm-drop-border {
    margin-right: auto;
    .btn.btn-secondary.dropdown-toggle {
        border: 1px solid #d3e3f8 !important;
        font: normal normal normal 13px/20px Poppins !important;
        margin-right: 0 !important;
        min-width: 195px;
        padding: 15px 60px !important;
    }
    .dropdown-toggle::after {
        border-top: 8px solid;
        border-right: 7px solid transparent;
        border-bottom: 0;
        border-left: 7px solid transparent;
    }
    span {
        flex-shrink: 0;
        margin-right: 15px;
        .iconlft {
            margin-right: 10px;
            width: 15px;
        }
    }
}

.ftr_w100 {
    .btn-area {
        width: 100%;
    }
}

.cstm-drop-border {
    .dropdown-menu .dropdown-item {
        color: #e5e5e5 !important;
        font: 13px/18px Poppins;
    }
    .dropdown-menu {
        width: 100%;
    }
}

.tender-table,
.table-btn-grp {
    tr {
        th {
            color: #6c83a2;
            font: normal 12px/18px Poppins;
            vertical-align: middle;
        }
    }

    .sm-th {
        width: 50px;
    }
    .lg-th {
        width: 350px;
    }
    .estimation-th {
        width: 220px;
    }
    .slct-th {
        width: 150px;
    }
    .value-area {
        .css-1dyz3mf {
            border: 1px solid #d3e3f8;
            border-radius: 12px;
            height: 51px;
        }
        .css-1jqq78o-placeholder {
            font-size: 13px;
        }
        .css-13cymwt-control:hover {
            border: 1px solid transparent !important;
        }
    }

    .number-crcl {
        background: #0075e9;
        color: #fff;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        margin-left: 10px;
    }
    .slct_otr {
        display: flex;
        align-items: center;
    }
    td.action {
        width: 50px !important;
    }
}
.table-btn-grp.table-btn {
    display: flex;
    column-gap: 10px;
    .btn {
        flex-grow: 1;
    }
}

.checkbox_in_mdl {
    display: flex;
    column-gap: 20px;
    .form-check-input[type='checkbox'] {
        border-radius: 50%;
    }
    .form-check {
        display: flex;
    }
    .form-check-label {
        color: #35475e;
        font: normal 13px/20px Poppins;
        margin-left: 10px;
    }
    .form-check-input {
        border-color: #0075e9;
    }
}
.tender-table tr th {
    height: 43px;
}

.tender-table {
}

.mlty-drop {
    .dropdown-toggle::after {
        display: none;
    }
    .dropdown-menu {
        border: 0;
        box-shadow: 0px 3px 16px #0075e929;
        li {
            padding: 0 7px;
        }
        .dropdown-item {
            background-color: transparent;
            font: normal 12px/18px Poppins;
            display: inline-flex;
            align-items: center;
            min-width: 200px;
            border-radius: 6px;
            height: 40px;
            .check {
                flex-shrink: 0;
                margin-left: auto;
                padding-left: 10px;
            }
            &:hover,
            &.active {
                background-color: #f2f8ff !important;
                color: #0075e9 !important;
            }
        }
    }
}

.test-mod-bg {
    .mb-4 {
        margin-bottom: 30px !important;
    }
    .error-msg {
        bottom: -19px;
        font-size: 12px;
    }
}

.stk-nme {
    .select-custom-react .css-hlgwow {
        width: 100px;
    }
}

.copy-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 15px !important;
    padding-right: 15px !important;
    &:hover,
    &:focus {
        box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25) !important;
    }
    .img {
        flex-shrink: 0;
        margin-left: 10px;
        img {
            width: 15px;
        }
    }
}
.copy-down {
    justify-content: center;
    .btn-area {
        justify-content: center;
        align-items: center;
        column-gap: 10px;
        display: flex;
        width: 100%;
        padding-left: 30%;
        .btn {
            flex-grow: 1;
            padding-left: 15px !important;
            padding-right: 15px !important;
            &:hover {
                box-shadow: rgba(13, 110, 253, 0.25) 0px 0px 0px 0.25rem !important;
            }
        }
    }
}

.select-custom .css-10wo9uf-option {
    font-size: 12px !important;
    line-height: 18px !important;
    padding: 3px 12px !important;
}

.img-settings {
    .select-custom .css-10wo9uf-option {
        padding: 7px 12px !important;
    }
}

.tender-table {
    // .css-1nmdiq5-menu {
    //     > div {
    //         max-height: 220px !important;
    //     }
    // }
}
.frm-col {
    padding-left: 15px;
    padding-right: 15px;
}

.css-1xc3v61-indicatorContainer {
    display: none !important;
}
.select__control.css-13cymwt-control:hover {
    border: 1px solid #d3e3f8 !important;
}

.min-height {
    min-height: 50vh;
}

.cmb-4 {
    margin-bottom: 35px !important;
}
.sub-sec {
    .css-12a83d4-MultiValueRemove svg {
        fill: #333 !important;
    }
}

.css-1p3m7a8-multiValue {
    max-width: 130px;
}
.add-form {
    textarea {
        resize: none;
    }
    .floating-input {
        height: 50px;
    }
}
.modal.proposal-download {
    .modal-body-view {
        .container-view {
            background: #fbfcfe;
            border-left: 1px solid #d3e3f8;
            border-right: 1px solid #d3e3f8;
            max-width: 850px;

            &:first-child {
                border-top: 1px solid #d3e3f8;
                border-top-left-radius: 12px;
                border-top-right-radius: 12px;
            }
            &:last-child {
                border-bottom: 1px solid #d3e3f8;
                margin-bottom: 25px;
                border-bottom-left-radius: 12px;
                border-bottom-right-radius: 12px;
            }
        }
    }
}
.modal .modal-dialog .modal-content .modal-footer .btn-area {
    column-gap: 15px;
}
.share-modal {
    background-color: rgba(26, 44, 67, 0.2);
    .modal-dialog {
        max-width: 900px !important;
    }

    .mt-4 {
        margin-bottom: 15px !important;
    }
}

.tags-otr {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 15px;
    row-gap: 15px;
    width: 100%;

    .tag {
        background: #f3f8ff;
        border: 1px solid #b9d0ee;
        border-radius: 16px;
        padding: 5px 15px;
        padding-right: 25px;
        min-height: 30px;
        position: relative;

        span {
            font: 300 12px/17px Poppins;
            letter-spacing: 0px;
            color: #35475e;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 180px;
            white-space: nowrap;
        }
        .close-icon {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            border: 0.5px solid #62a8ee;
            flex-shrink: 0;
            background-color: transparent;
            padding: 0;
            margin-left: 5px;
            background: url(../img/close.svg);
            background-size: 12px;
            background-position: center center;
            position: absolute;
            right: 0px;
            transform: translate(-50%, -50%);
            top: 50%;
        }
    }
}

.hed-elements {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    column-gap: 13px;
    padding-top: 5px;
    .btsgrp {
        display: flex;
        align-items: center;
        column-gap: 13px;
        .round-btn {
            background-color: #f3f8ff;
            width: 45px;
            height: 45px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 0;
            padding: 0;
            transition: 0.2s;
            &:hover {
                box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.25);
            }
            img {
                width: 15px;
            }
        }
    }

    .circle-sct {
        margin-left: 20px;
        display: flex;
        align-items: center;
        .share-list-btn {
            background-color: #0075e9;
            color: #fff;
            font: 500 13px/18px Poppins;
            position: relative;
            // z-index: 1 !important;
        }
        .dropdown-toggle::after {
            display: none;
        }
    }
    .circle-css {
        background-color: transparent;
        margin-left: -5px;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        padding: 0;
        background-color: #fff;
        overflow: hidden;
        position: relative;
        z-index: 1;
        transition: 0.2s;
        &:hover {
            position: relative;
            z-index: 2 !important;
            box-shadow: 0 0 0 0.15rem #0c79cf;
        }
        img {
            object-fit: contain;
        }
    }
}

.cover-elements {
    width: 100%;
    margin-top: 25px;
    .select-custom.select-custom-react .css-hlgwow {
        min-height: 51px !important;
        height: auto !important;
    }
    .css-1dyz3mf,
    .css-hlgwow {
        border: 1px solid #d3e3f8 !important;
        padding-left: 10px !important;
        row-gap: 5px;
        border-radius: 12px;
        padding: 8px 10px;
    }
    .custom-tags-otr {
        margin-top: 0 !important;
    }
    .tags-otr .tag {
        border-radius: 20px !important;
    }
    .tags-otr {
        margin-top: 0 !important;
    }
    .chk-bx-cvr {
        width: 100%;
        margin-top: 35px;
        height: calc(100vh - 450px);
        overflow-y: auto;
        overflow-x: hidden;
        .ck-label {
            color: #35475e;
            font: 500 15px/20px Poppins;
            margin-bottom: 25px;
        }
        .custom-checkbox {
            font: 500 13px/25px Poppins;
        }
        .custom-checkbox .checkmark {
            background: #f8fbff;
        }
        .custom-checkbox input:checked ~ .checkmark {
            background-color: #0075e9;
        }
        .col {
            flex: 0 0 auto;
            width: 25%;
        }
    }
}

.modal_md .modal-dialog {
    max-width: 1078px !important;
}

.top-of-bg {
    background-color: rgba(26, 44, 67, 0.2);
}

.dropfiles-forms {
    .select-custom-react .css-13cymwt-control,
    .select-custom-react .css-t3ipsp-control {
        height: 100% !important;
    }
    .select-custom-react.select-custom-react.select-custom-react .css-1nmdiq5-menu {
        * {
            .dark-mode & {
                color: #ffffff !important;
            }
        }
    }
    .drag-drop-wrap label {
        bottom: 0;
    }
    .label-img {
        color: #6c83a2;
        font: 300 13px/17px Poppins;
        margin-bottom: 10px;
        height: 10px;
    }
    .modal-header {
        padding-top: 30px !important;
        padding-bottom: 30px !important;
    }
    .modal-body {
        padding: 0 100px !important;
    }

    .frm {
        margin-bottom: 35px;
        .label {
            color: #3089fa;
            font: 500 15px/23px Poppins;
            margin-bottom: 25px;
        }
    }
    .drag-drop-wrap {
        width: 100%;
        height: 160px;
        margin-top: 0;
        position: relative;
        .image-preview {
            position: relative;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 0.2s;
            .close_btn {
                border: 1px dashed #ddd;
                background-color: #fff;
                width: 22px;
                height: 22px;
                padding: 0;
                border-radius: 50%;
                position: absolute;
                right: -20px;
                top: -20px;
                z-index: 10;
                transition: 0.2s;
                &.z-9 {
                    z-index: 9;
                }
                img {
                }
                &:hover {
                    box-shadow: 0 0 0 0.1rem #c1d4f8;
                }
            }
            &.logo {
                img {
                    max-height: 150px;
                }
            }
            &.cover {
                background-size: cover;
                width: 100%;
                height: 100%;
                background-position: center center;
                img {
                    max-width: 100%;
                    width: 100%;
                }
            }
        }
        .upload-content small {
            margin-bottom: 5px !important;
        }
        .drag-drop {
            margin-bottom: 0;
        }
        .upload-icon {
            margin-bottom: 8px !important;
        }
        .upload-btn {
            height: 30px !important;
            min-width: 11px !important;
            border-radius: 8px;
        }
        .drag-drop .upload-icon {
            img {
                width: 40px;
            }
        }
    }

    .textarea-scroll {
        height: 160px !important;
        resize: none;
    }
}

.forms-elements-modal {
    .form-box {
        background-color: inherit !important;
        border: inherit !important;
        padding: 0 !important;
    }
    .modal-body {
        padding: 0 30px 10px !important;
    }
}

.fixed-footer {
    &.modal-footer {
        padding: 17px 43px 0 !important;
        .btn {
            width: 135px;
            height: 40px;
        }
    }
}
.inputvbtn {
    display: flex;
    align-items: center;
    .css-qbdosj-Input,
    .css-166bipr-Input {
        .dark-mode & {
            * {
                color: #ffffff !important;
            }
            color: #ffffff !important;
        }
    }
    .css-13cymwt-control,
    .css-t3ipsp-control {
        .dark-mode & {
            * {
                color: #ffffff !important;
            }
        }
    }
    .add-button {
        border: 0;
        background-color: transparent;
        &:hover {
            opacity: 0.7;
        }
    }
    .select-custom {
        margin-left: 0;
        flex-grow: 1;
    }
}

@media (max-width: 1679px) {
    .modal.proposal-dwd-conf .modal-dialog .modal-content .modal-header {
        padding-bottom: 5px;
    }
    .cover-elements .chk-bx-cvr {
        height: calc(100vh - 350px);
    }
    .modal .modal-dialog .modal-content .modal-footer .btn-area.multi-btns .btn {
        height: 45px;
        min-width: 150px;
    }
    .css-1p3m7a8-multiValue {
        max-width: 110px;
    }
    .table-btn-grp .number-crcl,
    .tender-table .number-crcl {
        width: 25px;
        height: 25px;
        font-size: 12px;
    }
    .custom-checkbox .checkmark {
        height: 20px;
        width: 20px;
    }
    .custom-checkbox .checkmark::after {
        border-width: 0px 2px 2px 0px;
        left: 8px;
        top: 3px;
    }
    .custom-checkbox {
        padding-left: 30px;
    }
    .test-mod-bg .error-msg {
        font-size: 11px !important;
    }

    .modal-article .para p {
        font: 13px / 27px Poppins;
    }
    .modal-article .title_lg {
        font: 600 18px/26px Poppins;
        margin-bottom: 15px;
    }
    .dlt-otr {
        margin-top: 8px;
        padding: 0;
        margin-left: 10px;
        .btn-remove {
            width: 27px;
            height: 27px;
            img {
                width: 10px;
            }
        }
    }
    .modal {
        .modal-dialog {
            .modal-content {
                .modal-body {
                    padding: 0px 50px 0px;
                    .modal-data-content {
                        .modal-form-area {
                            margin-top: 30px;
                            .form-input {
                                margin-bottom: 30px;
                            }
                        }
                    }
                }
                .modal-footer {
                    padding: 0px 50px 30px;
                    .btn-area {
                        .btn {
                            padding: 10px 40px;
                        }
                    }
                }
            }
        }
        &.view-emp-modal {
            .modal-dialog {
                .modal-content {
                    .modal-body {
                        padding: 0px 50px 0px;
                        .modal-data-content {
                            .modal-form-view {
                                margin-top: 30px;
                                .form-value {
                                    margin-bottom: 30px;
                                }
                            }
                        }
                    }
                    .modal-footer {
                        padding: 0px 50px 30px;
                    }
                }
            }
        }
        &.success-modal {
            .modal-dialog {
                .modal-content {
                    .modal-body {
                        .modal-data-content {
                            .success-modal-area {
                                padding: 0px 30px 30px;
                                img {
                                    max-width: 100px;
                                    margin-bottom: 5px;
                                }
                                h3 {
                                    font-size: 20px;
                                    line-height: 25px;
                                }
                                p {
                                    margin-bottom: 20px;
                                }
                                .btn-suces {
                                    padding: 10px 40px;
                                }
                            }
                        }
                    }
                }
            }
        }
        &.delete-modal {
            .modal-dialog {
                .modal-content {
                    .modal-body {
                        .modal-data-content {
                            .delete-modal-area {
                                padding: 0px 30px 30px;
                                img {
                                    max-width: 100px;
                                    margin-bottom: 5px;
                                }
                                h3 {
                                    font-size: 20px;
                                    line-height: 25px;
                                }
                                p {
                                    margin-bottom: 20px;
                                }
                                .btn-area {
                                    .btn {
                                        padding: 10px 40px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: 1199px) {
    .copy-down .btn-area {
        padding-left: 0;
    }
}
@media (min-width: 992px) {
    .modal-dialog {
        max-width: 991px;
    }
    .success-modal {
        .modal-dialog {
            max-width: 600px;
        }
    }
    .delete-modal {
        .modal-dialog {
            max-width: 600px;
        }
    }
}
@media (max-width: 991px) {
    .dropfiles-forms {
        .modal-footer {
            padding: 30px !important;
        }
        .frm {
            .form-floating {
                margin-bottom: 30px;
            }
        }
        .btn-area .btn {
            width: 100% !important;
        }
    }

    .dropfiles-forms .drag-drop-wrap .upload-btn {
        width: 125px;
    }
    .dropfiles-forms .frm {
        margin-bottom: 30px;
    }
    .dropfiles-forms .modal-body {
        padding: 0 20px !important;
    }
    .modal-article .title_lg {
        font: 600 15px/22px Poppins;
    }
    .modal-article .para p {
        font: 13px/23px Poppins;
    }
    .details-wrap .inner__content .portfolio-col {
        margin-right: 10px;
        margin-bottom: 15px;
    }
    .modal {
        .modal-dialog {
            max-width: 90%;
            .modal-content {
                .modal-body {
                    padding: 0px 40px 0px;
                    .modal-data-content {
                        .modal-form-area {
                            margin-top: 40px;
                        }
                    }
                }
                .modal-footer {
                    padding: 0px 40px 50px;
                    .btn-area {
                        .btn {
                            padding: 10px 30px;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
        &.add-emp-modal {
            .modal-dialog {
            }
        }
        &.view-emp-modal {
            .modal-dialog {
                .modal-content {
                    .modal-body {
                        padding: 0px 40px 0px;
                        .modal-data-content {
                            .modal-form-view {
                                margin-top: 40px;
                                .form-value {
                                    margin-bottom: 30px;
                                }
                            }
                        }
                    }
                    .modal-footer {
                        padding: 0px 40px 50px;
                    }
                }
            }
        }
        &.success-modal {
            .modal-dialog {
                max-width: 600px;
                .modal-content {
                    .modal-body {
                        .modal-data-content {
                            .success-modal-area {
                                padding: 15px 30px 30px;
                                h3 {
                                    font-size: 20px;
                                }
                                .btn-suces {
                                    padding: 10px 30px;
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.delete-modal {
            .modal-dialog {
                max-width: 600px;
                .modal-content {
                    .modal-body {
                        .modal-data-content {
                            .delete-modal-area {
                                padding: 15px 30px 30px;
                                h3 {
                                    font-size: 20px;
                                }
                                .btn-area {
                                    .btn {
                                        padding: 10px 30px;
                                        font-size: 14px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .uploaed-image-otrd {
        margin-top: 20px;
    }
    .modal {
        .modal-dialog {
            .modal-content {
                .modal-body {
                    .modal-data-content {
                        .modal-form-area {
                            margin-top: 40px;
                            .form-input {
                                margin-bottom: 30px;
                            }
                        }
                        .btn {
                            font-size: 12px !important;
                        }
                    }
                }
            }
        }
        &.add-emp-modal {
            .modal-dialog {
                .modal-content {
                }
            }
        }
    }
    .table-btn-grp.table-btn {
        flex-wrap: wrap;
    }

    .table-btn-grp.table-btn .btn {
        margin-bottom: 10px;
    }
}

@media (max-width: 575px) {
    .table-btn-grp.table-btn {
        flex-direction: column;
    }

    .cstm-drop-border .btn.btn-secondary.dropdown-toggle {
        min-width: 100%;
        padding: 5px 15px !important;
    }
    .cstm-drop-border span {
        margin: auto;
    }
    .cstm-drop-border span .iconlft {
        margin-right: 0;
    }
    .cstm-drop-border {
        margin-right: 0;
    }
    .ftr_w100 .btn-area {
        flex-direction: column;
        gap: 10px;
        .btn {
            width: inherit !important;
            flex-grow: 1;
        }
    }

    .portfolio-from-modal {
        .modal-content .modal-footer {
            padding: 0px 30px 30px !important;
        }
    }

    .uploaded-image-otr .img-file,
    .uploaded-image-otr img {
        width: 100px;
        height: 100px;
        border-width: 5px;
    }
    .modal {
        .modal-dialog {
            max-width: 100%;
            .modal-content {
                .modal-body {
                    padding: 0px 20px 0px;
                }
                .modal-footer {
                    padding: 0px 20px 30px;
                }
            }
        }
        &.add-emp-modal {
            .modal-dialog {
            }
        }
        &.view-emp-modal {
            .modal-dialog {
                .modal-content {
                    .modal-body {
                        padding: 0px 30px 0px;
                    }
                    .modal-footer {
                        padding: 0px 30px 30px;
                        .btn-area {
                            flex-wrap: wrap;
                            justify-content: end;
                            .btn {
                                margin-bottom: 10px;
                                margin-right: 0px;
                                margin-left: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.small-modal {
    .modal-footer {
    }
    .drop-down-btn {
    }

    .textarea-scroll.textarea-col {
        height: 113px;
        resize: none;
    }
}

.add-emp-modal {
    .modal-body {
        padding-top: 0 !important;
    }
}
.modal-add-proposal {
    .modal-header {
        padding-bottom: 0 !important;
    }
}

.floating-form-block .form-floating label {
    width: auto !important;
    &.z-label {
        top: -6px !important;
        z-index: 99;
    }
}

.cover-elements .tags-otr .tag img {
    border-radius: 50%;
}
.tags-otr .tag {
    padding-left: 8px !important;
}

.cls_btn:before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    left: -11px;
    background: #f8fbff;
}

// Dark Mode
.dark-mode {
    .cls_btn {
        background: #1e1e2c;
        border: 1px solid #606272;
    }
    .cls_btn:before {
        background: #1e1e2c;
    }
    .cover-elements .css-1dyz3mf,
    .cover-elements .css-hlgwow {
        border: 1px solid #494b5b !important;
    }
    .cover-elements .chk-bx-cvr .custom-checkbox .checkmark {
        background: #babdc1;
    }
    .cover-elements .chk-bx-cvr .ck-label {
        color: #a2aab2;
    }
    .tags-otr .tag {
        background: #2e3345;
        border: 1px solid #494b5b;
        span {
            color: #fff;
        }
    }
    .tags-otr .tag .close-icon {
        // -webkit-filter: brightness(0) invert(1);
        // filter: brightness(0) invert(1);
    }
    .modal .modal-dialog .modal-content .modal-footer .btn-area .btn.btn-cancel {
        color: #fff;
        img {
            -webkit-filter: brightness(0) invert(1);
            filter: brightness(0) invert(1);
        }
    }
    .modal.proposal-download .modal-body-view .container-view {
        border-color: #2e3345;
        background: #2a2c3c;
    }
    .css-wsp0cs-MultiValueGeneric {
        color: #fff;
    }

    .withbtn-otr .btn__add,
    .inputvbtn .add-button {
        opacity: 0.7;
        &:hover {
            opacity: 0.8;
        }
    }
    .check-box-from {
        background: #242635;
        // border: 1px solid #494b5b;
    }
    .custom-checkbox {
        color: #a2aab2;
    }
    .custom-checkbox .checkmark {
        background-color: #24263500;
    }
    .sub-sec .css-12a83d4-MultiValueRemove svg {
        fill: #fff !important;
    }
    .custom-checkbox:hover input ~ .checkmark {
        background: #393a46;
    }
    .custom-checkbox input:checked ~ .checkmark {
        background-color: #24263500;
    }
    .subnav-link.cursor-pointer {
        background-color: #3c3e4e !important;
    }
    .submenu-expand li .submenu-inner li {
        border-left: solid 1px #fff;
    }
    .submenu-expand li .submenu-inner li .subnav-link-inner {
        color: #fff;
    }
    .submenu-expand li.inner-nav-expand.show {
        background: #1e1e2c;
    }
    .test-mod-bg {
        background: #242636;
        border: 1px solid #494b5b;
        .css-13cymwt-control {
            background-color: rgb(36 38 54) !important;
        }
        .select__control.css-13cymwt-control {
            border: 1px solid #494b5b !important;
        }
        .file-format {
            color: #f85050 !important;
        }
    }
    .tender-table tr th,
    .checkbox_in_mdl .form-check-label {
        color: #fff;
    }
    .checkbox_in_mdl .form-check-input {
        // background: #494b5b;
        // border-color: #616161;
    }
    .modal .modal-dialog .modal-content {
        border: 1px solid #2e3345;
    }
    .modal-article {
        .title_lg {
            color: #ffffff;
        }
        .details-wrap {
            background-color: transparent;
        }
    }

    .modal-article .para p {
        color: #a2aab2;
    }
    .copy-input .copy_btn {
        background-color: #494b5b;
        color: #a2aab2;
        border-color: #a2aab2;
        img {
            -webkit-filter: brightness(0) invert(1);
            filter: brightness(0) invert(1);
        }
    }
    .cstm-drop-border span .iconlft {
        -webkit-filter: brightness(0) invert(1);
        filter: brightness(0) invert(1);
    }
    .cstm-drop-border .btn.btn-secondary.dropdown-toggle {
        border: 1px solid #a2aab2 !important;
        color: #fff !important;
    }
    .cstm-drop-border {
        .dropdown-menu {
            background-color: #2b2d3e !important;

            .dropdown-item {
                color: #e5e5e5 !important;

                &:hover {
                    background-color: #1e1e2c;
                }
            }
        }
    }
    .custom-drop-down {
        .select-custom:after {
            border-top-color: #dcebff;
        }
        .css-b62m3t-container {
            border: 1px solid #494b5b;
        }
    }

    .modal {
        .modal-dialog {
            .modal-content {
                background: $dark-bg;
                .modal-header {
                    .btn-close {
                        opacity: 0.7;
                        background-color: #313243 !important;
                        // background-size: 13px;
                        // filter: grayscale(100%);
                        background-image: url(../img/close-modal.svg);
                    }
                }
                .modal-body {
                    .modal-data-content {
                        .heading {
                            h3 {
                                color: #ffffff;
                            }
                        }
                        .modal-form-area {
                            .form-input {
                                .floating-label {
                                    label {
                                        background: $dark-bg;
                                        color: #fff;
                                    }
                                    .floating-input {
                                        background: $dark-bg;
                                        border: 1px solid $dark-border;
                                        color: #ffffff;
                                    }
                                }
                            }
                        }
                    }
                }
                .modal-footer {
                    .btn-area {
                        .btn.btn-cancel {
                            background: transparent;
                            border: 1px solid #0075e9;
                        }
                    }
                }
            }
        }
        &.view-emp-modal {
            .modal-dialog {
                .modal-content {
                    .modal-body {
                        .modal-data-content {
                            .modal-form-view {
                                .form-value {
                                    label {
                                        color: #a2aab2;
                                    }
                                    h3 {
                                        color: #ffffff;
                                    }
                                }
                            }
                        }
                    }
                    .modal-footer {
                        .btn-area {
                            .btn {
                                background-color: $dark-bg;
                                color: #ffffff;
                                border: 1px solid $dark-border;
                                img {
                                    fill-opacity: 1;
                                    filter: brightness(0) invert(1);
                                }
                            }
                        }
                    }
                }
            }
        }
        &.success-modal {
            .modal-dialog {
                .modal-content {
                    .modal-body {
                        .modal-data-content {
                            .success-modal-area {
                                h3 {
                                    color: #ffffff;
                                }
                                p {
                                    color: #a2aab2;
                                }
                            }
                        }
                    }
                }
            }
        }
        &.delete-modal {
            .modal-dialog {
                .modal-content {
                    .modal-body {
                        .modal-data-content {
                            .delete-modal-area {
                                h3 {
                                    color: #ffffff;
                                }
                                p {
                                    color: #a2aab2;
                                }
                                .btn-area {
                                    .btn-cancel {
                                        background: transparent;
                                        border: 1px solid #0075e9;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .form-input .floating-label .floating-input.select {
        background-image: url(../img/arrow-white.svg);
        background-repeat: no-repeat;
        background-position-x: 98%;
        background-position-y: 10px;
    }
    .modal.proposal-dwd-conf .modal-dialog .modal-content .modal-header .modal-title {
        color: #fff;
    }
    .modal.proposal-dwd-conf .modal-dialog .modal-content .modal-body .download-as .dl-options .input-wrp .label {
        border-color: $dark-border;
        .txt {
            color: #fff;
        }
    }
    .modal.proposal-dwd-conf
        .modal-dialog
        .modal-content
        .modal-body
        .download-as
        .dl-options
        .input-wrp
        .customInput:checked
        ~ .label {
        background: #333546;
    }
    .modal.proposal-dwd-conf
        .modal-dialog
        .modal-content
        .modal-body
        .download-as
        .dl-options
        .input-wrp
        .label
        .checks {
        border-color: #fff;
    }
    .modal.proposal-dwd-conf
        .modal-dialog
        .modal-content
        .modal-body
        .download-as
        .dl-options
        .input-wrp
        .customInput:checked
        ~ .label
        .checks:before {
        background-color: #fff;
    }
    .modal.proposal-download .modal-dialog .modal-content .modal-header .modal-title {
        color: #fff;
    }
    .modal.proposal-download .modal-dialog .modal-content .modal-body-inner {
        background-color: #1e1e2c;
        border: 0;
    }
    .modal.proposal-dwd-conf .modal-dialog .modal-content .modal-body .download-as .dl-title .title {
        color: #ffffff;
    }
    .modal.proposal-dwd-conf .modal-dialog .modal-content .modal-body .download-as .dl-format {
        border-color: $dark-border;
    }
    .modal.proposal-dwd-conf
        .modal-dialog
        .modal-content
        .modal-body
        .download-as
        .dl-format
        .input-wrp
        .customInput:checked
        ~ .label {
        background-color: $dark-bg-2;
        border-color: $dark-bg-2;
        color: #fff;
    }
    .modal.proposal-dwd-conf .modal-dialog .modal-content .modal-body .download-as .input-wrp .label {
        color: $light-clr;
    }
    .uploade-modal {
        .floating-form-block .form-floating .form-control {
            border-color: $dark-border;
            background-color: $dark-bg;
            color: #fff;
        }
        .floating-form-block .form-floating label {
            color: #fff;
        }
        .form-floating > .form-control:focus ~ label,
        .form-floating > .form-control:not(:placeholder-shown) ~ label,
        .form-floating > .form-select ~ label {
            color: #fff;
            background-color: $dark-bg;
        }
    }
    .modal .modal-dialog .modal-content .modal-body .modal-data-content .heading h3 {
        color: #fff !important;
    }

    .add-role-btn-block .add-role-btn {
        background-color: transparent !important;
        border: 1px dashed #a2aab2;
        color: #e5e5e5;
        img {
            -webkit-filter: brightness(0) invert(1);
            filter: brightness(0) invert(1);
        }
    }
}

.uploade-modal.small-modal .modal-dialog {
    max-width: 738px;
}

.uploade-modal {
}

@media (max-width: 767px) {
    .step-app > .step-content {
        .error-msg {
            bottom: -20px !important;
        }
    }
    .portfolio-from-modal .form-grp {
        .form-floating label {
            font: 13px/27px 'Poppins', sans-serif !important;
        }
        .form-control {
            margin-bottom: 0 !important;
        }
    }
    .uploade-modal {
        .modal-content .modal-header {
            padding-left: 25px !important;
            padding-right: 25px !important;
        }
    }
    .modal.add-emp-modal .image-uploader {
        margin-top: 5px;
        margin-bottom: 20px;
    }
    .modal.add-emp-modal .modal-form-area {
        margin-top: 10px !important;
    }
    .uploade-modal.small-modal .modal-body {
        padding-left: 25px !important;
        padding-right: 25px !important;
    }
}
.custom-cl-feild .fa-calendar,
.fa-caret-down {
    font-family: var(--fa-style-family, 'Font Awesome 6 Free') !important;
    font-weight: var(--fa-style, 900);
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: var(--fa-display, inline-block);
    font-style: normal;
    font-variant: normal;
    line-height: 1;
    text-rendering: auto;
}

.modal .modal-dialog .modal-content .modal-body .modal-data-content {
    padding: 0 20px;
}

.modal.proposal-dwd-conf .modal-dialog .modal-content .modal-footer {
    padding-top: 20px !important;
}

.proposal-add-modal {
    .modal-body {
        overflow-y: hidden !important;
    }
    .modal-content {
        height: auto;
    }
    .step-footer.btn_grp {
        padding: 20px 0;
        // position: sticky;
        bottom: 0;
        margin-bottom: 0 !important;
        @media (max-width: 1679px) {
            padding: 10px 0;
        }
    }
    .step-tab-panel {
        overflow: auto;
        min-height: 325px;
        max-height: calc(100vh - 340px);
        overflow-x: hidden;
        padding-right: 2px;
        padding-left: 10px;
        @media (max-width: 1679px) {
        }
    }
    .modal-body {
        // overflow-y: hidden !important;
    }
    .step-app > .step-content {
        // overflow-x: hidden;
        // overflow-y: auto;
        // height: calc(60vh - 100px);
    }
    .step-app > .step-footer {
        margin-top: 25px;
    }
}
.dropdown-noborder {
    .form-control.css-b62m3t-container {
        padding: 0 !important;
        border: 0 !important;
    }
}
.cmb-1 {
    margin-bottom: 30px !important;
}
.select__custom.border_0 {
    div.css-13cymwt-control,
    div.css-t3ipsp-control {
        border: 0 !important;
        min-height: 51px !important;
        @media (max-width: 1679px) {
            min-height: 46px !important;
        }
    }
}
.cover-elements .chk-bx-cvr .ck-label {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: sticky;
    top: 0;
    background: #fff;
    padding-bottom: 15px;
    z-index: 0;
}
.tab_btns_otr {
    margin-left: auto;
    .tb-btn {
        border: 1px solid #d3e3f8;
        border-radius: 11px;
        background-color: #fff;
        min-height: 40px;
        min-width: 162px;
        padding: 5px 20px;
        font: 500 14px/21px Poppins;
        position: relative;
        &.active {
            background: #edf5ff;
            z-index: 1;
        }
        &.fst {
            left: 15px;
        }
    }
}

.svg-arrow-hide {
    .select__indicator {
        svg {
            display: none !important;
        }
    }
}
.file-details .format-icon {
    width: 40px;
    height: 40px;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
@media (max-width: 1679px) {
    .uploade-otr .floating-form-block {
        margin-bottom: 15px !important;
    }
    .proposal-add-modal .step-tab-panel {
        height: calc(100vh - 310px);
    }
}
.contact-modal {
    .modal-footer {
        padding-left: 65px !important;
        padding-right: 65px !important;
    }
}
@media (max-width: 767px) {
    .fade.modal.contact-modal {
        .col-ftr .modal-footer {
            padding-left: 0 !important;
            padding-right: 10px !important;
            padding-top: 0 !important;
        }
    }
    .uploade-otr .floating-form-block {
        margin-bottom: 0 !important;
    }
}

@media (max-width: 479px) {
    .btn-area {
        width: 100%;
        display: flex;
        flex-direction: column;
        .btn {
            flex-grow: 1;
            margin-bottom: 10px;
        }
    }
}

.modal-header .btn-close {
    z-index: 99;
}

.border-red {
    -webkit-box-shadow: inset 0px 0px 0px 1px rgba(245, 106, 106, 1);
    -moz-box-shadow: inset 0px 0px 0px 1px rgba(245, 106, 106, 1);
    box-shadow: inset 0px 0px 0px 1px rgba(245, 106, 106, 1);
}

.dropdown-menu.custom-drop {
    overflow: auto;
    max-height: 270px;
}
.table-btn-grp .number-crcl,
.tender-table .number-crcl {
    position: relative;
    right: 4px;
}
.tab_btns_otr .tb-btn.lst {
    position: static !important;
}
.form-grp.inputvbtn .css-13cymwt-control,
.form-grp.inputvbtn .css-t3ipsp-control {
    background-color: transparent;
}

.add-emp-modal {
    .floating-input.select {
        background-image: none !important;
    }
}

.tags-otr .tag {
    display: inline-flex;
    align-items: center;
    .custom-tag {
        width: 18px !important;
    }
}
.dark-mode {
    .sub_item_list_otr .sub-accordion-button {
        background-color: #2d3143cf !important;
        border: 1px solid #333546 !important;
    }
}

.filter-cover .filter-btns {
    align-items: center;
}

.download_as {
    // max-width: 70%;
    margin-bottom: 5px;
    .dw-btn {
        border: 1px solid #d3e3f8;
        padding: 16px 24px;
        border-radius: 12px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        gap: 10px;
        background-color: transparent;
        width: 100%;
        color: #35475e;
        font-size: 13px;
        &:hover {
            background-color: #d3e3f830;
        }
        .iconinside {
            margin-right: 5px;
        }
    }

    .dl-title {
        .title {
            color: #35475e;
            font-size: 14px;
            font-weight: 400;
        }
    }
    .input-wrp {
        position: relative;
        display: flex;
        .customInput {
            position: absolute;
            inset: 0;
            opacity: 0;
            cursor: pointer;
        }

        .label {
            width: 100%;
            transition: all ease-in-out 0.2s;
            color: #35475e;
            font-size: 13px;
            border: 1px solid transparent;
        }
    }
    .dl-format {
        padding: 6px;
        border: 1px solid #d3e3f8;
        border-radius: 12px;
        display: flex;
        align-items: center;
        gap: 5px;
        margin-bottom: 20px;
        .input-wrp {
            flex: 1;
            .label {
                text-align: center;
                border-radius: 12px;
                padding: 12px;
            }
            .customInput {
                &:checked {
                    & ~ .label {
                        background-color: #e8f2ff;
                        border-color: #d3e3f8;
                        color: #0075e9;
                    }
                }
            }
        }
    }

    .dl-options {
        display: flex;
        margin-bottom: 25px;
        gap: 24px;
        transition: all ease-in-out 0.3s;
        max-height: 60px;
        .input-wrp {
            flex: 1;
            .label {
                border: 1px solid #d3e3f8;
                padding: 16px 24px;
                border-radius: 12px;
                display: flex;
                gap: 10px;
                .checks {
                    flex: 0 0 16px;
                    max-width: 16px;
                    height: 16px;
                    border-radius: 16px;
                    border: 1px solid #0075e9;
                    position: relative;
                    &:before {
                        position: absolute;
                        content: '';
                        inset: 1px;
                        border-radius: 100%;
                    }
                }
            }

            .customInput {
                &:checked {
                    & ~ .label {
                        background-color: #f2f6fc;
                        .txt {
                            color: #0075e9;
                        }
                        .checks {
                            &:before {
                                background-color: #0075e9;
                            }
                        }
                    }
                }
            } // custom input
        } // input wrps
    }
}

.table-btn-grp .number-crcl,
.tender-table .number-crcl {
    font-size: 13px;
}

.download-btn-otr {
    display: flex;
    justify-content: end;
    margin-top: 15px;
    .in-active {
        cursor: default;
        .dwnldbtn,
        small {
            opacity: 0.5;
        }
    }
    small {
        margin: 0 10px;
    }
    .Btn {
        border: 0;
        background-color: transparent;
        font: 14px/21px Poppins;
        color: #0075e9;

        display: flex;
        align-items: center;
    }
}

.move-modal-drop {
}

.css-t3ipsp-control {
}

.select-custom-react .css-13cymwt-control {
    outline: none !important;
}

.refres-btn-cvr {
    width: 100%;
    justify-content: space-between;
    .btsgrp {
        margin-right: 30px;
        margin-top: -3px;
    }
    .round-btn {
        width: 27px !important;
        height: 27px !important ;
        background-color: #e8f2ff !important;
        img {
            width: 13px !important;
        }
    }
}

.dark-mode {
    .round-btn {
        background-color: #313243 !important;
    }
}

// duplicate proposal
.duplicate-proposal {
    .form-floating {
        label {
            color: #35475e !important;
        }
    }

    .form-floating > .form-control:focus ~ label {
        transform: scale(0.85) translateY(-2px) translateX(-8px);
    }

    .btn {
        font-family: 'Poppins', sans-serif;
        padding: 10px 30px;
        border-radius: 8px;
        width: 100%;
        max-width: 164px;
        border: 1px solid #0075e9;
        &.btn-outline {
            color: #0075e9;
        }
    }
    .modal-footer {
        @media (min-width: 1440px) {
            padding-bottom: 70px !important;
        }
    }
    .modal-body {
        @media (min-width: 1440px) {
            padding-top: 30px !important;
        }
    }
}
.dark-mode {
    .duplicate-proposal {
        .form-floating > .form-control:focus ~ label {
            background-color: #242636;
            color: #a2aab2 !important;
        }
    }
}

.addProjectModal {
    .modal-dialog {
        .modal-content {
            .modal-header,
            .modal-body,
            .modal-footer {
                padding-left: 80px;
                padding-right: 80px;
            }

            .modal-header {
                padding-top: 64px;
                position: relative;
                font-family: 'Poppins', sans-serif;
                .btn-close {
                    position: absolute;
                    z-index: 2;
                    top: 20px;
                    right: 20px;
                }
            }

            .modal-footer {
                padding-top: 30px;
            }
        }
    }
}
img.warningIcon {
    width: 100px;
}
