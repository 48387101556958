@import 'partials/variables';
@import 'partials/mixins';

.modal-full-width {
    .modal-dialog {
        max-width: 98%;

        .table-responsive {
            // max-height: calc(100vh - 250px);
            // min-height: calc(100vh - 250px);
        }

        .modal-content {
            border-radius: 24px;
            height: 100vh;
            padding-bottom: 20px;
            .modal-header {
                padding: 16px 30px;
                border: none;

                .modal-title {
                    text-align: left;
                    font: 500 20px/30px $poppins;
                    color: #35475e;
                }

                .btn-close {
                    position: absolute;
                    width: 27px;
                    height: 27px;
                    background-color: #e8f2ff;
                    color: $primary-bg;
                    right: 20px;
                    top: 20px;
                    border-radius: 100px;
                    opacity: 1;
                    background-image: url('../img/close.svg');
                    background-size: 20px;
                    z-index: 9;
                }
            }

            .modal-body {
                padding: 0 40px 10px;

                .full-width-content {
                    .top-head {
                        display: flex;
                        align-items: center;
                        margin-bottom: 22px;
                        .title-lft {
                            font: 500 20px/30px $poppins;
                            color: $primary-bg;
                        }
                    }
                    .btn-grp {
                        margin-left: auto;
                    }
                    .btn-add {
                        background-color: #0075e9;
                        border: 1px solid #0075e9;
                        color: #ffffff;
                        border-radius: 8px;
                        height: 40px;
                        min-width: auto;
                        font: normal normal normal 14px/21px $poppins;
                        box-shadow: none;
                        &:hover {
                            background-color: #1a8dff;
                            border-color: #1a8dff;
                        }
                        .ico {
                            margin-left: 5px;
                        }
                    }

                    .forms-cover {
                        .title-dark {
                            font: 400 20px/30px $poppins;
                            color: #ffffff;
                            background: #a2bcd6;
                            border: 1px solid #b9d0ee;
                            border-radius: 12px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            min-height: 50px;
                            margin-bottom: 16px;
                        }
                    }

                    .form-box {
                        background: #f8fbff;
                        border: 1px solid #d3e3f8;
                        border-radius: 12px;
                        padding: 34px 23px;
                        margin-top: 20px;
                        position: relative;

                        .floating-txt {
                            position: absolute;
                            top: -10px;
                            color: #6c83a2;
                            font: 13px/17px Poppins;
                            background-color: #fff;
                            padding: 3px 8px;
                        }

                        .place-holder-color {
                            @include placeholder {
                                opacity: 1;
                                color: #a5aeb7;
                            }
                        }
                    }

                    .form-grp {
                        margin-bottom: 25px;
                        .form-floating label {
                            // width: 100%!important;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                        .form-floating > .form-control:not(:placeholder-shown) ~ label {
                            width: auto !important;
                        }
                    }
                    .textarea {
                        height: 120px !important;
                    }

                    .btn-grp {
                        display: flex;
                        // flex-wrap: wrap;
                        justify-content: flex-end;
                        column-gap: 17px;
                        .btn-sm {
                            min-width: 140px;
                            height: 43px;
                            border-radius: 11px;
                            box-shadow: none !important;
                            font: 500 13px/17px Poppins;
                        }
                        .btn-primary {
                            background: $primary-bg;
                            border: 1px solid $primary-bg;
                            color: #fff;
                            &:hover {
                                background-color: #1a8dff;
                                border-color: #1a8dff;
                            }
                        }
                        .btn-secondary {
                            background-color: #fff;
                            color: $primary-bg;
                            border: 1px solid $primary-bg;
                            &:hover {
                                opacity: 0.9;
                            }
                        }
                    }

                    .form-floating > .form-control:focus ~ label,
                    .form-floating > .form-control:not(:placeholder-shown) ~ label,
                    .form-floating > .form-select ~ label {
                        opacity: 1;
                        transform: scale(0.85) translateY(-10px) translateX(10px);
                        padding: 0;
                        background: #fff;
                        padding: 0 10px;
                        max-height: 20px;
                    }
                    .floating-form-block {
                        margin-bottom: 25px;
                        padding-top: 0px;

                        .form-floating {
                            label {
                                font: 13px/20px $poppins;
                                text-align: left;
                                color: #35475e;

                                span {
                                    color: #0075e9;
                                }
                            }

                            .form-control {
                                height: 51px;
                                background: #ffffff;
                                border: 1px solid #d3e3f8;
                                border-radius: 12px;
                                font-size: 13px;
                                text-align: left;
                                font-family: $poppins;
                                color: #35475e;

                                &:focus {
                                    box-shadow: none;
                                    padding-top: 10px;
                                }
                            }
                            .form-control:disabled {
                                background: #f2f8ff;
                                label {
                                }
                            }
                        }
                    }

                    .total-scnt {
                        min-height: 72px;
                        border: 1px solid #b9d0ee;
                        border-radius: 12px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 25px 26px;
                        margin-top: 16px;
                        margin-bottom: 16px;
                        .total {
                            color: #35475e;
                            font: 600 16px/25px Poppins;
                        }
                        .count {
                            color: #35475e;
                            font: 600 16px/25px Poppins;
                        }
                    }
                }
            }

            .table-cover {
                .table-head {
                    font: 400 20px/30px $poppins;
                    color: #ffffff;
                    background: #a2bcd6;
                    border: 1px solid #b9d0ee;
                    border-radius: 14px 14px 0px 0px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-height: 50px;
                    margin-bottom: 0px;
                }
                .table-responsive {
                    margin-bottom: 20px;
                    overflow-x: visible;
                }
                .table-custom {
                    border-collapse: collapse;
                    width: 100%;
                    tr {
                        border-top: 0 !important;
                        border: 1px solid #d3e3f8;
                        height: 40px;
                    }
                    th {
                        border-top: 0 !important;
                        color: #35475e;
                        font: 600 16px/25px Poppins;
                        padding: 5px 25px;
                    }
                    td {
                        color: #35475e;
                        font: 13px/20px Poppins;
                        .strong {
                            &.title-name {
                                word-break: break-all;
                                margin-left: auto;
                                margin-right: auto;
                                max-width: 120px;
                                min-width: 80px;
                            }

                            color: #35475e;
                            font: 600 13px/20px Poppins;
                        }
                    }
                    td,
                    th {
                        border: 1px solid #d3e3f8;
                        text-align: center;
                        padding: 0 15px;
                    }

                    .width-01 {
                        width: 100px;
                    }
                    .width-02 {
                        width: 150px;
                    }
                    // .width-03 {
                    //     width: 50%;
                    // }
                    .table-content {
                        padding: 0;
                        .title-module {
                            background: #e5f0ff;
                            height: 48px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-bottom: 1px solid #d3e3f8;
                            font: 600 16px/25px Poppins;
                            margin-bottom: -1px;
                            position: relative;
                        }
                        .para {
                            color: #6c83a2;
                            font: 14px/21px Poppins;
                            text-align: left;
                            padding: 20px 100px;
                        }
                    }
                    .estimation {
                        td {
                            font: 600 14px/21px Poppins;
                            color: #35475e;
                        }
                    }
                }
            }
            .title-italic {
                color: #5892cc;
                font: italic 600 13px/20px Poppins;
                letter-spacing: 0px;
                margin-bottom: 10px;
                display: block;
            }
            .add-btn-otr {
                // margin-top: 16px;
                // margin-bottom: 20px;
                display: flex;
                column-gap: 15px;
                .add-btn {
                    background: #f3f8ff;
                    border: 1px dashed #c4ddff;
                    border-radius: 8px;
                    color: $primary-bg;
                    min-width: 170px;
                    padding: 16px;
                    font: 500 13px/20px Poppins;
                    &:hover {
                        // opacity: 0.8;
                    }

                    .ico {
                        filter: invert(30%) sepia(70%) saturate(3489%) hue-rotate(196deg) brightness(97%) contrast(101%);
                        margin-left: 15px;
                    }
                }
            }

            .list-content {
                color: #35475e !important;
                font: 500 13px/20px Poppins;
                text-align: left !important;
                padding-top: 25px !important;
                padding-bottom: 25px !important;
                padding-left: 20px !important;
                padding-right: 20px !important;
            }

            .drop-btn-sm {
                .drop-btn {
                    background-color: transparent;
                    border: 0;
                    color: #0075e9;
                    font: italic 600 16px/25px Poppins;
                }
                .dropdown-menu {
                    box-shadow: 0px 3px 6px #0075e929;
                    border-radius: 8px;
                    border: 0;
                    min-width: 180px;

                    overflow: hidden;
                    .dropdown-item {
                        color: #35475e;
                        padding: 10px 15px;
                        font: 500 13px/18px Poppins;
                        &:hover {
                            background-color: #e9ecef !important;
                        }
                        .icon {
                            margin-right: 10px;
                            img {
                                width: 15px;
                            }
                        }
                    }
                }
            }

            .sub-heading {
                border-top: 1px solid #d3e3f8;
                .italic-head {
                    color: #a2bcd6;
                    font: italic 500 13px/20px Poppins;
                    margin-top: 20px;
                    margin-bottom: 20px;
                }
            }

            .modal-footer {
                border: none;
                padding: 15px 65px 15px;

                .btn {
                    width: 165px;
                    height: 51px;
                    border-radius: 11px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 14px;
                    font-family: $poppins;
                    font-weight: normal;
                    text-align: center;

                    &.btn-secondary {
                        border: 1px solid #0075e9;
                        background: transparent !important;
                        color: #0075e9;
                        margin-right: 17px;
                    }

                    &.btn-primary {
                        background: #0075e9;
                        border: none;
                        color: #fff;
                    }
                }
            }

            .actions-mdl {
                position: absolute;
                right: 20px;
                .btn-act-dropdown {
                    background-color: transparent !important;
                    border: none !important;
                }
                .actn-btns {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    background-color: #ffffff;
                    padding: 10px 20px;
                    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
                    border-radius: 10px;
                    max-width: 160px;
                    position: absolute;
                    right: 0;
                    .actn-btn {
                        width: 100px;
                        flex: 0 0 100%;
                        padding: 5px 0px;
                        margin: 0;
                        text-decoration: none;
                        display: flex;
                        align-items: center;
                        border: none;
                        border-radius: 0 !important;
                        & + .actn-btn {
                            border-top: 1px solid #d3e3f8;
                        }
                        &:hover,
                        &:focus {
                            box-shadow: none;
                        }
                        img {
                            object-fit: contain;
                        }
                        .txt {
                            padding-left: 10px;
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}
// &.scoll-hide{
//     overflow: hidden;
//     resize: none;
// }
.textarea-scroll::-webkit-scrollbar {
    width: 0px;
}

.textarea-scroll::-webkit-scrollbar-track {
    background: transparent;
}

.textarea-scroll::-webkit-scrollbar-thumb {
    background: transparent;
}

.textarea-scroll::-webkit-scrollbar-thumb:hover {
    background: transparent;
}
.proposal-add-modal .step-tab-panel {
    margin-bottom: 0 !important;
}
.modal-full-width .modal-dialog .modal-content .table-cover .table-custom ul li span,
.modal-full-width .modal-dialog .modal-content .table-cover .table-custom ul li,
.modal-full-width .modal-dialog .modal-content .table-cover .table-custom p span,
.modal-full-width .modal-dialog .modal-content .table-cover .table-custom p strong {
    color: #35475e !important;
}

// Dark mode
.dark-mode {
    .actions-mdl {
        .actn-btns {
            background-color: #11121d !important;
            border-color: transparent !important;
            .btn {
                border-color: transparent !important;
                color: #ffffff;
                box-shadow: none !important;
                & + .btn {
                    border-top: 1px solid #333546 !important;
                }
            }
        }
    }

    .modal-full-width .modal-dialog .modal-content .table-cover.table-preview .table-custom ul li span,
    .modal-full-width .modal-dialog .modal-content .table-cover.table-preview .table-custom ul li,
    .modal-full-width .modal-dialog .modal-content .table-cover.table-preview .table-custom p span,
    .modal-full-width .modal-dialog .modal-content .table-cover.table-preview .table-custom p strong {
        color: #35475e !important;
    }

    .modal-full-width .modal-dialog .modal-content .table-cover .table-custom ul li span,
    .modal-full-width .modal-dialog .modal-content .table-cover .table-custom ul li,
    .modal-full-width .modal-dialog .modal-content .table-cover .table-custom p span,
    .modal-full-width .modal-dialog .modal-content .table-cover .table-custom p strong {
        color: #fff !important;
    }

    .tble-area-info .tble-area-item .head,
    .tble-area-info .tble-area-item .amt {
        color: #fff !important;
    }
    .tble-area-info .tble-area-item,
    .tble-area-info .tble-area-item.net {
        background: #1e1e2c !important;
    }
    .tble-area-info .tble-area-item .icon {
        -webkit-filter: brightness(0) invert(1);
        filter: brightness(0) invert(1);
    }
    .modal-full-width .modal-dialog .modal-content .table-cover .table-custom tr {
        border-bottom: 1px solid rgb(73, 75, 91);
        border-left: 1px solid rgb(73, 75, 91);
        border-right: 1px solid rgb(73, 75, 91);
    }
    .modal-full-width .modal-dialog .modal-content .actions-mdl .actn-btns .actn-btn {
        border: 1px solid rgb(79 81 93);
        background-color: #11121d;
        img {
            -webkit-filter: brightness(0) invert(1);
            filter: brightness(0) invert(1);
        }
    }
    .modal-full-width .modal-dialog .modal-content .actions-mdl .actn-btns .actn-btn:hover,
    .modal-full-width .modal-dialog .modal-content .actions-mdl .actn-btns .actn-btn:focus {
        box-shadow: 0 0 0 0.15rem rgb(51 53 70);
        border: transparent;
    }
    .modal-full-width .modal-dialog .modal-content .modal-header .btn-close {
        background-size: 10px;
    }
    .modal-full-width .modal-dialog .modal-content .modal-header .modal-title {
        color: #fff;
    }
    .modal-full-width .modal-dialog .modal-content .modal-body .full-width-content .top-head .title-lft {
        color: $light-clr;
    }
    .modal-full-width .modal-dialog .modal-content .modal-body .full-width-content .btn-add {
        background-color: $dark-bg-2;
        border-color: $dark-bg-2;
    }
    .modal-full-width .modal-dialog .modal-content .modal-body .full-width-content .forms-cover .title-dark {
        background: #333546;
        color: #fff;
        border-color: #333546;
    }
    .modal-full-width .modal-dialog .modal-content .modal-body .full-width-content .form-box {
        background: #1e1e2c;
        border-color: #494b5b;
    }
    .modal-full-width
        .modal-dialog
        .modal-content
        .modal-body
        .full-width-content
        .floating-form-block
        .form-floating
        .form-control {
        background-color: $dark-bg;
        border-color: #333546;
        color: #fff;
    }
    .modal-full-width
        .modal-dialog
        .modal-content
        .modal-body
        .full-width-content
        .floating-form-block
        .form-floating
        label {
        color: #fff;
    }
    .modal-full-width
        .modal-dialog
        .modal-content
        .modal-body
        .full-width-content
        .form-floating
        > .form-control:focus
        ~ label {
        background-color: $dark-bg;
    }
    .modal-full-width .modal-dialog .modal-content .modal-body .full-width-content .btn-grp .btn-secondary {
        background-color: transparent;
    }
    .modal-full-width .modal-dialog .modal-content .modal-body .full-width-content .total-scnt {
        border-color: $dark-border;
    }
    .modal-full-width .modal-dialog .modal-content .modal-body .full-width-content .total-scnt .total,
    .modal-full-width .modal-dialog .modal-content .modal-body .full-width-content .total-scnt .count {
        color: #fff;
    }
    .modal-full-width .modal-dialog .modal-content .add-btn-otr .add-btn {
        background-color: transparent;
        border: 1px dashed #a2aab2;
        color: #e5e5e5;
    }
    .modal-full-width .modal-dialog .modal-content .add-btn-otr .add-btn .ico {
        filter: brightness(0) invert(1);
    }
    .modal-full-width .modal-dialog .modal-content .sub-heading {
        border-color: $dark-bg-2;
    }
    .modal-full-width .modal-dialog .modal-content .table-cover .table-head {
        background: #333546;
    }
    .modal-full-width .modal-dialog .modal-content .table-cover .table-head,
    .modal-full-width .modal-dialog .modal-content .table-cover .table-custom th {
        border-color: $dark-border;
        color: #fff;
    }
    .modal-full-width .modal-dialog .modal-content .table-cover .table-custom td {
        color: #e5e5e5;
        border-color: $dark-border;
    }
    .modal-full-width .modal-dialog .modal-content .table-cover .table-custom .table-content .title-module {
        background: #1e1e2c;
        border-color: $dark-border;
        color: #fff;
    }
    .modal-full-width .modal-dialog .modal-content .table-cover .table-custom .table-content .para {
        color: $light-clr;
    }
    .modal-full-width .modal-dialog .modal-content .modal-body .full-width-content .form-box .floating-txt {
        background: #1e1e2c;
        color: $light-clr;
    }
    .modal-full-width .modal-dialog .modal-content .list-content,
    .modal-full-width .modal-dialog .modal-content .table-cover .table-custom td .strong {
        color: #e5e5e5 !important;
    }
    .modal-full-width .modal-dialog .modal-content .drop-btn-sm .drop-btn {
        color: #fff;
    }
    .modal-full-width
        .modal-dialog
        .modal-content
        .modal-body
        .full-width-content
        .form-floating
        > .form-control:focus
        ~ label,
    .modal-full-width
        .modal-dialog
        .modal-content
        .modal-body
        .full-width-content
        .form-floating
        > .form-control:not(:placeholder-shown)
        ~ label,
    .modal-full-width
        .modal-dialog
        .modal-content
        .modal-body
        .full-width-content
        .form-floating
        > .form-select
        ~ label {
        background-color: $dark-bg;
    }
    .modal-full-width .modal-dialog .modal-content .drop-btn-sm .dropdown-menu {
        background-color: #11121d;
        box-shadow: 0px 3px 16px #0000005c;
    }
    .modal-full-width .modal-dialog .modal-content .drop-btn-sm .dropdown-menu li {
        &:last-child .dropdown-item {
            border-bottom: 0;
        }
    }
    .modal-full-width .modal-dialog .modal-content .drop-btn-sm .dropdown-menu .dropdown-item {
        color: #e5e5e5;
        background-color: #11121d !important;
        border-bottom: 1px solid #2b2b2b;
    }
    .modal-full-width .modal-dialog .modal-content .drop-btn-sm .dropdown-menu .dropdown-item .icon img {
        filter: brightness(0) invert(1);
    }
}

.modal-full-width .modal-dialog .modal-content .drop-btn-sm .dropdown-menu .dropdown-item {
    white-space: normal !important;
    display: inline-flex;
}
.modal-full-width .modal-dialog .modal-content .drop-btn-sm .dropdown-menu .dropdown-item .icon {
    flex-shrink: 0;
}

.container-view table.sm-table td {
    vertical-align: top !important;
}
.uploade-otr {
    .floating-form-block {
        margin-bottom: 10px !important;
    }
}

@media ($sm-1679) {
    .uploade-otr .floating-form-block {
        margin-bottom: 10px !important;
    }
    .error-msg {
        font-size: 12px;
    }
    .password-control {
        .form-floating > label {
            padding: 14px 15px;
        }
    }

    .modal-designation .modal-dialog .modal-content .modal-body .floating-form-block {
        margin-bottom: 35px;
    }

    .modal-full-width .modal-dialog .modal-content .modal-body .full-width-content .btn-add {
        // min-width: 175px;
    }
    .modal-full-width .modal-dialog .modal-content .modal-header .modal-title {
        font: 500 17px / 30px Poppins, sans-serif;
    }
    .modal-full-width .modal-dialog .modal-content .modal-body .full-width-content .top-head .title-lft {
        font: 500 17px / 30px Poppins, sans-serif;
    }
    .modal-full-width .modal-dialog .modal-content .modal-body .full-width-content .top-head {
        margin-bottom: 12px;
    }
    .modal-full-width .modal-dialog .modal-content .modal-body .full-width-content .forms-cover .title-dark {
        min-height: 40px;
        font: 400 17px/30px 'Poppins', sans-serif;
    }
    .modal-full-width
        .modal-dialog
        .modal-content
        .modal-body
        .full-width-content
        .floating-form-block
        .form-floating
        .form-control {
        // height:45px;
        padding: 10px 15px;
    }
    .form-floating > label {
        padding: 15px 15px;
    }
    .modal-full-width .modal-dialog .modal-content .modal-body .full-width-content .form-box {
        padding: 30px 18px;
    }
    .modal-full-width .modal-dialog .modal-content .modal-body .full-width-content .total-scnt {
        min-height: 50px;
        padding: 17px 18px;
    }
    .modal-full-width .modal-dialog .modal-content .modal-body .full-width-content .total-scnt .total,
    .modal-full-width .modal-dialog .modal-content .modal-body .full-width-content .total-scnt .count {
        font: 600 14px/25px Poppins;
    }
    .modal-full-width .modal-dialog .modal-content .table-cover .table-head {
        min-height: 40px;
        font: 400 17px/30px 'Poppins', sans-serif;
    }
    .modal-full-width .modal-dialog .modal-content .table-cover .table-custom th {
        font: 600 14px/25px Poppins;
    }
    .modal-full-width .modal-dialog .modal-content .table-cover .table-custom tr {
        height: 35px;
    }
    .modal-full-width .modal-dialog .modal-content .table-cover .table-custom .estimation td {
        font: 600 13px/21px Poppins;
    }
    .modal-full-width .modal-dialog .modal-content .table-cover .table-custom .table-content .title-module {
        height: 40px;
        font: 600 13px/25px Poppins;
    }
    .modal-full-width .modal-dialog .modal-content .table-cover .table-custom .table-content .para {
        font: 13px/20px Poppins;
        padding: 20px 80px;
    }
    .modal-full-width .modal-dialog .modal-content .add-btn-otr .add-btn {
        min-width: 160px;
        padding: 12px;
        font: 500 13px/20px Poppins;
    }
    .modal-full-width .modal-dialog .modal-content .drop-btn-sm .drop-btn {
        font: italic 600 14px/25px Poppins;
    }
}

@media ($sm-991) {
    .modal-full-width .modal-dialog .modal-content .actions-mdl {
        right: 10px;
    }
    .modal-full-width .modal-dialog .modal-content .table-cover .table-custom .table-content .para {
        padding: 20px 40px;
    }
}
@media ($sm-767) {
    .modal-full-width .modal-dialog .modal-content .modal-header {
        padding: 16px 20px;
    }
    .modal-full-width .modal-dialog .modal-content .modal-body {
        padding: 0 20px 20px;
    }
    .modal-full-width .modal-dialog .modal-content .modal-body .full-width-content .top-head .title-lft,
    .modal-full-width .modal-dialog .modal-content .modal-header .modal-title {
        font: 500 16px/20px Poppins, sans-serif;
    }
    .modal-full-width .modal-dialog .modal-content .modal-body .full-width-content .btn-add {
        // min-width: 170px;
    }
    .modal-full-width .modal-dialog .modal-content .modal-body .full-width-content .form-grp {
        margin-bottom: 15px;
    }
}

@media ($sm-479) {
    .modal-full-width .modal-dialog .modal-content .modal-body .full-width-content .top-head .title-lft {
        width: 100%;
        margin-bottom: 15px;
    }
    .modal-full-width .modal-dialog .modal-content .modal-body .full-width-content .top-head {
        flex-direction: column;
    }
    .modal-full-width .modal-dialog .modal-content .modal-body .full-width-content .total-scnt {
        min-height: 40px;
        padding: 12px 18px;
    }
    .modal-full-width .modal-dialog .modal-content .modal-body .full-width-content .btn-grp .btn-sm {
        flex-grow: 1;
        min-width: auto;
    }
}

// new seaction
.tble-area-info {
    flex-wrap: wrap;
    > div {
        row-gap: 10px;
    }
    .col-md-4 {
        &:last-child {
            .tble-area-item {
                margin-bottom: 0px;
            }
        }
    }
    .tble-area-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background: #afc6e426;
        border-radius: 12px;
        padding: 20px 40px 20px 20px;
        height: 100%;
        @media (max-width: 1679px) {
            padding: 15px 30px 15px 15px;
        }
        @media (max-width: 991px) {
            padding: 10px;
        }
        @media (max-width: 767px) {
            margin-bottom: 15px;
        }
        .icon {
            margin-right: 20px;
        }
        .head {
            color: #35475e;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 16px;
            font-family: 'Poppins', sans-serif;
            @media (max-width: 1679px) {
                font-size: 14px;
            }
            @media (max-width: 991px) {
                font-size: 12px;
            }
        }
        .amt {
            margin-left: auto;
            color: #35475e;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 16px;
            font-family: 'Poppins', sans-serif;
            @media (max-width: 1679px) {
                font-size: 14px;
            }
            @media (max-width: 991px) {
                font-size: 12px;
            }
        }
        &.qa {
            background-color: #e2eeff8c;
            .amt,
            .head {
                color: #35475e;
            }
        }
        &.net {
            background-color: #c4e1ff54;
            .amt,
            .head {
                color: #0075e9;
            }
        }
    }
}

.modal-full-width .full-width-content {
    padding: 0 15px;
}

.accordion-button:focus {
    box-shadow: none !important;
}

.info-icon {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: var(--fa-display, inline-block);
    font-style: normal;
    font-variant: normal;
    line-height: 1;
    text-rendering: auto;
    font-family: var(--fa-style-family, 'Font Awesome 6 Free') !important;
    font-weight: var(--fa-style, 900) !important;
    outline: none;
}
.modal-full-width .modal-dialog .modal-content .table-cover .table-custom .table-content .para {
    p {
        word-break: break-word;
    }
}
.modal-full-width .modal-dialog .modal-content .sub-heading {
    margin-left: 12px;
    margin-right: 12px;
}

@media (max-width: 1199px) {
    .modal-full-width .modal-dialog .modal-content .table-cover .table-responsive {
        overflow-x: auto !important;
    }
}

.modal-full-width .modal-dialog .modal-content .modal-body .full-width-content .btn-add .ico {
    margin-left: 10px !important;
}

.feature-reject {
    border: 1px solid #a88071 !important;
}

.dark-mode {
    .modal-full-width .modal-dialog .modal-content .table-cover .table-custom td strong {
        color: #e5e5e5 !important;
    }
    .modal-full-width .modal-dialog .modal-content .table-cover.table-preview .table-custom td strong {
        color: #35475e !important;
    }
}

.frd-input-cover {
    min-width: 50px;
    margin: 0;
}
