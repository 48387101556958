@import "partials/variables";
@import "partials/mixins";
.dark-mode{
    
    background: #1E1E2C;


    .select__cstm .form-floating{
        border-color: #494B5B;
        background-color: #242636;
    }

    .select__cstm label{
        background-color: #242636 !important;
        color: #fff !important;
    }

.modal-designation .modal-dialog .modal-content {
        background: #062039;
    }

    .modal-designation .modal-dialog .modal-content .modal-header .modal-title {
        color: #fff;
    }

    .modal-designation .modal-dialog .modal-content .modal-header .btn-close {
        background-image: url('../img/Close_dark.svg');
    }

    .modal-designation .modal-dialog .modal-content .modal-body .floating-form-block .form-floating .form-control {
        background: #062039;
        border: 1px solid #1F4478;
    }

    .modal-designation .modal-dialog .modal-content .modal-body .form-floating>.form-control:focus~label {
        background: #062039;
        color: #A2BCD6;
    }

    .modal-designation .modal-dialog .modal-content .modal-body .floating-form-block .form-floating label span {
        color: #0075E9;
    }

    .modal-designation .modal-dialog .modal-content .modal-body .accordion .accordion-item .accordion-header .accordion-button {
        background: #0C2B47;
        border: 1px solid #153563;
        color: #FFFFFF;
    }

    .modal-designation .modal-dialog .modal-content .modal-body .accordion .accordion-item .accordion-header .form-switch .form-check-input:checked {
        background-color: #0075E9;
    }

    .modal-designation .modal-dialog .modal-content .modal-body .accordion .accordion-item .accordion-header .form-switch .form-check-input {
        background-color: #062039;
    }

    .modal-designation .modal-dialog .modal-content .modal-body .accordion-body .modal-card .form-switch .form-check-input:checked {
        background-color: #0075E9;
    }

    .modal-designation .modal-dialog .modal-content .modal-body .accordion-body .modal-card .form-switch .form-check-input {
        background-color: #062039;
    }

    .modal-designation .modal-dialog .modal-content .modal-body .accordion-body .modal-card {
        background: #062039;
        border: 1px solid #1F4478;
    }
    .modal-designation .modal-dialog .modal-content .modal-body .accordion-body .modal-card span{
        color: #fff;
    }
    .modal-designation .modal-dialog .modal-content .modal-body .accordion .accordion-item .accordion-header .form-switch .form-check-input{
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%236C83A2'/%3e%3c/svg%3e");
    }

    .modal-designation .modal-dialog .modal-content .modal-body .accordion .accordion-item .accordion-header .form-switch .form-check-input:checked{
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
    }
    .modal-designation .modal-dialog .modal-content .modal-body .accordion .accordion-item .accordion-header .accordion-button::after{
        background-image: url('../img/collapse-arrow.svg');
    }
















    .wrapper-main .sidebar{
        background: #2B2D40;
    }
    .header{
        background: $dark-bg;
        border-bottom: 0;
    }
   
    .select-custom select{
        background: #0A2946;
        border: 1px solid #35475E;
        color: #fff;
    }
    .select-custom:after{
        border-top: 9px solid #fff;
    }
    .header .lft-sctn .search-bar .search{
        border: 1px solid #35475E;
        box-shadow: none;
        background-color:transparent;
    }
    .input-shadow:focus{
        box-shadow: 0 0 0 0.25rem rgb(10, 42, 72);
    }
     .header .lft-sctn .search-bar .search{
        color: #fff;
        background-image: url(../img/dark-search-icon.svg);

    }
    .profile-drop{
         .btn{
            background-color: transparent;
            color: #fff;
        }
    }
    .date-picker .date-input-cvr .date-input{
        border: 1px solid #35475E;
        color: #E5E5E5;
    }
    .floating-label label{
        background-color: transparent;
        color: #E5E5E5;

    }
    .floating-input:focus ~ label, .floating-input:not(:placeholder-shown) ~ label{
        background-color: #011933;
        color: #E5E5E5;
    }
    .profile-drop .name{
        color: #fff;
        span{
            color: #D3E3F8;
        }
    }

    .wrapper-main .sidebar:not(.sidebar--Collapse) .nav-sidebar > li:hover,.wrapper-main .sidebar:not(.sidebar--Collapse) .nav-sidebar > li.active {
        background:  #1E1E2C;
    }
    .wrapper-main .nav-sidebar li a img{
        // filter: invert(36%) sepia(91%) saturate(3640%) hue-rotate(196deg) brightness(93%) contrast(102%);
    }
    .wrapper-main .sidebar--Collapse .nav-sidebar > li.active{
        background:  #1E1E2C;
    }
    .profile-drop .btn:after{
        border-top: 9px solid #ddd;

    }
    .header .lft-sctn .search-bar{
        @include placeholder{
            opacity: 1;
            color: #3c5c7b;
          }
    }
    .heading-cvr .heading{
        color:#fff;
    }
    .ui-widget-content{
        background: $dark-bg;
        border: 1px solid #35475E !important;

    }
    .ui-widget-header{
        background: $dark-bg !important;
    }
    .ui-datepicker th{
        color: #A2BCD6;
    }
    a.ui-state-default {
        background: #1e1e2c !important;
        color: #A2BCD6;
        border: 1px solid #35475E !important;
    }
    a.ui-state-default:hover{
        background-color: #007bf5 !important; 
    }
    .ui-datepicker select.ui-datepicker-month, .ui-datepicker select.ui-datepicker-year {
        background-color: #007bf5;
        color: #fff; 
    }
    .collapsetoggle{
        background: transparent linear-gradient(180deg, #272939 0%, #393b51 100%) 0% 0% no-repeat padding-box;
    }
    .date-picker .date-input-cvr .date-input{
        background-image: url(../img/calendar-white.svg);
    }
    .switch .slider:before{
        background-color: #3e4051;
    }
}


@media(max-width:991px) {
    .dark-mode .header {
        background: $dark-bg;
        border-top: 2px solid #35475E;
    }
}