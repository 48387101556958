@import 'partials/variables';
@import 'partials/mixins';

.details-wrap {
    border-radius: 12px;
    background-color: #fff;
    padding: 15px;
    margin-bottom: 20px;
    .inner__content {
        padding: 30px 40px;
        .portfolio-col {
            display: flex;

            flex-direction: column;
            .icon {
                flex-shrink: 0;
                margin-right: 10px;
                display: inline-flex;
                img {
                    width: 15px;
                    height: 14px;
                    object-fit: contain;
                }
            }
            label {
                color: #0075e9;
                font: 13px/17px Poppins;
                display: flex;
                align-items: center;
                margin-bottom: 5px !important;
            }
            p {
                color: #35475e;
                font: 600 18px/27px Poppins;
                a {
                    color: #0075e9;
                    font-weight: normal;
                    font-size: 15px;
                }
                .linkicon {
                    margin-right: 2px;
                    padding-left: 10px;
                    img {
                        width: 10px;
                    }
                }
            }
            .linkotr {
                a {
                    color: #0075e9;
                    font-weight: normal;
                    font-size: 16px;
                }
                margin-top: 15px;
            }
            ul {
                display: inline-flex;
                flex-wrap: wrap;
                column-gap: 8px;
                align-items: center;
                margin-top: 15px;
                margin-bottom: 0 !important;
                li {
                    list-style: none;
                    display: inline-flex;
                    flex-wrap: wrap;
                    column-gap: 8px;
                    margin-bottom: 8px;
                    min-width: 165px;
                    &:first-child {
                        width: 170px;
                    }
                    strong {
                        color: #35475e;
                        font: 500 13px/23px Poppins;

                        width: 170px;
                    }
                    .tag {
                        background: #ebf3ff;
                        border-radius: 23px;
                        color: #0075e9;
                        min-width: 90px;
                        width: 100%;
                        height: 30px;
                        display: flex;
                        padding: 5px 10px;
                        font: normal 13px/17px Poppins;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }
        article {
            p {
                color: #5b7391 !important;
                font: normal 14px/23px Poppins !important;
            }
        }
    }

    .portfolios-image {
        width: 100%;
        position: relative;
        transition: 0.3s;

        .dwnld-img-btn {
            width: 35px;
            height: 35px;
            border-radius: 50%;
            transition: 0.3s;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            position: absolute;
            top: 50px;
            z-index: 9;
            right: 50px;

            -webkit-box-shadow: 4px 4px 11px -3px rgba(0, 0, 0, 0.701);
            -moz-box-shadow: 4px 4px 11px -3px rgba(0, 0, 0, 0.701);
            box-shadow: 4px 4px 11px -3px rgba(0, 0, 0, 0.701);

            img {
                width: 12px;
            }
        }

        // &:hover {
        //     .dwnld-img-btn {
        //         display: flex;
        //         align-items: center;
        //         justify-content: center;
        //         right: 85px !important;
        //         opacity: 0.5 !important;
        //     }
        // }
    }
}

.asd {
    width: auto;
    flex: 1 1 auto;
}

.portfolio-preview-sec {
    min-height: calc(100vh - 215px);
    // height: calc(100vh - 215px);

    // flex-wrap: wrap;
    display: flex;
    width: 100%;
    .portfolio-sidebar-col {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 30%;
    }
    .portfolio-content-col {
        flex-grow: 1;
    }

    > div {
        padding: 0 8px;
    }

    .portfolio-list {
        background: #eaf7ff;
        border-radius: 4px;
        height: 100%;
        padding: 20px 10px 0px 25px;
        position: relative;
        &:after {
            content: '';
            background: transparent linear-gradient(180deg, #eaf7ff00 0%, #eaf7ff 100%) 0% 0% no-repeat padding-box;
            position: absolute;
            bottom: 0;
            height: 100px;
            width: 100%;
            right: 0;
        }
        .title-list {
            color: #327aa7;
            font: 500 14px/23px Poppins;
            margin-bottom: 16px;
        }

        ul {
            list-style: none;
            overflow: auto;
            height: calc(100vh - 150px);

            padding-right: 15px;
            li {
                background: #ffffff;
                box-shadow: 0px 3px 6px #00000012;
                border-radius: 16px;
                min-height: 100px;
                padding: 20px;
                margin-bottom: 13px;

                display: flex;
                align-items: center;

                .drag-icon {
                    flex-shrink: 0;
                }

                .right-sct {
                    border-left: 1px solid #e8f2ff;
                    display: flex;
                    margin-left: 13px;
                    padding-left: 13px;
                    width: 100%;
                    .img-potfolio {
                        width: 120px;
                        margin-right: 16px;
                    }
                    .cnt {
                        strong {
                            color: #314055;
                            font: 600 14px/23px Poppins;
                            margin-bottom: 5px;
                            display: inline-block;
                        }
                        p {
                            color: #6c83a2;
                            font: normal 14px/17px Poppins;
                            margin-bottom: 5px;
                        }
                    }
                    .close__btn {
                        border: 0;
                        margin-left: auto;
                        background-color: transparent;
                        flex-shrink: 0;
                        img {
                            width: 25px;
                        }
                    }
                }
            }
        }
    }
    .portfolio-slider {
        background: #daedf8;
        border-radius: 4px;
        height: 100%;

        padding-top: 50px;
        padding-bottom: 50px;
        display: flex;
        .portfolio-col p {
            //         overflow: hidden;
            // text-overflow: ellipsis;
            // white-space: nowrap;
            // width: 40px;
        }
        .box__full {
            // background-color: blue;

            margin: 0 auto;
            // width: 450px !important;
            img {
                // width: 100%;
            }

            .large-view {
                display: flex;
                justify-content: center;
                flex-direction: column;
                margin: 0 auto;
                .portfolio-cover {
                    .page-outer {
                        height: 100% !important;
                    }
                    .cover {
                        height: 100% !important;
                    }
                }

                img {
                    // width: 100%;
                    // height: 100%;
                    margin: 0 auto;
                }
                .details-wrap .inner__content {
                    padding: 10px !important;
                    > .row {
                        > div {
                            // flex: 1 0 0% !important;
                        }
                    }
                }
                .portfolio-col ul li .tag {
                    min-width: 88px !important;
                }
                .portfolio-col ul {
                    margin-bottom: 0 !important;
                }
            }
        }

        .footer-main .title-md {
            font: 600 25px/35px Rubik;
        }
        .footer-main .title-lg {
            font: 600 40px/42px Rubik;
        }
        .portfolio-cover .header-sm {
            top: 20px;
            left: 20px;
        }
        .portfolio-cover .footer-main {
            // padding:0 20px;
        }
        .portfolio-cover .cover-image .shape-two-otr .shape-two:before {
            background-color: #044783;
        }
        .portfolio-cover .page-outer .cover-image {
        }
        .portfolio-cover .footer-main article p {
            font: normal 14px/23px Rubik;
        }
        .portfolio-cover.main-page {
            padding-bottom: 10px;
        }
    }
}

.cvr {
    max-width: 100%;
    display: flex;
    width: 100%;
    .slider-for {
        width: 650px;
        max-width: 100%;
        margin: 0 auto;
    }
    .slider-nav {
        margin-right: 25px;
        width: 15%;
        .thumb-slide {
            margin-bottom: 10px;
            //  width: 80% !important;
        }
        .slick-track {
            width: 90px;
            // margin-right: 25px;
            // width: 100px !important;
            // overflow: auto;
            // height: calc(100vh - 270px) !important;
        }
    }
    .thumbnail-cover .portfolio-cover {
        border: 2px solid transparent;
        transition: 0.3s;
    }
    .thump-active {
        border: 2px solid #ffa805 !important;
        border-radius: 12px;
    }

    .slick-arrow {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        background: url(../img/slider-arrow.png) no-repeat center center;
        background-size: 100%;
        width: 35px;
        height: 35px;
        z-index: 1;
        &.slick-prev {
            transform: rotate(0deg);
        }
        &.slick-prev:before {
            content: '' !important;
        }
        &.slick-next {
            // transform: rotate(180deg);
            transform: rotate(180deg);
        }
        &.slick-next:before {
            content: '' !important;
        }
        &.slick-disabled {
            opacity: 0.2;
        }
    }
}

.cvr .slider-nav .thumb-slide {
    .portfolio-cover {
        zoom: 0.5;
    }
}

.short-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 200px;
}
.single-view-cover {
    .short-text {
        max-width: 100%;
    }
}
.page-outer .details-wrap {
    height: 100%;
}
.thumbnail-cover {
    .details-wrap {
        height: 100%;
    }
    .portfolios-image img {
        // object-fit: contain !important;
        width: 100%;
    }
}

body.dark-mode {
    .select__custom .css-b62m3t-container {
        background-color: transparent;
    }
    .thumbnail-cover .portfolio-cover {
        background: #35353a !important;
    }
    .portfolio-preview-sec .portfolio-list {
        background: #232331;
    }
    .portfolio-preview-sec .portfolio-list ul li {
        background-color: #1b1b26;
    }
    .portfolio-preview-sec .portfolio-list ul li .right-sct {
        border-color: #333546;
    }
    .portfolio-preview-sec .portfolio-list ul li .drag-icon {
        -webkit-filter: brightness(0) invert(1);
        filter: brightness(0) invert(1);
        opacity: 0.7;
    }
    .portfolio-preview-sec .portfolio-list ul li .right-sct .close__btn img {
        filter: grayscale(100%);
        -webkit-filter: grayscale(100%);
        -moz-filter: grayscale(100%);
        opacity: 0.3;
    }
    .portfolio-preview-sec .portfolio-list::after {
        content: '';
        background: linear-gradient(rgba(234, 247, 255, 0) 0%, rgb(35 35 49) 100%) 0% 0% no-repeat padding-box
            padding-box transparent;
    }
    .portfolio-preview-sec .portfolio-list .title-list {
        color: #a2aab2;
    }
    .portfolio-preview-sec .portfolio-slider {
        background: #1b1b26;
    }
    .cvr .slick-arrow {
        filter: grayscale(100%);
        -webkit-filter: grayscale(100%);
        -moz-filter: grayscale(100%);
    }
    .portfolio-preview-sec .portfolio-list ul li .right-sct .cnt strong {
        color: #fff;
    }
    .portfolio-preview-sec .portfolio-list ul li .right-sct .cnt p {
        color: #a2aab2;
    }

    .details-wrap {
        background: #232331;
    }
    .details-wrap .inner__content .portfolio-col .icon img {
        -webkit-filter: brightness(0) invert(1);
        filter: brightness(0) invert(1);
    }
    .details-wrap .inner__content .portfolio-col label {
        color: #fff;
    }
    .details-wrap .inner__content .portfolio-col p,
    .details-wrap .inner__content .portfolio-col ul li strong {
        color: #a2aab2;
    }
    .details-wrap .inner__content article p {
        color: #a2aab2 !important;
    }
    .details-wrap .inner__content .portfolio-col ul li .tag {
        background-color: #333546;
        color: #ffffff;
    }
    .details-wrap .inner__content .portfolio-col p a {
        color: #4286dd;
    }
    .ul-li-table table {
        background: #2a2d3f;
    }
    .ul-li-table table tr {
        border-bottom: 1px solid #2d3c52;
    }
    .ul-li-table {
        border: 1px solid #2d3c52;
    }
    .ul-li-table td,
    .ul-li-table th {
        border-right: 1px solid #2d3c52;
    }
    .ul-li-table th,
    .ul-li-table td {
        color: #a2aab2;
    }
}
.cvr .slider-nav .thumb-slide.slick-current.slick-active {
    border: 2px solid #ffa805;
    border-radius: 4px;
}
.cvr .slider-nav .slick-slide img {
    width: 100%;
}
.details-wrap .portfolios-image {
    text-align: center;
}
.details-wrap .inner__content .portfolio-col p a,
.details-wrap .inner__content .portfolio-col p {
    text-align: justify;
}

.test-mod-bg {
    .select-custom:after {
        content: '';
        top: 28px !important;
    }
}

.portfolio-preview-sec {
    // overflow: hidden;

    .details-wrap .portfolios-image {
        max-height: calc(100vh - 162px) !important;
    }
}

.cvr .slider-nav {
    /* scrollbar  */
    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
        // box-shadow: inset 0 0 2px #666;
        border-radius: 0px;
        background: #b5d1f8;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: rgba(58, 57, 57, 0.158) !important;
        border-radius: 0px;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #c4ddff;
    }
}

.thumbnail-cover {
    padding-right: 5px;
}
.cvr .slider-nav {
    overflow: auto;
    // height: calc(100vh - 150px);
    // height: 100vh;
    height: calc(100vh - 80px);
}

.bk-cvr {
    margin-left: 15px;
    .back_button {
        background-color: #6d9cc2;
        border: 0;
        color: #fff;
        height: 35px;
        min-width: 100px;
        border-radius: 25px;
        display: inline-flex;
        align-items: center;
        padding-right: 15px;
        justify-content: space-evenly;
        &:hover {
            background-color: #5c92c9;
        }
        img {
        }
    }
}

.slider-for {
    .slick-prev:hover,
    .slick-prev:focus,
    .slick-next:hover,
    .slick-next:focus {
        background: url(../img/slider-arrow.png) no-repeat center center !important;
        background-size: cover !important;
    }
}

.cvr .slider-for {
    .portfolios-image img {
        object-fit: cover !important;
        width: 100%;
    }
    .portfolio-col p,
    .portfolio-col a {
        font: normal 13px/23px Poppins;
        span {
        }
    }
    .details-wrap {
        overflow: auto;
    }
}
.cvr .slider-for,
.cvr .slider-nav {
    .footer-main article p {
        word-break: break-word;
    }
}

.portfolio-preview-sec .portfolio-list ul li .right-sct .cnt p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (max-width: 1679px) {
    .portfolio-cover .page-outer .cover-image {
        // height: 290px;
    }
    .portfolio-from-modal .select-custom-fluid.select___custom .css-t3ipsp-control {
        height: 46px !important;
    }
    .portfolio-from-modal .select-custom-fluid.select___custom .css-13cymwt-control {
        height: 46px !important;
    }
    .portfolio-preview-sec .portfolio-list ul {
        height: calc(100vh - 170px);
    }
    .portfolio-preview-sec .portfolio-list ul li .right-sct .cnt strong {
        font: 600 13px/17px Poppins;
    }
    .portfolio-preview-sec .portfolio-list ul li .right-sct .cnt p {
        font: normal 13px/17px Poppins;
        margin-bottom: 0;
    }
    .portfolio-preview-sec .portfolio-list ul li .right-sct .img-potfolio {
        width: 90px;
        margin-right: 10px;
    }
    .portfolio-preview-sec .portfolio-list ul li .drag-icon img {
        width: 10px;
    }
    .portfolio-preview-sec .portfolio-list ul li .right-sct {
        margin-left: 8px;
        padding-left: 8px;
    }
    .portfolio-preview-sec .portfolio-list ul li .right-sct .close__btn img {
        width: 18px;
    }
    .portfolio-preview-sec .portfolio-list ul li {
        min-height: 90px;
        padding: 17px 12px 10px;
        margin-bottom: 13px;
    }
    .portfolio-preview-sec .portfolio-list {
        padding: 20px 10px 0px 15px;
    }
    .portfolio-preview-sec .portfolio-list ul {
        padding-right: 10px;
    }
    .portfolio-preview-sec .portfolio-list ul {
        // height: calc(100vh - 230px);
    }
    .cvr .slick-arrow {
        width: 30px;
        height: 30px;
    }
    .cvr .slick-arrow.slick-prev {
        left: 25px;
    }
    .cvr .slick-arrow.slick-next {
        right: 25px;
    }
    .cvr .slider-for {
        width: 600px;
    }
    .portfolio-preview-sec .portfolio-slider .box__full .large-view {
        // height: calc(100vh - -25%);
        max-width: 500px;
    }
    .portfolio-preview-sec .portfolio-list ul {
        height: calc(100vh - 195px);
    }
    .portfolio-preview-sec .portfolio-slider .box__full .large-view {
    }
    .portfolio-preview-sec .portfolio-slider .portfolio-cover .footer-main article p,
    .portfolio-cover .footer-main article .link-site .link {
        font: normal 12px/18px Rubik;
    }
    .portfolio-cover .footer-main article .link-site {
        // margin-top: 20px;
    }
}
@media (max-width: 1281px) {
    .portfolio-preview-sec {
        .cvr {
            flex-direction: column;
        }
    }

    .cvr {
        align-items: center;
    }
    .portfolio-preview-sec .portfolio-slider .box__full .large-view {
        // height: calc(80vh - 170px);
    }
    .cvr .slider-nav .slick-track {
        width: 70px;
    }
}
@media (max-width: 1199px) {
    .cvr .slider-for .details-wrap {
        min-height: 500px;
        height: auto !important;
    }
    .portfolio-preview-sec .portfolio-slider .box__full .large-view {
        // height: auto;
    }
    .cvr .slick-arrow.slick-next {
        right: -20px;
    }
    .cvr .slick-arrow.slick-prev {
        left: -20px;
    }
    .cvr .slider-for {
        width: 400px;
    }
    .portfolio-preview-sec .portfolio-list ul {
        height: calc(100vh - 300px);
    }

    .cvr .slider-nav {
        margin-right: 10px;
    }
    .cvr .slider-nav {
        display: none !important;
    }
    .details-wrap.mb-3 {
        margin-bottom: 15px !important;
    }
}

@media (max-width: 1023px) {
}

@media (max-width: 991px) {
    .heading-cvr.sm-head {
        flex-wrap: wrap;
    }
    .portfolio-preview-sec .portfolio-sidebar-col {
        width: 40%;
    }
    .portfolio-preview-sec .portfolio-list ul li .right-sct .img-potfolio {
        width: 70px;
    }
    .portfolio-preview-sec .portfolio-list ul li .right-sct {
        align-items: center;
    }
    .cvr {
        display: block;
    }
    // .portfolio-preview-sec{
    //     display: block;
    // }
    .portfolio-preview-sec {
        min-height: auto;
    }
    // .cvr .slider-nav .slick-track{
    //     width: 500px !important;
    // }

    .cvr .slider-nav .slick-track {
        // width: 100% !important;
    }
    .cvr .slider-nav .thumb-slide {
        // width: 100px !important;
    }
    .cvr .slider-nav {
        .slick-track {
            display: flex;
        }
        .slick-slide img {
            // width: 100% ;
        }
    }
    .cvr .slider-nav .thumb-slide {
        margin: 0 5px;
        width: 100% !important;
    }
    .portfolio-preview-sec .portfolio-list ul {
        height: calc(100vh - 300px);
    }
    .details-wrap .inner__content {
        padding: 10px;
    }
}
@media (max-width: 767px) {
    .details-wrap .portfolios-image {
        margin-bottom: 0 !important;
    }
    .portfolio-preview-sec .portfolio-slider .box__full .large-view {
        height: auto;
    }
    .portfolio-preview-sec {
        flex-wrap: wrap;
    }
    .portfolio-preview-sec .portfolio-sidebar-col {
        width: 100%;
    }
    .cvr .slick-arrow {
        display: none !important;
    }
    .cvr .slider-for {
        width: 100%;
    }
    .portfolio-preview-sec .portfolio-slider .box__full .large-view {
        width: 80%;
    }
    .portfolio-preview-sec > div {
        width: 100%;
    }
    .portfolio-preview-sec .portfolio-slider .box__full .large-view {
        // height: auto;
    }
    .details-wrap .inner__content .portfolio-col ul li:first-child {
        width: 100%;
    }
    .details-wrap .inner__content .portfolio-col ul {
        margin-bottom: 0 !important;
    }
    .portfolio-preview-sec .portfolio-list ul {
        height: 300px;
    }
    .portfolio-preview-sec .portfolio-slider {
        margin-top: 0px;
        padding-top: 15px;
    }
}

@media (max-width: 578px) {
    .heading-cvr.sm-head .rgt-scnt .headingForm {
        justify-content: end;
    }
    .cvr .slider-nav {
        margin-right: 0;
    }
    .portfolio-preview-sec .portfolio-slider .box__full .large-view {
        width: 95%;
    }
    .cvr .slick-arrow.slick-next {
        right: -8px;
    }
    .cvr .slick-arrow.slick-prev {
        left: -8px;
    }
    .cvr .slick-arrow {
        width: 20px;
        height: 20px;
    }
    .portfolio-preview-sec > div {
        flex: 0 0 auto;
        width: 100%;
    }
    .cvr .slider-for {
        // width: 90%;
    }
}

.slider .slick-slider-count {
    position: absolute;
    bottom: 10px; /* Adjust the position as needed */
    right: 10px; /* Adjust the position as needed */
}

.portfolio-from-modal,
.addProjectModal {
    .css-1xc3v61-indicatorContainer {
        display: none !important;
    }
    .css-12a83d4-MultiValueRemove {
        background-color: rgb(102 102 102 / 62%) !important !important;
        svg {
            fill: #fff;
        }
    }
    .css-12a83d4-MultiValueRemove:hover {
        background-color: #0075e99e !important;
        svg {
            fill: #fff;
        }
    }

    .select-custom-react .select-z-menu__control {
        border: 1px solid #d3e3f8 !important;
    }
    .css-13cymwt-control,
    .css-t3ipsp-control {
        height: 51px;
        background: #ffffff;
        border: 1px solid #d3e3f8 !important;
        border-radius: 12px;
        font-size: 13px;
        text-align: left;
        font-family: 'Poppins', sans-serif;
        color: #35475e;
        font-weight: normal;
        border-color: #d3e3f8 !important;
        box-shadow: none !important;
    }
    .css-13cymwt-control:hover {
        border: 1px solid #d3e3f8 !important;
    }
    .css-1u9des2-indicatorSeparator {
        display: none;
    }
    .test-mod-bg {
        background-color: #fff !important;
        border: 1px solid #d3e3f8 !important;
    }
    // brightness(0) invert(1)
}

.portfolio-from-modal .select-custom-fluid.select___custom .css-t3ipsp-control {
    height: 51px !important;
}
.portfolio-from-modal .select-custom-fluid.select___custom .css-13cymwt-control {
    height: 51px !important;
}
.z-3 {
    z-index: 3 !important;
}
.z-2 {
    z-index: 2 !important;
}
.z-1 {
    z-index: 1 !important;
}

body.dark-mode {
    .cvr .slider-nav {
        /* Track */
        &::-webkit-scrollbar-track {
            background: #a4a4a560 !important;
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: rgba(58, 57, 57, 0.301) !important;
        }
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: rgba(58, 57, 57, 0.301) !important;
        }
    }
    .portfolio-from-modal,
    .addProjectModal {
        .test-mod-bg {
            background-color: #242636 !important;
            border: 1px solid #333546 !important;
            .floating-label label {
                background-color: #242636 !important;
                color: #fff !important;
            }
            .btn-close {
                filter: brightness(0) invert(1);
            }
        }
        .css-13cymwt-control,
        .css-t3ipsp-control {
            background: #242636;
            border: 1px solid #494b5b !important;
        }
        .css-wsp0cs-MultiValueGeneric {
            color: #fff !important;
        }
        .css-1dimb5e-singleValue {
            color: #a2aab2 !important;
        }
        .floating-input:focus ~ label,
        .floating-input:not(:placeholder-shown) ~ label {
            background-color: #242636 !important;
            color: #a2aab2 !important;
        }
        .select__option {
            background: #242636 !important;
            color: #fff !important;
        }
        .select__menu .css-qr46ko {
            color: #fff;
        }
        .select__menu .css-qr46ko > div:hover {
            background: #494b5b !important;
            color: #fff;
        }
        .select__option:hover {
            color: #fff !important;
            background-color: #494b5b !important;
        }
        .select__menu-list > div {
            color: #fff !important;
        }
        .select__input {
            color: #fff !important;
        }
        .modal-titles {
            color: #fff;
        }
    }
}

.addProjectModal .select__control {
    min-height: 51px !important;
    height: auto !important;
    padding: 10px;
}

.portfolio-preview-sec .portfolio-list ul li .right-sct .close__btn {
    display: flex;
}

.slick-slider .slick-list,
.slick-slider .slick-track {
    // height: 900px !important;
}
.cvr .slider-for {
    .portfolio-cover.main-page {
        height: 100%;
        margin-top: 0;
        .cover-image {
            img {
                height: 100%;
            }
        }
    }
}
.cvr .slick-arrow {
    &:hover {
        opacity: 0.8;
    }
}

.cvr .slider-for,
.thumbnail-cover {
    .contact-info {
        display: none !important;
    }
}

.ul-li-table {
    border: 1px solid #d3e3f8;
    border-radius: 12px;

    table {
        width: auto;
        width: 100%;
        background: #f9fcff;
        margin-bottom: 0;
        .t__width {
            width: 35%;
        }
        tr {
            border-bottom: 1px solid #d3e3f8;
            &:first-child {
                border-top: 0;
            }
            &:last-child {
                border-bottom: 0;
            }
        }
    }
    td,
    th {
        padding: 7px;
        border: 0;
        vertical-align: middle;
        border-right: 1px solid #d3e3f8;
        &:last-child {
            border: 0;
        }
    }
    th {
        color: #35475e;
        font: 600 14px/23px Poppins;
        margin-right: 20px;
        // white-space: nowrap;

        padding-right: 25px;
    }
    td {
        font: normal 14px/21px Poppins;
        letter-spacing: 0px;
        color: #5b7391;
        vertical-align: middle;
        .tags_otr {
            display: flex;
            flex-wrap: wrap;
            gap: 3px 3px;
            align-items: center;
            span.tags {
                background-color: #6eb0f5;
                padding: 2px 8px !important;
                border-radius: 23px;
                margin-top: 0 !important;
                word-wrap: normal;
                display: inline-block;
                color: #fff;
                font-size: 12px;
            }
        }
    }
    strong {
        color: #35475e;
        font: 500 13px/23px Poppins;
        margin-right: 20px;
    }
    .tag {
        background: #ebf3ff;
        border-radius: 23px;
        color: #0075e9;
        min-width: 90px;
        width: 100%;
        min-height: 30px;
        display: inline-flex;
        flex-wrap: wrap;
        padding: 5px 10px;
        font: normal 13px/17px Poppins;
        white-space: nowrap;
        align-items: center;
        justify-content: center;
    }
}

// slider area in portfolio

.portfolio-preview-sec {
    .shape-two-otr .shape-two {
        height: 530px !important;
    }
    .shape-two-otr:after {
        bottom: -67px !important;
    }

    .details-wrap .inner__content .portfolio-col ul li {
    }

    .page-outer .details-wrap {
        .portfolio-col ul li:first-child {
            width: 110px !important;
            min-width: inherit !important;
        }
    }
    .portfolio-col ul li {
        min-width: 75px !important;
        .tag {
            font: normal 12px/17px Poppins !important;
        }
    }
    .details-wrap {
        // height: calc(100vh - 75px);
    }
    .portfolios-image img {
        height: 100%;
    }
    .thumbnail-cover {
        .details-wrap {
            height: 180px;
            .portfolio-col ul li:first-child {
                width: 80px !important;
            }
            .portfolio-col ul li {
                .tag {
                    min-width: 40px !important;
                    height: 27px !important;
                }
            }
        }
        .shape-two-otr .shape-two {
            height: 100px !important;
        }
        .shape-two-otr:after {
            bottom: 0px !important;
        }
        .footer-main article {
            // margin-top: 0 !important;
        }
        .footer-main article p {
            -webkit-line-clamp: 2;
            font-size: 5px !important;
            line-height: 7px !important;
        }
        .footer-main .title-md {
            font-size: 9px !important;
        }
        .footer-main article p,
        .portfolio-cover .footer-main article .link-site .link {
            margin-bottom: 0 !important;
        }
    }
}

.select-custom-react {
    .css-13cymwt-control,
    .css-t3ipsp-control {
        // min-height: px !important;
        height: auto !important;
    }
}

.css-8mmkcg {
    // display: none !important;
}
.select___custom .css-13cymwt-control,
.select___custom .css-t3ipsp-control {
    box-shadow: none;
}
@media (max-width: 1679px) {
    .portfolio-preview-sec {
        .shape-two-otr .shape-two {
            height: 400px !important;
        }
        .shape-two-otr:after {
            bottom: -50px !important;
        }
    }
}
.tag {
    min-width: 40px !important;
    height: 27px !important;
}

.shape-two-otr .shape-two {
    height: 100px !important;
}
.shape-two-otr:after {
    bottom: 0px !important;
}
.footer-main article {
    margin-top: 0 !important;
}
.footer-main article p {
    -webkit-line-clamp: 2;
    // font-size: 5px !important;
    // line-height: 7px !important;
}
.footer-main .title-md {
    // font-size: 9px !important;
}
.footer-main article p,
.portfolio-cover .footer-main article .link-site .link {
    margin-bottom: 0 !important;
}
.portfolio-preview-sec .portfolio-slider .box__full .large-view .details-wrap .inner__content {
    .col-lg-6 {
        width: 100% !important;
    }
}
.portfolio-cover .footer-main article {
    margin-top: 10px !important;
}
@media (max-width: 1679px) {
    .thumbnail-cover {
        .portfolio-cover .footer-main article {
            margin-top: 3px !important;
        }
    }
    .portfolio-preview-sec .portfolio-slider .footer-main .title-lg {
        font: 600 34px/35px Rubik;
    }
    .details-wrap .inner__content .portfolio-col {
        margin-top: 5px !important;
    }
    .portfolio-preview-sec .details-wrap {
        // height: calc(100vh - 0px);
    }
    .cvr .slider-nav {
        height: calc(100vh - 0px);
    }
    .portfolio-preview-sec .thumbnail-cover .details-wrap {
        height: 130px;
    }
    .portfolio-preview-sec .portfolio-slider .footer-main .title-lg {
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
    }
    .cvr .thumbnail-cover .portfolio-cover {
        border: 1px solid transparent;
    }

    .details-wrap .inner__content .portfolio-col p {
        font: normal 13px/23px Poppins !important;
    }
    .details-wrap .inner__content .portfolio-col p.ps-4 {
        font-weight: 600 !important;
    }
    .portfolio-preview-sec {
        .shape-two-otr .shape-two {
            height: 400px !important;
        }
        .shape-two-otr:after {
            bottom: -50px !important;
        }
    }
    .details-wrap .inner__content .portfolio-col p a {
        font-size: 13px;
    }
    .ul-li-table th {
        font: 600 13px/23px Poppins;
    }
    .ul-li-table td {
        font: normal 13px/18px Poppins;
    }
}

@media (max-width: 767px) {
    .portfolio-preview-sec .portfolio-slider .portfolio-cover.main-page {
        padding-bottom: 20px;
    }
    .portfolio-cover .header-sm img {
        margin: 0 !important;
        max-height: 75px;
    }
    .details-wrap .inner__content .portfolio-col label {
        margin-bottom: 10px !important;
    }
    .details-wrap .inner__content .portfolio-col p {
        font: normal 13px/23px Poppins !important;
    }
    .details-wrap .portfolios-image {
        margin-top: 0;
        margin-bottom: 0px;
        padding-bottom: 0;
    }
    .ul-li-table table .t__width {
        width: 45%;
    }
    .ul-li-table {
        border-radius: 8px;
    }
    .ul-li-table td,
    .ul-li-table th {
        padding: 6px 10px;
    }
    .details-wrap .inner__content .portfolio-col {
        margin: 0;
    }
}

.ul-li-table td {
    span {
        display: inline-block;
        padding: 0 !important;
        padding-right: 10px !important;
    }
}

.login-cover .login-wrapper .right-block .form-block .back-btn-block .back-btn img {
    filter: invert(29%) sepia(86%) saturate(3647%) hue-rotate(198deg) brightness(98%) contrast(101%);
}

.details-wrap .portfolios-image {
    img {
        width: 100%;
    }
}

@media (max-width: 767px) {
    .portfolio-col {
        .ps-4 {
            padding-left: 0 !important;
        }
    }
}

.portfolio-cover.thank-you-height {
    height: auto !important;
}
.thumbnail-cover .contact-info .pb-4 {
    padding-bottom: 0 !important;
}
.cvr .slider-for .portfolio-col p a {
    font-size: 13px;
}
.cvr .slider-for .portfolio-col p {
    font-weight: 600;
}
.cvr .slider-for .details-wrap {
    // padding: 10px !important;
    // margin-bottom: 10px !important;
}

.ul-li-table td .tags_otr {
    padding: 0;
}

.ul-li-table {
    margin-top: 8px;
}

@media (max-width: 1679px) {
    .portfolio-preview-sec .portfolio-slider .box__full .large-view .ul-li-table td {
        font: normal 11px/19px Poppins !important;
    }

    .portfolio-preview-sec .portfolio-slider .box__full .portfolio-col .ps-4 {
        padding-left: 10px !important;
    }
}

@media print {
    .ul-li-table td .tags_otr span.tags {
        background-color: #6eb0f5;
        padding: 2px 8px !important;
        border-radius: 23px;
        margin-top: 0 !important;
        word-wrap: normal;
        display: inline-block;
        font-size: 12px;
    }
}

.select-custom-react .select-z-menu__control,
.select-custom-react .css-t3ipsp-control {
    position: relative;
    z-index: 9;
    border: 0;
    min-height: 48px !important;
}

.cvr .slider-for {
    .thank-you-cover {
        min-height: 350px;
    }
    .portfolio-cover.main-page {
        width: 100%;
    }
}
.portfolio-preview-sec .portfolio-slider .portfolio-cover {
    box-shadow: none !important;
    width: 100%;
}
.portfolio-preview-sec .portfolio-slider .portfolio-cover.main-page {
    .footer-main article p {
        overflow: auto;
        max-height: 120px;
    }
}

.cvr .thumbnail-cover .portfolio-cover.thank-you-height ~ div {
    display: none;
}
.portfolio-preview-sec .portfolio-slider .box__full .large-view {
    min-height: calc(100vh - 0px);
    max-height: calc(100vh - 0px);
}
.portfolio-preview-sec .portfolio-slider .box__full .large-view .details-wrap .inner__content {
    overflow-y: auto !important;
    overflow: hidden;
    max-height: calc(100vh - 82px);
}
.cvr .thumbnail-cover .portfolio-cover {
    margin: 0 !important;
}
.portfolio-preview-sec .portfolio-slider .box__full {
    .portfolio-cover {
        margin: 0 !important;
    }
}
.slider.slider-nav {
    .thumbnail-cover {
        padding-right: 5px;
        display: flex;
        flex-direction: column;
        row-gap: 10px;
    }
}

.slider.slider-nav .footer-main article p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}
// .multiple-select {
//     .select__control {
//         &.select__control {
//             height: 51px !important;
//         }
//     }
//     .select__menu-portal {
//         .select__menu {
//             .select__menu-list {
//                 .select__option {
//                     padding: 8px 12px !important;
//                     &:hover {
//                         padding: 8px 12px !important;
//                     }
//                 }
//             }
//         }
//     }
// }
