@import 'partials/variables';
@import 'partials/mixins';

.date-picker {
    display: flex;
    column-gap: 20px;
    .date-input-cvr {
        .date-input {
            height: 48px;
            background-color: transparent;
            border: 1px solid #c4ddff;
            border-radius: 8px;
            padding: 8px 22px;
            background-image: url(../img/noun-calendar.svg);
            background-repeat: no-repeat;
            background-position: 95% 50%;
            font: 13px/20px Poppins;
            position: relative;
        }
    }
}

.floating-label {
    position: relative;
    width: 100%;
}

.floating-input:focus,
.floating-select:focus {
    outline: none;
}
.floating-label {
    label {
        background: #f2faff;
        font: 500 15px/20px 'Roboto', sans-serif;
        line-height: 140%;
        color: #7c7f80;
        position: absolute;
        pointer-events: none;
        left: 13px;
        top: 15px;
        transition: 0.2s ease all;
        -moz-transition: 0.2s ease all;
        -ms-transition: 0.2s ease all;
        -webkit-transition: 0.2s ease all;
        padding: 0px;
    }
}
.floating-input:focus ~ label,
.floating-input:not(:placeholder-shown) ~ label {
    top: -7px;
    background: #f2faff;
    font: 500 12px/20px 'Roboto', sans-serif;
    line-height: 120%;
    color: #7c7f80;
    left: 13px !important;
    padding: 0 7px;
}

.floating-select:focus ~ label,
.floating-select:not([value='']):valid ~ label {
    top: -10px;
    font-size: 14px;
    color: #5264ae;
}

.form-inner-cover {
    background: #fff;
    border-radius: 15px;
    min-height: 335px;
    padding: 85px 73px 65px;
    margin-bottom: 35px;

    .profile-box {
        display: flex;
        position: relative;
        transition: 0.4s;
        .edit-btn {
            background: #ebf3ff;
            border-radius: 9px;
            color: $primary-color;
            font: 500 13px/14px Poppins;
            border: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 114px;
            height: 42px;
            position: absolute;
            right: 0;
            top: -50px;
            .icon {
                margin-right: 15px;
                flex-shrink: 0;
            }
        }
    }

    .profile-pick {
        flex: 1 0 200px;
        max-width: 200px;
        margin-right: 60px;
    }

    .right-forms {
        flex-grow: 1;
        margin-bottom: 0;
    }
    .form-floating {
        margin-bottom: 50px;
    }
    .form-control,
    .form-floating label {
        padding-left: 20px;
    }
}

.btn__grp {
    display: flex;
    // flex-wrap: wrap;
    justify-content: flex-end;
    column-gap: 17px;
    .btn-sm {
        min-width: 140px !important;
        height: 43px !important;
        border-radius: 11px !important;
        box-shadow: none !important;
        font: 500 13px/17px Poppins !important;
    }
    .btn-primary {
        background: $primary-bg !important;
        border: 1px solid $primary-bg !important;
        color: #fff !important;
        &:hover {
            background-color: #1a8dff !important;
            border-color: #1a8dff !important;
        }
    }
    .btn-secondary {
        background-color: #fff !important;
        color: $primary-bg !important;
        border: 1px solid $primary-bg !important;
        &:hover {
            opacity: 0.9;
        }
    }
}

@mixin object-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

$circleSize: 173px;
$radius: 100px;
$shadow: 0 0 10px 0 rgba(255, 255, 255, 0.35);
$fontColor: rgb(250, 250, 250);

.profile-pic {
    color: transparent;
    transition: all 0.3s ease;
    @include object-center;
    justify-content: flex-start;
    transition: all 0.3s ease;
    position: relative;
    input {
        display: none;
    }

    img {
        position: absolute;
        object-fit: cover;
        width: $circleSize;
        height: $circleSize;
        border-radius: $radius;
        border: 8px solid #cce6ff;
        z-index: 0;
    }

    .icon-camera {
        // position: absolute;
        img {
            width: 35px;
            height: 35px;
            right: 0px;
            bottom: 20px;
            border: 0;
        }
    }

    .label {
        cursor: pointer;
        height: $circleSize;
        width: $circleSize;
    }

    .label {
        @include object-center;
        z-index: 9;
        transition: background-color 0.2s ease-in-out;
        border-radius: $radius;
        margin-bottom: 0;
        position: relative;
    }

    span {
        // display: inline-flex;
        // padding: .2em;
        // height: 2em;
    }
}

.profile-main {
    .right-forms {
        margin-top: 10px;
    }
    .profile-img {
        img {
            object-fit: cover;
            border: 8px solid #cce6ff;
            width: $circleSize;
            height: $circleSize;
            border-radius: 100px;
        }
    }
    .form-value {
        display: flex;
        margin-bottom: 55px;
        .disabled {
            opacity: 0.6;
        }
        .icon-label {
            flex-shrink: 0;
            margin-right: 9px;
        }
        label {
            color: $primary-color;
            margin-bottom: 8px;
            font: 13px/17px Poppins;
        }
        .field {
            color: #35475e;
            font: 500 15px/20px Poppins;
        }
    }
}

// drag and drop

.file-upload {
    text-align: center;
}

.drag-drop-wrap label {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-bottom: 10px;
    font-size: 18px;
    position: absolute;
    inset: 0;
    bottom: -48px;
    z-index: 9;
    cursor: pointer;
}

.file-upload input {
    display: none;
}

// antD floating label custom
.floating-label {
    .floating_lbl.floating_lbl {
        position: absolute;
        inset: 10px;
        right: 30px;
        display: flex;
        align-items: center;
        font-size: 13px;
        color: rgba(0, 0, 0, 0.3);
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
        min-height: 20px;
        height: unset;
        z-index: 0;
        padding: 0 !important;
    }

    &.calender {
        .floating_lbl.floating_lbl {
            background-color: #ffffff;
        }
    }

    .ant-picker {
        width: 100%;
        &.ant-picker-focused,
        &.selected-date {
            & ~ .floating_lbl {
                top: -9px;
                bottom: unset;
                right: unset;
                color: #393939;
                font-size: 10px !important;
            }
        }
    }
}

// Dark mode
.dark-mode {
    .floating-label {
        .floating_lbl {
            background-color: #1e1e2c;
            color: rgba(255, 255, 255, 0.3);
        }

        .ant-picker {
            &.ant-picker-focused,
            &.selected-date {
                & ~ .floating_lbl {
                    color: #ffffff;
                }

                input {
                    color: #ffffff;
                }
            }
        }
        &.calender {
            .floating_lbl.floating_lbl {
                background-color: #242636;
                color: rgba(255, 255, 255, 0.3) !important;
            }

            .ant-picker {
                &.ant-picker-focused,
                &.selected-date {
                    & ~ .floating_lbl {
                        color: #ffffff !important;
                    }
                }
            }
        }
    }

    .form-inner-cover {
        background-color: $dark-bg;
    }
    .profile-main .profile-img img,
    .profile-pic img {
        border-color: #1e1e2c;
    }
    .profile-main .form-value label {
        color: $light-clr;
    }
    .profile-main .form-value .icon-label img {
        filter: brightness(0) invert(1);
    }
    .profile-main .form-value .field {
        color: #fff;
    }
    .profile-main .form-value .disabled {
        color: #828b94;
    }
    .form-inner-cover .profile-box .edit-btn {
        background-color: #333546;
        color: #e5e5e5;
    }
    .form-inner-cover .profile-box .edit-btn .icon img {
        filter: brightness(0) invert(1);
    }
    .btn__grp .btn-secondary {
        background-color: transparent !important;
    }
    .file-format {
        color: #e5e5e5;
    }
    .file-details {
        border-color: #494b5b;
        background-color: #242636;
        color: #fff;
    }
    .file-details .file_details .file-name {
        color: #e5e5e5;
    }
    .file-details .close_btn img {
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        opacity: 0.7;
    }
    .file-details .file_details .size-info {
        color: #979a9d;
    }

    .profile_pic {
        border: 8px solid #34364b;
        .user-icon {
            opacity: 0.1;
        }
    }
    .image-uploader .icon-camera img {
        filter: grayscale(80%) brightness(42%);
        -webkit-filter: grayscale(80%) brightness(42%);
        -moz-filter: grayscale(80%) brightness(42%);
    }
}

.file-format {
    color: #4676c7;
    margin-top: 8px;
    font: normal 12px/13px Poppins;
    span {
        margin-right: 3px;
    }
}

.uploade-otr {
    position: relative;
}

.file-details {
    border: 1px solid #d3e3f8;
    border-radius: 12px;
    padding: 10px 15px;
    background-color: #fff;
    margin-bottom: 15px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    // position: absolute;
    width: 100%;
    .otr {
        display: flex;
        align-items: center;
        width: 100%;
    }
    .format-icon {
        width: 22px;
        margin-right: 18px;
        img {
            width: 100%;
        }
    }
    .file_details {
        font: 500 13px/17px Poppins;
        .file-name {
            color: #35475e;
        }
        .size-info {
            color: #a2bcd6;
            font-weight: normal;
        }
    }

    .close_btn {
        margin-left: auto;
        border: 0;
        flex-shrink: 0;
        background-color: transparent;
        img {
            width: 20px;
        }
    }
}

.file_details {
    font: 500 13px/17px Poppins;
    overflow: hidden;
    max-width: calc(100% - 100px);
    flex: 0 0 calc(100% - 100px);
    .file-name {
        color: #35475e;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .size-info {
        color: #a2bcd6;
        font-weight: normal;
    }
}

.image-uploader {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;

    .user-icon {
        width: 50px;
    }
    .icon-camera {
        position: absolute;
        right: 0;
        bottom: 0;
        img {
            width: 30px;
        }
    }
    .image-employee {
        width: 100%;
        object-fit: cover;
        border-radius: 100%;
        width: 100%;
        height: 100%;
    }
}

.profile_pic {
    width: 120px;
    height: 120px;
    border-radius: 100%;
    border: 8px solid #cce6ff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .file {
        position: absolute;
        inset: 0;
        opacity: 0;
    }
}

.floating-label label span {
    color: #0075e9;
}

.modal .modal-dialog .modal-content .modal-header .btn-close {
    margin-right: 1px;
    margin-top: 1px;
}
.modal-full-width .modal-dialog .modal-content .modal-header .btn-close {
    margin-right: 0px;
    margin-top: 0px;
}

@media (max-width: 1679px) {
    .profile-pic .label {
        width: 150px;
    }
    .date-picker .date-input-cvr .date-input {
        height: 40px;
    }
    .floating-label label {
        // top: 10px;
    }
    .date-picker .date-input-cvr .date-input {
        // height: 40px;
    }
    .form-inner-cover {
        padding: 75px 63px 55px;
    }
    .profile-main .form-value .field {
        color: #35475e;
        font: 500 14px/20px Poppins;
    }
    .profile-main .profile-img img,
    .profile-pic img {
        width: 153px;
        height: 153px;
    }
    .form-inner-cover .profile-pick {
        margin-right: 40px;
    }
    .form-inner-cover .profile-pick {
        flex: 1 0 182px;
        max-width: 182px;
    }
}
@media (max-width: 1199px) {
    .form-inner-cover {
        padding: 55px 33px 55px;
    }
    .profile-pic .icon-camera img {
        // right: 50px;
    }
    .form-inner-cover .profile-pick {
        // margin-right: 0;
    }
    .form-inner-cover .profile-pick {
        flex: 1 0 180px;
        max-width: 180px;
    }
    .form-inner-cover .profile-box {
        flex-wrap: wrap;
    }
    .form-inner-cover .profile-pick {
        margin-bottom: 35px;
    }
}
@media (max-width: 991px) {
    .form-inner-cover .profile-pick {
        flex: 1 0 133px;
        max-width: 133px;
    }

    .form-inner-cover {
        padding: 35px;
    }
    .form-inner-cover .form-floating,
    .profile-main .form-value {
        margin-bottom: 30px;
    }
    .profile-pic img,
    .profile-main .profile-img img {
        width: 133px;
        height: 133px;
    }
    .profile-pic .icon-camera img {
        right: 0px;
        bottom: 25px;
    }
    .form-inner-cover .profile-box .edit-btn {
        top: 0;
    }
}
@media (max-width: 579px) {
    .profile_pic {
        width: 100px;
        height: 100px;
        border: 6px solid #cce6ff;
    }
    .image-uploader .user-icon {
        width: 40px;
    }
    .profile-pic .label {
        height: 130px;
    }
    .profile-pic .icon-camera img {
        right: 0;
    }
    .form-inner-cover .profile-pick {
        max-width: 105px;
        margin-right: 60px;
    }
    .profile-pic {
        justify-content: flex-start;
        align-items: flex-start;
    }
    .profile-pic img,
    .profile-main .profile-img img {
        width: 103px;
        height: 103px;
        border: 5px solid #cce6ff;
    }
    .btn__grp .btn-sm {
        min-width: auto !important;
        flex-grow: 1;
    }
    .form-inner-cover .profile-box .edit-btn {
        min-width: 84px;
        height: 38px;
    }
}

.img-settings {
    column-gap: 17px;
    .btn-sm {
        height: 50px !important;
    }
    .search-list {
        right: 0;
        max-height: inherit;
        li {
            cursor: pointer;
            padding-left: 40px;
            padding-right: 10px;
            &.active {
                .icon {
                    visibility: visible;
                }
            }
            &:hover {
                .icon {
                    visibility: visible;
                }
            }
        }
    }
    span {
        background-color: transparent !important;
        .icon {
            margin-right: 5px;
            width: 20px;
            visibility: hidden;
        }
    }
    label {
        font-weight: normal !important;
    }
}

@media (max-width: 767px) {
    .img-settings {
        flex-direction: column;
    }
}

.profile-main .form-value {
    .icon-label img {
        min-width: 14px;
    }
}

.form-inner-cover .profile-box .edit-btn .icon {
    margin-top: -2px;
}

.select-z-menu__multi-value {
    .select-z-menu__multi-value__remove {
        &:hover {
            svg {
                color: #de350b;
            }
        }
        svg {
            color: #ffffff;
        }
    }
}
