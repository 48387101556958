@import 'partials/variables';
@import 'partials/mixins';

$tblBorder: #dfedff;
$tblBorderDark: #333546;
.content-tables {
    padding-bottom: 5px;

    // min-height: 400px;
    .table-responsive {
        // max-height: calc(100vh - 310px);
        // min-height: calc(100vh - 310px);
        // overflow-y: auto;
        // background-color: #fff;
        // border-left: 1px solid #DFEDFF;
        // border-right: 1px solid #DFEDFF;
        // border-radius: 12px 12px 0 0;
        @media (max-width: 1679px) {
            // max-height: calc(100vh - 250px);
            // min-height: calc(100vh - 250px);
        }
    }
    table.table {
        font-family: $poppins;
        & > :not(:last-child) > :last-child > * {
            border-bottom-color: $tblBorder;
        }
        margin-bottom: 0;
        border-collapse: separate;
        border-spacing: 0 4px;
        .actionCol {
            width: 100px;
        }
        .tbl-cont {
            // display: flex;
            // min-width: fit-content;

            word-wrap: break-word;
            align-items: center;
            white-space: normal;
        }
        .cont-nowrp {
            max-width: 400px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .cont-wrp {
            max-width: 400px;
            white-space: normal;
            word-break: break-word;
        }
        &.px-start {
            th,
            td {
                &:first-child {
                    padding-left: 48px;
                    @media screen and (max-width: 1679px) {
                        padding-left: 32px;
                    }
                    @media screen and (max-width: 1200px) {
                        padding-left: 20px;
                    }
                }
            }
        }
        th,
        td {
            padding-top: 14px;
            padding-bottom: 14px;
            font-size: 14px;

            @media screen and (max-width: 1200px) {
                font-size: 12px;
                padding-top: 10px;
                padding-bottom: 10px;
            }
        }

        thead {
            th {
                border-top: 1px solid $tblBorder;
            }
            th,
            td {
                border-bottom: 1px solid $tblBorder;
                // border-left: 1px solid $tblBorder;

                background-color: #ffffff;
                color: #35475e;
                font-weight: 500;

                &:first-child {
                    border-left: 1px solid $tblBorder;
                    border-radius: 12px 0 0 0;
                }
                &:last-child {
                    border-right: 1px solid $tblBorder;
                    border-radius: 0 12px 0 0;
                }
            }
        }

        tbody {
            th,
            td {
                border-top: 1px solid $tblBorder;
                border-bottom: 1px solid $tblBorder;

                background-color: #ffffff;
                font-weight: 400;
                color: #6c83a2;
                vertical-align: middle;
                word-break: break-all;
                // height: 64px;
                @media screen and (min-width: 1201px) {
                    // height: 76px;
                }
                &:first-child {
                    border-left: 1px solid $tblBorder;
                }
                &:last-child {
                    border-right: 1px solid $tblBorder;
                }
            }
            tr {
                &:nth-child(even) {
                    th,
                    td {
                        background-color: #f9fdff;
                    }
                }
            }
        }

        .profImgCell {
            width: 130px;
            @media screen and (max-width: 1679px) {
                width: 80px;
            }
        }
        .profImg {
            width: 48px;
            height: 48px;
            max-width: 100%;
            display: flex;
            background-color: #94b9e1;
            border-radius: 100%;
            position: relative;
            margin-left: 24px;
            @media screen and (max-width: 1679px) {
                margin-left: 15px;
                width: 32px;
                height: 32px;
            }
            img {
                width: 100%;
                height: 100%;
                border-radius: 100%;
                object-fit: cover;
            }
            .noImg {
                display: flex;
                position: absolute;
                inset: 0;
                align-items: center;
                justify-content: center;
                color: #ffffff;
                font-size: 16px;
                @media screen and (max-width: 1679px) {
                    font-size: 12px;
                }
            }
        }

        .actn-btns {
            display: flex;
            align-items: center;
            gap: 14px;
            .actn-btn {
                width: 27px;
                height: 27px;
                padding: 0;
                margin: 0;
                text-decoration: none;
                display: flex;
                align-items: center;
                justify-content: center;
                border: none;
                background-color: #dcebff;
                border-radius: 100%;
                img {
                    object-fit: contain;
                }
            }
        }
    }
}

.pagenationWrp {
    padding: 8px 25px;
    background-color: #ffffff;
    border: 1px solid $tblBorder;
    margin-bottom: 10px;
    border-radius: 0 0 12px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .show-per-page {
        display: flex;
        align-items: center;
        column-gap: 15px;
        span {
            color: #6c83a2;
            font: normal normal normal 13px/23px Poppins;
        }
        .select-custom {
            position: relative;
            margin: 0;
            width: 75px;

            font: normal normal normal 13px/20px Poppins;
            select {
                color: #5c86be;
                min-width: 100%;
                border-color: #d3e3f8;
                border-radius: 7px;
                height: 35px;
                padding: 6px 10px;
            }
        }
        .select-custom:after {
            border-top-color: #5c86be;
            right: 4px;
        }
    }
    .show-txt {
        font: normal normal normal 13px/23px Poppins;
        color: #6c83a2;
    }
    .pagenationItems {
        display: flex;
        .pagination {
            margin-left: auto;
            gap: 8px;
            .page-link {
                width: 28px;
                height: 28px;
                border-radius: 100%;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #87b2de;
                border: 1px solid #d3e3f8;
                font-size: 13px;
                &:hover,
                &.active {
                    color: #0075e9;
                    background-color: #dcebff;
                }
                &.disabled {
                    pointer-events: none;
                    img {
                        opacity: 0.7;
                    }
                }
            }
            .prevPage {
                .page-link {
                    margin-right: 10px;
                }
            }
            .nextPage {
                .page-link {
                    margin-left: 10px;
                }
            }
        }
    }
}

.content-tables {
    .dropdown-menu {
        box-shadow: 0px 3px 6px #0075e929;
        border-radius: 8px;
        border: 0;
        min-width: 175px;

        overflow: hidden;
        li {
            // margin: 0 10px;
            &:last-child {
                .dropdown-item {
                    border-bottom: 0;
                }
            }
        }
        .dropdown-item {
            color: #35475e;
            padding: 10px 15px;
            font: 500 13px/18px Poppins;
            border-bottom: 1px solid #f3f8ff;
            &:hover {
                background: #e5f0ff !important;
            }
            .icon {
                margin-right: 10px;
                img {
                    width: 15px;
                }
            }
        }
    }

    .dot-icon {
        background-color: #dcebff;
        border: 1px dashed #dfedff;
        border-radius: 10px;
        padding: 5px 0px;
        width: 15px;
        img {
            width: 5px;
        }

        border: 0;
    }
}

.max-width-100 {
    max-width: 100% !important;
}

// dark mode
body.dark-mode.dark-mode {
    .rejected-row {
        background-color: #ff7e000f;
    }
    .status-02 .status-round.ok {
        background-color: #26974a45;
    }
    .status-02 .status-round.delete {
        background-color: #b0343461;
    }
    .status-02 .status-round.delete:hover {
        box-shadow: 0 0 0 0.15rem #9c4747e0;
    }
    .status-02 .status-round:hover {
        -webkit-box-shadow: 0 0 0 0.15rem #a1e4b6;
        box-shadow: 0 0 0 0.15rem #12983cba;
    }

    .content-tables .css-hlgwow {
        border: 1px solid #35475e;
    }
    .content-tables table.table tbody th:first-child,
    .content-tables table.table tbody td:first-child,
    .content-tables table.table tbody th,
    .content-tables table.table tbody td {
        border-color: #333546;
    }
    .content-tables table.table thead th,
    .content-tables table.table thead td {
        border-color: #333546;
    }
    .content-tables .table-responsive {
        // background-color: #1E1E2C;
        // border-color:#333546;
    }
    .content-tables .dot-icon {
        background-color: #11121d;
    }
    .content-tables .dot-icon img {
        filter: brightness(0) invert(1);
        opacity: 0.8;
    }
    .content-tables .dropdown-menu {
        background-color: #11121d;
        box-shadow: 0px 3px 16px #0000005c;
    }
    .content-tables .dropdown-menu .dropdown-item:hover {
        background: #333546 !important;
    }

    .content-tables .dropdown-menu li {
        &:last-child .dropdown-item {
            border-bottom: 0;
        }
    }
    .content-tables .dropdown-menu .dropdown-item {
        color: #e5e5e5;
        background-color: #11121d !important;
        border-bottom: 1px solid #2b2b2b;
    }
    .content-tables .dropdown-menu .dropdown-item .icon img:not(.integ) {
        filter: brightness(0) invert(1);
    }
    .content-tables {
        table.table {
            & > :not(:last-child) > :last-child > * {
                border-bottom-color: $tblBorderDark;
            }

            thead {
                th,
                td {
                    border-top: 1px solid $tblBorderDark;
                    border-bottom: 1px solid $tblBorderDark;
                    background-color: #232331;
                    color: #ffffff;
                    font-weight: 500;
                    &:first-child {
                        // border-left: 1px solid $tblBorderDark;
                        border-radius: 12px 0 0 0;
                    }
                    &:last-child {
                        // border-right: 1px solid $tblBorderDark;
                        border-radius: 0 12px 0 0;
                    }
                }
            }

            tbody {
                th,
                td {
                    // border-top: 1px solid $tblBorderDark;
                    border-bottom: 1px solid $tblBorderDark;
                    background-color: #232331;
                    font-weight: 400;
                    color: #9eaec4;
                    vertical-align: middle;
                    // height: 64px;
                    @media screen and (min-width: 1201px) {
                        // height: 76px;
                    }
                    &:first-child {
                        // border-left: 1px solid $tblBorderDark;
                    }
                    &:last-child {
                        // border-right: 1px solid $tblBorderDark;
                    }
                }

                tr {
                    &:nth-child(even) {
                        th,
                        td {
                            background-color: #09233d;
                        }
                    }
                }
            }

            .actn-btns {
                .actn-btn {
                    background-color: #11121d;
                }
            }
        }
    }

    .pagenationWrp {
        background-color: #232331;
        border: 1px solid $tblBorderDark;
        .pagenationItems {
            .pagination {
                .page-link {
                    color: #e5e5e5;
                    border: 1px solid #333546;
                    background-color: transparent;
                    &:hover,
                    &.active {
                        color: #fff;
                        background-color: #494b5b;
                        color: #e5e5e5;
                    }
                }
            }
        }
    }

    .content-tables table.table tbody tr:nth-child(even) th,
    .content-tables table.table tbody tr:nth-child(even) td {
        background-color: #1e1e2c;
    }
    .content-tables table.table .profImg {
        background: #494b5b;
    }
    .content-tables table.table .tbl-cont {
        color: #e5e5e5;
    }
    .content-tables table.table .actn-btns .actn-btn img {
        filter: brightness(0) invert(1);
    }

    .tooltip-arrow:before {
        border-color: #000 !important;
    }
    .tooltip > div {
        background-color: #000;
        border: #000;
        box-shadow: none;
        color: #e8f2ff;
    }

    .status.light-blue {
        background: #323341;
        border-color: transparent;
    }
    .status.light-green {
        background: #2d303c;
        border-color: transparent;
    }
    .status.light-red {
        background: #34333f;
        border-color: transparent;
    }
    .pagenationWrp .show-per-page .select-custom select {
        border-color: #333546;
        background-color: #2b2d3e;
        color: #e5e5e5;
    }
    .pagenationWrp .show-per-page .select-custom:after {
        border-top-color: #fff;
    }
    .pagenationWrp .show-per-page span {
        color: #e5e5e5;
    }
    .pagenationWrp .show-txt {
        color: #878aa3;
    }
    .table-content-wrap .heading-cvr .heading {
        color: #fff;
    }
}

.status {
    padding: 10px 17px;
    font: 500 13px/18px Poppins;
    border-radius: 8px;
    white-space: nowrap;
    &.light-blue {
        background: #e5f0ff;
        border: 1px solid #bbd0ee;
        color: #0075e9;
    }
    &.light-green {
        background: #defff2;
        border: 1px solid #a2ffdc;
        color: #00d585;
    }
    &.light-red {
        background: #fff5e5;
        border: 1px solid #f5e2c1;
        color: #ffa816;
    }
}

.table-content-wrap {
    .heading-cvr {
        padding-top: 10px;
        .heading {
            color: #35475e;
        }
        .searchInput {
            border: 1px solid #e8f2ff !important;
        }
    }
    .content-tables {
        table.table .tbl-cont {
            white-space: normal;
        }

        .status {
            padding: 7px 17px 6px 17px;

            font: 500 13px/15px Poppins;
            border-radius: 24px;
            min-width: 82px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            &.light-blue {
                background: #e5f0ff;
                border: 1px solid #c4dcff;
                color: #0075e9;
            }
            &.light-green {
                background: #ddf5e0;
                border: 1px solid #bdefc3;
                color: #4aa155;
            }
            &.light-red {
                background: #ffefef;
                border: 1px solid #eec8c8;
                color: #c74243;
                min-width: 98px;
            }
        }
    }

    tr {
        .width-lg {
            width: 350px;
        }
    }

    .floating-form-block {
        margin-bottom: 0 !important;
        width: 100%;
    }
    .form-floating label {
        padding: 10px 10px !important;
    }
    .form-floating > .form-control:not(:placeholder-shown) ~ label {
    }
    .form-floating > .form-control:focus ~ label,
    .form-floating > .form-control:not(:placeholder-shown) ~ label,
    .form-floating > .form-select ~ label .form-floating > .form-control:focus ~ label {
        padding: 0px 10px !important;
    }
    .form-floating {
        width: 100%;
        .form-control {
            height: 40px !important;
            padding: 8px 10px !important;
            border-radius: 8px !important;
        }
    }
    .calender {
        position: relative;
    }
}

.content-tables {
    .css-hlgwow {
        border: 1px solid #d3e3f8;
        border-radius: 8px;
        height: 43px;
    }
}

.content-tables table.table .actn-btns .actn-btn {
    flex-shrink: 0;
}
th.custom-mobile-width-200 {
    width: 260px;
}
.filter-cover .select-custom {
    .css-hlgwow {
        padding-right: 25px !important;
    }
}
.content-tables tbody .tbl-cont {
    max-width: 200px;
}

.status-01 {
    .status-orange {
        color: #c68438;
    }
    .status-green {
        color: #54aa1f;
    }
}

.space-th-td {
    padding-left: 25px;
}

.status-02 {
    display: flex;
    column-gap: 10px;
    .status-round {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: 0.2s;
        &:hover {
            box-shadow: 0 0 0 0.15rem #a1e4b6;
        }
        img {
            width: 13px;
        }
        &.ok {
            background-color: #d9ffe5;
        }
        &.delete {
            background-color: #ffefef;
            &:hover {
                box-shadow: 0 0 0 0.15rem #f5dada;
            }
        }
    }
}

.content-tables {
    .width_04 {
        width: 43%;
    }
    .width-sm {
        width: 23%;
    }
}

.rejected-row {
    background-color: #fff6ed;
    .drop-btn {
        color: #dc3545 !important;
    }
}
.content-tables .dropdown-menu .dropdown-item {
    display: inline-flex;
}

// small table

.sm__table {
    th:first-child {
        border-top-left-radius: 12px;
        border-top: 1px solid #dfedff;
        border-left: 1px solid #dfedff;
        text-align: left;
    }

    th:last-child {
        border-top-right-radius: 12px;
        border-top: 1px solid #dfedff;
        border-right: 1px solid #dfedff;
    }

    /* Optional styling for the other <th> elements */
    th:not(:first-child):not(:last-child) {
        border-top: 1px solid #dfedff;
    }

    table {
        border-collapse: separate;
        border-spacing: 0;
        th,
        td {
            padding: 10px 20px;
        }
        tbody tr {
            &:last-child {
                td:first-child {
                    border-bottom-left-radius: 12px;
                }
                td:last-child {
                    border-bottom-right-radius: 12px;
                }
            }
        }
        .nodata {
            .img_nodata {
            }
        }

        th {
            border-bottom: 1px solid #dfedff !important;
        }
        td {
            border-bottom: 1px solid #dfedff;
            font: normal 13px/20px Poppins;
            color: #35475e;

            &:first-child {
                border-left: 1px solid #dfedff;
                width: 150px;
            }
            &:last-child {
                border-right: 1px solid #dfedff;
            }
        }
    }

    th,
    td {
        text-align: center;
    }
    td {
        vertical-align: middle;
    }
    .custom-checkbox {
        margin-bottom: 0;
        height: 22px;
        padding-left: 0;
        .checkmark {
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
    .img-content {
        // justify-content: center;
        .img-round {
            overflow: hidden;
            margin-right: 10px;
            flex-shrink: 0;
            img {
                width: 24px;
                height: 24px;
                border-radius: 50%;
                object-fit: cover;
                max-width: 100%;
            }
        }
        .content-td {
            white-space: nowrap;
        }
    }
    thead {
        tr {
        }
        th {
            color: #35475e;

            font: 500 14px/21px Poppins;
        }
    }
}

@media (max-width: 1679px) {
    .content-tables tbody .tbl-cont {
        max-width: 180px;
    }
    .content-tables table.table thead th {
        white-space: nowrap;
        font-size: 13px;
    }
    .content-tables .dot-icon img {
        width: 3px;
    }
    .content-tables .dot-icon {
        padding: 4px 0px;
        width: 12px;
    }
    .content-tables table.table th,
    .content-tables table.table td {
        font-size: 13px;
    }
    .content-tables table.table tbody td {
        padding: 10px 12px;
    }
}
@media (max-width: 1199px) {
    .sm__table thead th {
        white-space: nowrap;
    }
    .content-tables table.table .tbl-cont {
        // white-space: nowrap;
    }
}
@media (max-width: 767px) {
    .sm__table thead th {
        font: 500 13px/21px Poppins;
    }
    .cover-elements .chk-bx-cvr .ck-label {
        margin-bottom: 0px;
    }
    .tab_btns_otr {
        margin-top: 15px;
        .tb-btn {
            flex-grow: 1;
            font: 500 13px/21px Poppins;
            position: static;
        }
    }
    .pagenationWrp {
        flex-direction: column;
        padding-top: 15px;
        padding-bottom: 15px;
    }
    .pagenationWrp .show-txt {
        margin: 10px 0 15px;
    }
    .custom-col-lg.col-md-12 {
        padding: 0 15px !important;
    }
}
@media (max-width: 479px) {
    .tab_btns_otr {
        margin-left: 0;
        width: 100%;
    }
    .tab_btns_otr .tb-btn {
        width: 100%;
        margin-bottom: 5px;
    }
}
th.custom-mobile-width-120.activity-th {
    width: 100px;
}
.filter-cover .fltr-right .sort-select .sort {
    top: 17px;
}

.select-custom-react .css-13cymwt-control {
    .css-1dimb5e-singleValue {
        position: relative;
        top: 1.5px;
    }
}

.sm__th_td {
    width: 100px;
    padding: 0 15px !important;
    .profImg {
        margin: auto !important;
    }
}

.log-table {
    overflow: auto;
    max-height: calc(100vh - 170px);
    margin-bottom: 25px;
    margin-top: 15px;
    padding: 0 5px 0 0;
    table {
        margin-bottom: 0 !important;
    }
}

.dark-mode {
    .sm__table th:not(:first-child):not(:last-child),
    .sm__table th:first-child,
    .sm__table table td:first-child,
    .sm__table table td:first-child,
    .sm__table table td,
    .sm__table th:last-child {
        border-color: #333546 !important;
    }
    .sm__table table td {
        color: #e5e5e5;
    }
    .sm__table thead th {
        color: #e5e5e5;
    }
}

.description-txt {
    width: 800px !important;
    max-width: 800px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap !important;
    @media (max-width: 1441px) {
        max-width: 600px !important;
    }
    @media (max-width: 1281px) {
        max-width: 400px !important;
    }
}
.suggestions-table {
    td {
        white-space: nowrap !important;
        word-break: normal !important;
    }
}
