@import 'partials/variables';
@import 'partials/mixins';
.wrapper-main {
    .sidebar {
        display: none;
    }
    .sidebar {
        position: fixed;
        top: 0px;
        bottom: 0;
        left: 0;
        z-index: 1000;
        display: block;
        padding: 18px 20px 20px 20px;

        background-color: $primary-bg;
        // border-right: 2px solid #E8F0FF;
    }
    .nav-sidebar {
        // margin-right: -21px;
        margin-bottom: 20px;
        // margin-left: -20px;
        display: flex;
        flex-direction: column;
    }
    .nav-sidebar > li > a {
        padding-right: 20px;
        padding-left: 20px;
        display: inline-flex;
        align-items: center;
        width: 100%;
    }
    .sidebar {
        width: 343px;
        -moz-transition: width 0.2s ease-out;
        -o-transition: width 0.2s ease-out;
        -webkit-transition: width 0.2s ease-out;
        transition: width 0.2s ease-out;
        display: inline-block;
        z-index: 2;
        border-radius: 0px 0px 76px 0px;
    }
    .nav-sidebar {
        background: none;
        margin-top: 10px;
        li {
            margin-bottom: 40px;
            // display: flex;
            // align-items: center;
            &.active {
                // background: #e7f7fc;
                position: relative;
                //     &:before {
                //     content: "";
                //     background: #e7f7fc;
                //     position: absolute;
                //     height: 50px;
                //     top: -15px;
                //     left: -10px;
                //     right: 0px;
                //    }
                // &:after {
                //     content: "";
                //     background: $secondary-color;
                //     position: absolute;
                //     width: 4px;
                //     height: 50px;
                //     left: -10px;
                //     top: -15px;
                // }
                a {
                    // color: $secondary-color !important;
                    font: 500 13px/23px $poppins;
                }
                .menu-icon img {
                    // filter: invert(68%) sepia(89%) saturate(650%) hue-rotate(136deg) brightness(88%) contrast(87%);
                }
            }
            a {
                color: #fff;
                font: 300 14px/23px $poppins;
                position: relative;
                z-index: 9;
                img {
                    filter: brightness(0) invert(1);
                }
            }
        }
    }
    .sidebar {
        .otr {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
        .toggle-icon {
            color: #fff;
            font-size: 18px;
        }
        .logo-brand {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 5px;
            margin-left: -10px;
            img {
                // width: 156px;
                height: 30px;
                @media (max-width: 991px) {
                    height: 38px;
                }
            }
        }
        ul li a span {
            &.fa {
                font-size: 20px;
            }
        }
    }
    .menutext {
        left: 50px;
        margin-left: 20px;
        opacity: 1;
        -moz-transition: opacity 0.6s 0.2s ease-out;
        -o-transition: opacity 0.6s 0.2s ease-out;
        -webkit-transition: opacity 0.6s 0.2s ease-out;
        transition: opacity 0.6s 0.2s ease-out;
        position: absolute;
    }
    .collapseToggle {
        padding-left: 4px;
        cursor: pointer;
        padding-right: 5px; //So it's easier to click
        display: flex;
        justify-content: flex-end;
    }
    #toggleIcon {
        -moz-transition: transform 0.2s ease-out;
        -o-transition: transform 0.2s ease-out;
        -webkit-transition: transform 0.2s ease-out;
        transition: transform 0.2s ease-out;
    }
    .rotate {
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
        -moz-transition: transform 0.2s ease-out;
        -o-transition: transform 0.2s ease-out;
        -webkit-transition: transform 0.2s ease-out;
        transition: transform 0.2s ease-out;
    }
    .sidebar--Collapse {
        width: 80px;
        -moz-transition: width 0.2s ease-out;
        -o-transition: width 0.2s ease-out;
        -webkit-transition: width 0.2s ease-out;
        transition: width 0.2s ease-out;
        .logo-brand {
            display: none;
        }
        .toggle-icon {
            position: relative;
        }
        .menutext {
            opacity: 0;
            -moz-transition: opacity 0.3s ease-out;
            -o-transition: opacity 0.3s ease-out;
            -webkit-transition: opacity 0.3s ease-out;
            transition: opacity 0.3s ease-out;
            position: absolute;
        }
    }
    .main--slide {
        -moz-transition: margin-left 0.2s ease-out;
        -o-transition: margin-left 0.2s ease-out;
        -webkit-transition: margin-left 0.2s ease-out;
        transition: margin-left 0.2s ease-out;
        margin-left: 80px;
    }
}

.close-icon {
    position: absolute;
    &:before {
        // content: "\f00d" !important;
    }
}

.wrapper-main .sidebar--Collapse .notification {
    opacity: 1;
    i {
        position: absolute;
        left: -45px;
        top: -10px;
        @media (max-width: 991px) {
            position: static;
        }
    }
}

.logo-brand-sm {
    display: none;
}

.sidebar--Collapse {
    .logo-brand-sm {
        display: block;
        flex-shrink: 0;
        margin-top: 20px;
        text-align: center;
    }

    .collapsetoggle {
        // margin-top: 55px;
        text-align: center;
    }
    .logo-toggle {
        flex-direction: column;
    }
    .close-icon {
        position: static;
    }
    .nav-sidebar {
        margin-top: 30px;
    }
}
.wrapper-main .sidebar--Collapse {
    .nav-sidebar > li > a {
        padding-right: 0px;
        padding-left: 0px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }
    .nav-sidebar > li.active {
        background: #1a8dff;
        border-radius: 5px;
        width: 38px;
        a {
            padding: 10px;
        }
    }
    .nav-sidebar > li.show {
        @media (max-width: 991px) {
            background: #1a8dff;
            border-radius: 5px;
            .dark-mode & {
                background: #1e1e2c;
            }
        }
    }
}

.side-inner-cover {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.side-ftr {
    margin-top: auto;
}

.app-store-small {
    display: flex;
    flex-direction: column;
    align-items: center;
    display: none;
    .store-icon {
        margin-bottom: 15px;
        img {
            width: 20px;
        }
    }
}

.sidebar--Collapse {
    .app-store-small {
        display: flex;
    }
    .app-store {
        display: none;
    }
}

.collapsetoggle {
    cursor: pointer;
    flex-shrink: 0;
    width: 45px;
    height: 45px;
    background: transparent linear-gradient(180deg, #007af3 0%, #0382ff 100%) 0% 0% no-repeat padding-box;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.logo-toggle {
    display: flex;
}

.app-store {
    ul {
        li {
            list-style: none;
            margin-bottom: 10px;
            text-align: center;
            img {
                width: 160px;
            }
        }
    }
}

@media (max-width: 1679px) {
    .wrapper-main .sidebar .logo-brand img {
        height: 26px;
    }
    .sidebar--Collapse .logo-brand-sm img {
        // width:40px;
    }
    .wrapper-main .nav-sidebar li a {
        font-size: 13px;
    }
    .wrapper-main .nav-sidebar > li > a {
        padding-right: 8px;
        padding-left: 15px;
    }
    .wrapper-main .sidebar--Collapse .notification i {
        left: -38px;
        top: -11px;
    }
    .wrapper-main .nav-sidebar li.active::after {
        left: 0;
    }
    .wrapper-main .sidebar {
        width: 240px;
        padding: 20px 10px 20px 10px;
    }
    .wrapper-main .nav-sidebar li {
        margin-bottom: 28px;
    }
    .wrapper-main .sidebar--Collapse {
        width: 75px;
    }
    .wrapper-main .main--slide {
        margin-left: 75px;
    }
    .wrapper-main .collapseToggle {
        padding-left: 0;
    }
    .wrapper-main .menutext {
        margin-left: 10px;
    }
}

@media (max-width: 991px) {
    .sidebar--Collapse .app-store {
        display: block;
    }
    .app-store-small {
        display: none !important;
    }
    .side-ftr {
        // display: none;
        .app-store ul li {
            text-align: left;
        }
    }
    .wrapper-main .nav-sidebar {
        margin-top: 18;
    }
    .wrapper-main .sidebar {
        border-radius: 0;
    }
    .wrapper-main .nav-sidebar li.active::after {
        height: 40px;
        top: -10px;
    }
    .wrapper-main .nav-sidebar li.active:before {
        height: 40px;
        top: -10px;
    }
    .wrapper-main .sidebar {
        width: 100% !important;
        height: auto;
        bottom: 0 !important;
        transform: translateX(-100%);
        padding: 13px 20px;
        position: fixed;
        z-index: 999;
        transition: all ease-in-out 0.3s;
        max-width: 432px;
    }
    .wrapper-main .nav-sidebar li:last-child {
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 0;
    }
    .wrapper-main .sidebar--Collapse {
        height: auto;
        transform: translateX(0);
        .nav-sidebar {
            display: block !important;
            margin-top: 18px;
        }
        .side-ftr {
            display: block;
            .app-store ul li {
                text-align: left;
            }
        }
    }
    .wrapper-main .sidebar .otr {
        display: flex;
        // align-items: center;
        justify-content: flex-end;
    }
    .wrapper-main .nav-sidebar {
        // display: none;
    }
    .wrapper-main .sidebar--Collapse .menutext {
        opacity: 1;
    }
    .wrapper-main .sidebar--Collapse .logo-brand {
        display: flex;
    }
    .wrapper-main .nav-sidebar li {
        margin-bottom: 10px;
        // border-bottom: 1px solid #ececec;
        // padding-bottom: 10px;
    }
    .wrapper-main .main--slide {
        margin-left: 0px;
    }
}

.wrapper-main .sidebar:not(.sidebar--Collapse) .nav-sidebar {
    & > li {
        margin-bottom: 10px;

        border-radius: 12px;
        a {
            padding-top: 15px;
            padding-bottom: 15px;
        }

        &.active {
            background: #1a8dff;
            &:before,
            &:after {
                top: 0;
                height: 54px;
                bottom: 0;
            }
        }

        &:hover {
            background: #1a8dff;
        }
    }

    li {
        a {
            .menutext {
                position: relative;
                left: unset;
            }
        }
    }

    .has_submenu {
        display: flex;
        align-items: center;
        &.opened,
        &[aria-expanded='true'] {
            position: relative;
            span {
                position: relative;
                z-index: 2;
            }
            color: #1cc6d3 !important;
            .menu-icon img {
                filter: invert(68%) sepia(89%) saturate(650%) hue-rotate(136deg) brightness(88%) contrast(87%);
            }
            &:before {
                content: '';
                background-color: #e7f7fc;
                position: absolute;
                height: 54px;
                top: -15px;
                left: -10px;
                right: 0px;
                z-index: 1;
                opacity: 0.7;
            }
            &:after {
                content: '';
                background: #1cc6d3;
                position: absolute;
                width: 4px;
                left: -10px;
                height: 54px;
                top: -15px;
                z-index: 2;

                @media screen and (max-width: 1679px) {
                    left: 0px;
                }
            }
        }

        &[aria-expanded='true'] {
            &:after {
                opacity: 0;
            }
        }
    }
}

.wrapper-main .sidebar.sidebar--Collapse .nav-sidebar {
    .submenu {
        display: none !important;
    }
}

.has_submenu {
    display: flex;
    align-items: center;
    background-position: right 20px center;
    &.opened {
        position: relative;
        span {
            position: relative;
            z-index: 2;
        }
        color: #1cc6d3 !important;
        .menu-icon img {
            filter: invert(68%) sepia(89%) saturate(650%) hue-rotate(136deg) brightness(88%) contrast(87%);
        }
        &:before {
            content: '';
            background-color: #e7f7fc;
            position: absolute;
            height: 54px;
            top: -15px;
            left: -10px;
            right: 0px;
            z-index: 1;
        }
        &:after {
            content: '';
            background: #1cc6d3;
            position: absolute;
            width: 4px;
            left: -10px;
            height: 54px;
            top: -15px;
            z-index: 2;
            @media screen and (max-width: 1679px) {
                left: 0px;
            }
        }
    }
}

.submenu.submenu.submenu {
    box-sizing: border-box;
    padding-left: 55px;
    font-size: 0.87em;
    li {
        padding-top: 10px;
        padding-bottom: 10px;
        &.active {
            &:before {
                display: none;
            }
        }
    }
    li a {
        display: flex;
        align-items: center;
    }
    .menu-icon {
        width: 16px;
        img {
            width: 100%;
        }
    }
    .menutext {
        margin-left: 5px;
    }
    .submenu {
        padding-left: 15px;
    }
    .has_submenu {
        display: flex;
        align-items: center;
        position: relative;
        span {
            position: relative;
            z-index: 3;
        }
        &.opened,
        &[aria-expanded='true'] {
            color: #1cc6d3;
            .menu-icon img {
                filter: invert(68%) sepia(89%) saturate(650%) hue-rotate(136deg) brightness(88%) contrast(87%);
            }
            &:before,
            &:after {
                display: none;
            }
        }
        .menu-icon {
            width: 16px;
        }
        .menutext {
            margin-left: 5px;
        }
    }
}

.sidebar--Collapse {
    .has_submenu {
        background-position-x: calc(100% - 14px);
    }
}

@media (max-width: 1679px) {
    .sidebar--Collapse .logo-toggle {
        align-items: center;
    }
    .wrapper-main .sidebar:not(.sidebar--Collapse) .nav-sidebar > li a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .collapsetoggle {
        width: 37px;
        height: 37px;
    }
    .sidebar--Collapse .collapsetoggle {
        img {
            width: 12px;
        }
    }
    .wrapper-main .sidebar--Collapse .nav-sidebar > li.active a {
        padding: 8px;
    }
    .sidebar--Collapse .nav-sidebar {
        align-items: center;
    }
}

@media (max-width: 991px) {
    .collapsetoggle img {
        width: 15px;
    }
    .wrapper-main .menutext {
        left: 38px;
    }
    .wrapper-main .sidebar--Collapse .nav-sidebar > li a {
        padding: 8px 13px !important;
    }

    .logo-brand-sm {
        display: none !important;
    }
    .sidebar--Collapse .logo-toggle {
        flex-direction: row;
    }
    .wrapper-main .sidebar--Collapse .nav-sidebar > li {
        padding-bottom: 0;
    }
    .wrapper-main .sidebar--Collapse .nav-sidebar > li > a {
        justify-content: flex-start;
    }
    .wrapper-main .sidebar--Collapse .nav-sidebar > li.active {
        width: 100%;
    }
    .wrapper-main .sidebar--Collapse .menutext {
        margin-left: 10px;
    }
}

.submenu-expand {
    display: none;
}
.wrapper-main .sidebar .nav-sidebar li.show .submenu-expand {
    display: block !important;
}
.submenu-expand.submenu-expand {
    list-style: none;
    li {
        margin-bottom: 3px !important;
        margin-top: 3px;
        .subnav-link.subnav-link {
            display: block;
            padding: 10px 10px 10px 40px !important;
            position: relative;
            border-radius: 12px;
            &:hover,
            &.active {
                background-color: #0071e1;
            }
            &::before {
                content: '';
                position: absolute;
                left: 22px;
                top: 0;
                bottom: 0;
                margin: auto;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: #fff;
                display: block;
            }
        }
        &.inner-nav-expand {
            border-radius: 12px;
            .subnav-link {
                position: relative;
                &::after {
                    content: '';
                    position: absolute;
                    right: 10px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    width: 12px;
                    height: 12px;
                    background-image: url('../img/down-arrow.svg');
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 10px;
                    transform-origin: center;
                    transition: 0.3s;
                }
            }
            &.show {
                background-color: #0071e1;
                .subnav-link {
                    &::after {
                        transform: rotate(-180deg);
                    }
                }
                .submenu-inner {
                    display: block;
                }
            }
        }
        .submenu-inner {
            list-style: none;
            display: none;
            padding-left: 40px;
            padding-bottom: 15px;
            li {
                border-left: solid 1px #3590e9;
                .subnav-link-inner {
                    padding: 6px 10px 6px 25px !important;
                    display: block;
                    color: #76b5f3;
                    transition: 0.3s;
                    line-height: normal;
                    &:hover,
                    &.active {
                        color: #fff;
                        background-color: #0683ff;
                    }
                }
            }
        }
    }
}

.click-to-expand .menu-icon {
    width: 18px;
}

.wrapper-main .sidebar .nav-sidebar li.click-to-expand > a {
    position: relative;
    &::after {
        content: '';
        position: absolute;
        right: 10px;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 12px;
        height: 12px;
        background-image: url('../img/down-arrow.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 10px;
        transform-origin: center;
        transition: 0.3s;
    }
}
.wrapper-main .sidebar .nav-sidebar > li {
    padding: 2px;
}
.wrapper-main .sidebar:not(.sidebar--Collapse) .nav-sidebar > li.show {
    background: #0683ff;
    > a::after {
        transform: rotate(-180deg);
    }
}
.wrapper-main .nav-sidebar > li.show > a {
    background: #1a8dff;
    border-radius: 12px;
    @media (max-width: 991px) {
        .dark-mode & {
            background: #1e1e2c;
        }
    }
}

// sidebar collapse dropdown positin fix
.wrapper-main {
    .sidebar.sidebar--Collapse {
        border-radius: 0px 0px 30px 0px;
        padding-left: 10px;
        padding-right: 10px;
        .nav-sidebar {
            max-height: calc(100vh - 200px);
            overflow-y: auto;
            overflow-x: clip;
            flex-direction: row;
            flex-wrap: wrap;
            li {
                flex: 0 0 100%;
                max-width: 100%;
                margin-bottom: 30px;
                min-height: 40px;
                &.active {
                    position: unset;
                }
                &.show {
                    .submenu-expand {
                        @media (min-width: 992px) {
                            background-color: #0071e1;
                            padding-right: 15px;
                            padding-left: 15px;
                            position: absolute;
                            left: 75px;
                            box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.1);
                            .submenu-inner {
                                padding-left: 15px;
                                .subnav-link-inner {
                                    white-space: nowrap;
                                }
                            }
                        }
                    }
                }

                a:after {
                    right: 2px;
                }
            }
        }
    }
}

.dark-mode {
    .submenu-expand.submenu-expand li .submenu-inner li .subnav-link-inner:hover,
    .submenu-expand.submenu-expand li .submenu-inner li .subnav-link-inner.active {
        background-color: #242636;
    }
    .wrapper-main .sidebar:not(.sidebar--Collapse) .nav-sidebar > li.show {
        background-color: #1e1e2c;
    }
    .submenu-expand li .subnav-link:hover,
    .submenu-expand li .subnav-link.active {
        background-color: #242636;
    }
    .wrapper-main .sidebar:not(.sidebar--Collapse) .nav-sidebar > li.show .primary-sub {
        background-color: #3c3e4e;
    }
    .wrapper-main .sidebar:not(.sidebar--Collapse) .nav-sidebar > li.show .cursor-pointer {
        background-color: #3c3e4e;
    }

    .wrapper-main .sidebar.sidebar--Collapse .nav-sidebar li.show .submenu-expand {
        @media (min-width: 992px) {
            background-color: #2b2d40;
        }
    }
}

.wrapper-main .nav-sidebar li .menu-icon {
    width: 18px;
    img {
        max-width: 100%;
        width: 100%;
    }
}
// .wrapper-main .sidebar--Collapse ~ .main.main--slide ~ .copy-right{
//     background-color: red;
// }

.copy-right {
    position: relative;
    p {
        margin-left: inherit !important;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
    }
}
@media (max-width: 1679px) {
    .submenu-expand li .subnav-link::before {
        left: 18px;
    }
    .wrapper-main .nav-sidebar li .menu-icon {
        width: 13px;
    }
}

.sidebar.sidebar--Collapse {
    .collapsetoggle {
        position: relative;
        left: 9px;
    }
}
@media (max-width: 1199px) {
    .wrapper-main .sidebar .logo-brand {
        // margin-left: 0px;
    }
}
